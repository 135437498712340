import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

import {
  Btn,
  MUIIcons,
  H4,
  H5,
  H2,
  SecondaryButton,
} from "../../../../../../AbstractElements";
import Divider from "../../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../../CommonElements/FixedFooter";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import StepperHorizontal from "../../../../../../CommonElements/StepperHorizontal";
import Comments from "../../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  handleBackButton,
  handleNextButton,
  setBasicInputFormValue,
} from "../../../../../../ReduxToolkit/Reducers/CompetencyWizardSlice";
import {
  addCommentToStages,
  getCourseContent,
  getPublishedCourseList,
  updateCommentStatus,
  updateCourseContent,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getDomainList,
  getRefernceList,
  getResourceList,
  getToolsList,
  getTrainingAidList,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AutoSavedOn,
  Back,
  Comment,
  CompetencyStatements,
  EnterComments,
  Next,
  OthersPrerequisiteIsRequired,
  OthersReferenceRequired,
  OthersToolsRequired,
  OthersTrainingAidsRequired,
  PleaseEnterPositiveError,
  PleaseProvideRequiredInformation,
  Save,
  SaveAsDraft,
  SumOfClassRoomAndPracticalError,
} from "../../../../../../utils/Constant";
import {
  competencyStepperData,
  getCurrentDate,
  resourceAndReferenceDefaultData,
  scrollToTop,
  showToast,
  sortCommentsByDate,
  formatJSDate,
} from "../../../../../../utils/helper/helper";

import CompetencyFormOne from "./forms/CompetencyFormOne";
import CompetencyFormThree from "./forms/CompetencyFormThree";
import CompetencyFormTwo from "./forms/CompetencyFormTwo";

const CreateCompetencyStatement = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = location.state || "";
  const { numberLevel, basicInputFormValue, showFinish } = useAppSelector(
    state => state.competencyStatement
  );
  const { domainList } = useAppSelector(state => state.master);
  const [iconComments, setIconComments] = useState(null);
  const [isComentRead, setIsComentRead] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [domainListData, setDomainListData] = useState([]);
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState(null);

  const showOtherReferenceRef = useRef(false);
  const showOtherToolsRef = useRef(false);
  const showOtherTrainingAidsRef = useRef(false);
  const showOtherPrerequisiteRef = useRef(false);
  const [showOthers, setShowOthers] = useState(false);
  const [showOtherTools, setShowOtherTools] = useState(false);
  const [showOtherTrainingAids, setShowOtherTrainingAids] = useState(false);
  const [showOtherPrerequisite, setShowOtherPrerequisite] = useState(false);
  const [reviewOverride, setReviewOverride] = useState(false);
  const basicInputFormValueRef = useRef(basicInputFormValue);
  const autoSavedStatus = useRef<Date | null>(null);
  const [seconds, setSeconds] = useState(0);
  const prerequisiteListDataRef = useRef([]);
  const { refernceList, resourceList, toolsList, trainingList } =
    useAppSelector(state => state.master);
  const { courseContent, publishedCourseList } = useAppSelector(
    state => state.course
  );
  const resourceListDataRef = useRef([]);
  const toolsListDataRef = useRef([]);
  const trainingAidsListDataRef = useRef([]);
  const referenceListDataRef = useRef([]);
  const selectedPrerequisiteRef = useRef([]);
  const selectedResourceRef = useRef([]);
  const selectedToolsRef = useRef([]);
  const selectedTrainingAidsRef = useRef([]);
  const selectedReferenceRef = useRef([]);
  const [preSelectedPrerequisite, setPreSelectedPrerequisite] = useState([]);
  const [preSelectedResource, setPreSelectedResource] = useState([]);
  const [preSelectedTools, setPreSelectedTools] = useState([]);
  const [preSelectedTrainingAids, setPreSelectedTrainingAids] = useState([]);
  const [preSelectedReference, setPreSelectedReference] = useState([]);
  const [errors, setErrors] = useState({
    create_date: "",
    version_number: "",
    condition: "",
    personnel_requirement: "",
    media_demonstration: "",
    classroom_environment: "",
    estimated_delivery: "",
    class_room_ratio: "",
    practical_class_ratio: "",
    tools: "",
    training_aids: "",
    reference: "",
    review: "",
    assessment: "",
    prerequisite: "",
    other_references: "",
    other_tools: "",
    other_training_aids: "",
    other_prerequisites: "",
  });

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getPublishedCourseList());
  }, [dispatch]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getCourseContent({ id: courseId }));
    dispatch(getResourceList());
    dispatch(getToolsList());
    dispatch(getTrainingAidList());
    dispatch(getRefernceList());
  }, [dispatch, courseId]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      autoSaveDataToDb("");
      setSeconds(seconds + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const autoSaveDataToDb = async (type = "") => {
    if (courseContent) {
      const { ...rest } = courseContent;
      const toolsMap = [];
      const referenceMap = [];
      const prerequisiteMap = [];

      prerequisiteListDataRef.current?.forEach(element => {
        if (selectedPrerequisiteRef.current.includes(element.name)) {
          prerequisiteMap.push({ id: element.name, name: element.label });
        }
      });

      toolsListDataRef.current?.forEach(element => {
        if (selectedToolsRef.current.includes(element.name)) {
          toolsMap.push({ id: element.name, name: element.label });
        }
      });

      trainingAidsListDataRef.current?.forEach(element => {
        if (selectedTrainingAidsRef.current.includes(element.name)) {
          toolsMap.push({ id: element.name, name: element.label });
        }
      });

      prerequisiteListDataRef.current?.forEach(element => {
        if (selectedReferenceRef.current.includes(element.name)) {
          referenceMap.push({ id: element.name, name: element.label });
        }
      });

      const flatCourseContent = {
        ...rest,
        ...basicInputFormValueRef.current,
        min_attendance: courseContent.additional_information.min_attendance,
        max_attendance: courseContent.additional_information.max_attendance,
        suggested_reading:
          courseContent.additional_information.suggested_reading,
        qualification_certification:
          courseContent.additional_information.qualification_certification,
        assessment_criteria:
          courseContent.additional_information.assessment_criteria,
        theory_time_ratio:
          parseInt(courseContent?.additional_information?.theory_time_ratio) ||
          0,
        practical_time_ratio:
          parseInt(
            courseContent?.additional_information?.practical_time_ratio
          ) || 0,
        tooling_aids_required:
          courseContent.additional_information.tooling_aids_required,
        date: basicInputFormValueRef.current.create_date,
        prerequisite: prerequisiteMap,
        version: basicInputFormValueRef.current.version_number,
        conditions: basicInputFormValueRef.current.condition,
        min_requirements: basicInputFormValueRef.current.personnel_requirement,
        estimated_time: basicInputFormValueRef.current.estimated_delivery,
        classroom_lab: basicInputFormValueRef.current.classroom_environment,
        other_references: showOtherReferenceRef.current
          ? basicInputFormValueRef.current.other_references
          : "",
        other_tools: showOtherToolsRef.current
          ? basicInputFormValueRef.current.other_tools
          : "",
        other_training_aids: showOtherTrainingAidsRef.current
          ? basicInputFormValueRef.current.other_training_aids
          : "",
        other_prerequisites: showOtherPrerequisiteRef.current
          ? basicInputFormValueRef.current.other_prerequisites
          : "",
        resources: toolsMap,
        webinar_link: "",
        reference: referenceMap,
        resources_activity: basicInputFormValueRef.current.resources.toString(),
        intended_audience:
          courseContent?.additional_information?.intended_audience,
        other_intended_audiences:
          courseContent?.additional_information?.other_intended_audiences,
        pre_course_work: courseContent?.additional_information?.pre_course_work,
        other_pre_course_works:
          courseContent?.additional_information?.other_pre_course_works,
        class_room_ratio: basicInputFormValueRef.current.class_room_ratio,
        practical_class_ratio:
          basicInputFormValueRef.current.practical_class_ratio,
        practical_activity_details:
          courseContent?.additional_information?.practical_activity_details ||
          "",
        registration_requirement:
          courseContent?.additional_information?.registration_requirement || "",
        competency_statement_status: "draft",
      };

      await dispatch(updateCourseContent({ courseContent: flatCourseContent }));
      autoSavedStatus.current = new Date();
      if (type === "draft") {
        return navigate(
          `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
        );
      }
    }
  };

  useEffect(() => {
    basicInputFormValueRef.current = basicInputFormValue;
  }, [basicInputFormValue]);

  useEffect(() => {
    getCompetencyData(courseId);
  }, [dispatch, courseId]);

  const getCompetencyData = async courseId => {
    if (courseId) {
      dispatch(getPublishedCourseList());
      dispatch(getCourseContent({ id: courseId }));
    }
  };

  useEffect(() => {
    const requisites = [];
    publishedCourseList?.course_list?.forEach(item => {
      requisites.push({
        name: item.id?.toString(),
        label: item.course_name?.toString(),
        value: item.course_name?.toString(),
      });
    });
    if (requisites?.length > 0) prerequisiteListDataRef.current = requisites;
  }, [publishedCourseList, publishedCourseList.course_list]);

  useEffect(() => {
    if (courseContent?.competency_statement) {
      const requisites = [];
      const requisiteIds = [];
      const resource = [];
      const tools = [];
      const toolIds = [];
      const trainingAids = [];
      const trainingAidIds = [];
      const resourceIds = [];
      const reference = [];
      const referenceIds = [];
      courseContent?.competency_statement?.prerequisite?.forEach(item => {
        requisiteIds.push(item?.id?.toString());
        requisites.push({
          value: item?.name,
          name: item?.id?.toString(),
          label: item?.name,
        });
      });
      courseContent?.competency_statement?.tool_aid?.tool?.forEach(
        (item: any) => {
          toolIds.push(item?.id);
          tools.push({
            value: item?.name + " - " + item?.part_no,
            name: item?.id,
            label: item?.name + " - " + item?.part_no,
          });
        }
      );
      courseContent?.competency_statement?.tool_aid?.training_aid?.forEach(
        (item: any) => {
          trainingAidIds.push(item?.id);
          trainingAids.push({
            value: item?.name + " - " + item?.part_no,
            name: item?.id,
            label: item?.name + " - " + item?.part_no,
          });
        }
      );
      courseContent?.competency_statement?.reference?.forEach(item => {
        referenceIds.push(item?.id?.toString());
        reference.push({
          value: item?.name,
          name: item?.id?.toString(),
          label: item?.name,
        });
      });
      selectedPrerequisiteRef.current = requisiteIds;
      selectedResourceRef.current = resourceIds;
      selectedToolsRef.current = toolIds;
      selectedTrainingAidsRef.current = trainingAidIds;
      selectedReferenceRef.current = referenceIds;
      setPreSelectedResource(resource);
      setPreSelectedTools(tools);
      setPreSelectedTrainingAids(trainingAids);
      setPreSelectedReference(reference);
      setPreSelectedPrerequisite(requisites);
      const statementObj = {
        prerequisite: requisites,
        create_date: getCurrentDate(),
        version_number: courseContent?.competency_statement?.version,
        condition: courseContent?.competency_statement?.conditions,
        personnel_requirement:
          courseContent?.competency_statement?.min_requirements,
        media_demonstration:
          courseContent?.competency_statement?.media_demonstration,
        classroom_environment:
          courseContent?.competency_statement?.classroom_lab,
        estimated_delivery: courseContent?.competency_statement?.estimated_time,
        class_room_ratio: courseContent?.competency_statement?.class_room_ratio,
        practical_class_ratio:
          courseContent?.competency_statement?.practical_class_ratio,
        resources: resource,
        webinar_link: "",
        tools: tools,
        training_aids: trainingAids,
        reference: reference,
        other_references: courseContent?.competency_statement?.other_references,
        other_tools: courseContent?.competency_statement?.other_tools || "",
        other_training_aids:
          courseContent?.competency_statement?.other_training_aids || "",
        other_prerequisites:
          courseContent?.competency_statement?.other_prerequisites,
        review: courseContent?.competency_statement?.review,
        assessment:
          courseContent?.competency_statement?.assessment ||
          resourceAndReferenceDefaultData,
      };
      if (courseContent?.competency_statement?.review?.length > 0) {
        setReviewOverride(true);
      }
      if (statementObj?.other_references?.length > 0) {
        showOtherReferenceRef.current = true;
        setShowOthers(true);
      }
      if (statementObj?.other_tools?.length > 0) {
        showOtherToolsRef.current = true;
        setShowOtherTools(true);
      }
      if (statementObj?.other_training_aids?.length > 0) {
        showOtherTrainingAidsRef.current = true;
        setShowOtherTrainingAids(true);
      }
      if (statementObj?.other_prerequisites?.length > 0) {
        showOtherPrerequisiteRef.current = true;
        setShowOtherPrerequisite(true);
      }
      dispatch(setBasicInputFormValue(statementObj));
    }
    let comment = [];
    if (courseContent?.comment?.competency_statement) {
      comment = sortCommentsByDate(courseContent.comment.competency_statement);
    }
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "competency_statement"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
    setCommentList(comment);
  }, [dispatch, courseContent]);

  useEffect(() => {
    const references = [];
    refernceList?.references?.forEach(sites => {
      const userObj = {
        value: sites.id,
        label: sites.name,
      };
      references.push(userObj);
    });
    referenceListDataRef.current = references;
  }, [refernceList]);

  useEffect(() => {
    const resources = [];
    resourceList?.resources?.forEach(sites => {
      const userObj = {
        name: sites.id,
        label: sites.name,
        value: sites.name,
      };
      resources.push(userObj);
    });
    resourceListDataRef.current = resources;
  }, [resourceList]);

  useEffect(() => {
    const tools = [];
    toolsList?.resources?.forEach(sites => {
      const userObj = {
        name: sites.id,
        label: sites.name + " - " + sites?.part_no,
        value: sites.name + " - " + sites?.part_no,
      };
      tools.push(userObj);
    });
    toolsListDataRef.current = tools;
  }, [toolsList]);

  useEffect(() => {
    const trainings = [];
    trainingList?.resources?.forEach(sites => {
      const userObj = {
        name: sites.id,
        label: sites.name + " - " + sites?.part_no,
        value: sites.name + " - " + sites?.part_no,
      };
      trainings.push(userObj);
    });
    trainingAidsListDataRef.current = trainings;
  }, [trainingList]);

  const getUserData = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    const intValue = parseInt(value, 10);

    if (name === "class_room_ratio" || name === "practical_class_ratio") {
      if (isNaN(intValue) || intValue < 0 || intValue > 99) {
        setErrors({
          ...errors,
          [name]: PleaseEnterPositiveError,
        });
        dispatch(
          setBasicInputFormValue({
            ...basicInputFormValue,
            class_room_ratio: "",
            practical_class_ratio: "",
          })
        );
        return;
      }

      const newClassroom =
        name === "class_room_ratio" ? intValue : 100 - intValue;
      const newPractical =
        name === "practical_class_ratio" ? intValue : 100 - intValue;

      if (newClassroom < 0 || newPractical < 0) {
        setErrors({
          ...errors,
          [name === "class_room_ratio"
            ? "practical_class_ratio"
            : "class_room_ratio"]: SumOfClassRoomAndPracticalError,
        });
        dispatch(
          setBasicInputFormValue({
            ...basicInputFormValue,
            class_room_ratio: "",
            practical_class_ratio: "",
          })
        );
        return;
      }

      setErrors({
        ...errors,
        class_room_ratio: "",
        practical_class_ratio: "",
      });

      dispatch(
        setBasicInputFormValue({
          ...basicInputFormValue,
          class_room_ratio: newClassroom.toString(),
          practical_class_ratio: newPractical.toString(),
        })
      );
    } else {
      dispatch(
        setBasicInputFormValue({ ...basicInputFormValue, [name]: value })
      );
    }
  };

  const getMultiDropdownDataData = (value: any[]) => {
    const prerequisiteMap = [];

    prerequisiteListDataRef.current?.forEach((element: any) => {
      if (value.includes(element.name)) {
        prerequisiteMap.push({
          name: element.name?.toString(),
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedPrerequisite(prerequisiteMap);
    selectedPrerequisiteRef.current = value;
  };

  const getMultiDropdownResourceData = (value: any[]) => {
    const resourceMap = [];

    resourceListDataRef.current?.forEach((element: any) => {
      if (value.includes(element.name)) {
        resourceMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedResource(resourceMap);
    selectedResourceRef.current = value;
  };

  const getMultiDropdownToolsData = (value: any[]) => {
    const toolsMap = [];

    toolsListDataRef.current?.forEach((element: any) => {
      if (value.includes(element.name)) {
        toolsMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedTools(toolsMap);
    selectedToolsRef.current = value;
  };
  const getMultiDropdownTrainingAidsData = (value: any[]) => {
    const trainingMap = [];

    trainingAidsListDataRef.current?.forEach((element: any) => {
      if (value.includes(element.name)) {
        trainingMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedTrainingAids(trainingMap);
    selectedTrainingAidsRef.current = value;
  };

  const getMultiDropdownReferenceData = (value: any[]) => {
    const referenceMap = [];

    prerequisiteListDataRef.current?.forEach((element: any) => {
      if (value.includes(element.name)) {
        referenceMap.push({
          name: element.name?.toString(),
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedReference(referenceMap);
    selectedReferenceRef.current = value;
  };

  const getRichTextUserData = (name: string, value: string) => {
    dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
  };

  const validateFields = () => {
    let isValid = true;

    const requiredFields = {
      1: {
        create_date: "Create Date",
        version_number: "Version Number",
        condition: "Condition",
      },
      2: {
        personnel_requirement: "Personnel Requirement",
        media_demonstration: "Media Demonstration",
        classroom_environment: "Classroom Environment",
        estimated_delivery: "Estimated Delivery",
        class_room_ratio: "Proper Classroom ratio",
        practical_class_ratio: "Proper Practical ratio",
        tools: "Tools",
        training_aids: "Training Aids",
        reference: "Reference",
      },
      3: {
        review: "Review",
        assessment: "Assessment",
      },
    };

    const newErrors = {
      create_date: "",
      version_number: "",
      prerequisite: "",
      condition: "",
      personnel_requirement: "",
      media_demonstration: "",
      classroom_environment: "",
      estimated_delivery: "",
      class_room_ratio: "",
      practical_class_ratio: "",
      tools: "",
      training_aids: "",
      reference: "",
      review: "",
      assessment: "",
      other_references: "",
      other_tools: "",
      other_training_aids: "",
      other_prerequisites: "",
    };

    if (
      showOthers &&
      basicInputFormValue.other_references === "" &&
      numberLevel === 2
    ) {
      newErrors["other_references"] = OthersReferenceRequired;
      isValid = false;
    }

    if (
      showOtherTools &&
      basicInputFormValue.other_tools === "" &&
      numberLevel === 2
    ) {
      newErrors["other_tools"] = OthersToolsRequired;
      isValid = false;
    }

    if (
      showOtherTrainingAids &&
      basicInputFormValue.other_training_aids === "" &&
      numberLevel === 2
    ) {
      newErrors["other_training_aids"] = OthersTrainingAidsRequired;
      isValid = false;
    }

    if (
      showOtherPrerequisite &&
      basicInputFormValue.other_prerequisites === ""
    ) {
      newErrors["other_prerequisites"] = OthersPrerequisiteIsRequired;
      isValid = false;
    }

    if (requiredFields[numberLevel]) {
      for (const [field] of Object.entries(requiredFields[numberLevel])) {
        const value = basicInputFormValue[field];
        const isInvalid =
          typeof value === "string"
            ? !value.trim()
            : value === undefined || value === null || value === "";

        if (isInvalid) {
          newErrors[field] =
            `${requiredFields[numberLevel][field]} is required`;
          isValid = false;
        } else if (field === "reference") {
          if (
            !showOthers &&
            (selectedReferenceRef.current === null ||
              selectedReferenceRef.current?.length === 0)
          ) {
            newErrors["reference"] =
              `${requiredFields[numberLevel]["reference"]} is required`;
            isValid = false;
          }
        } else if (field === "tools") {
          if (
            !showOtherTools &&
            (selectedToolsRef.current === null ||
              selectedToolsRef.current?.length === 0)
          ) {
            newErrors["tools"] =
              `${requiredFields[numberLevel]["tools"]} is required`;
            isValid = false;
          }
        } else if (field === "training_aids") {
          if (
            !showOtherTrainingAids &&
            (selectedTrainingAidsRef.current === null ||
              selectedTrainingAidsRef.current?.length === 0)
          ) {
            newErrors[field] =
              `${requiredFields[numberLevel][field]} is required`;
            isValid = false;
          }
        } else if (field === "estimated_delivery") {
          if (value === "." || value === "0" || value === ".0") {
            newErrors[field] =
              `${requiredFields[numberLevel][field]} is required`;
            isValid = false;
          }
        } else if (
          field === "class_room_ratio" ||
          field === "practical_class_ratio"
        ) {
          if (
            value === "00.00" ||
            !value?.toString()?.split(".")[0] ||
            !value?.toString()?.split(".")[1] ||
            value?.toString()?.split(".")[0] === "00" ||
            value?.toString()?.split(".")[1] === "00"
          ) {
            newErrors[field] =
              `${requiredFields[numberLevel][field]} is required`;
            isValid = false;
          }
        }
      }
    }

    setErrors(newErrors);
    if (!isValid) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }
    return isValid;
  };

  const handleFinish = async () => {
    const { ...rest } = courseContent;
    const toolsMap = [];
    const referenceMap = [];
    const prerequisiteMap = [];

    prerequisiteListDataRef.current?.forEach(element => {
      if (selectedPrerequisiteRef.current.includes(element?.name?.toString())) {
        prerequisiteMap.push({ id: element.name, name: element.label });
      }
    });

    toolsListDataRef.current?.forEach(element => {
      if (selectedToolsRef.current.includes(element.name)) {
        toolsMap.push({ id: element.name, name: element.label });
      }
    });

    trainingAidsListDataRef.current?.forEach(element => {
      if (selectedTrainingAidsRef.current.includes(element.name)) {
        toolsMap.push({ id: element.name, name: element.label });
      }
    });

    prerequisiteListDataRef.current?.forEach(element => {
      if (selectedReferenceRef.current.includes(element?.name?.toString())) {
        referenceMap.push({ id: element.name, name: element.label });
      }
    });

    const flatCourseContent = {
      ...rest,
      ...basicInputFormValue,
      min_attendance: courseContent.additional_information.min_attendance,
      max_attendance: courseContent.additional_information.max_attendance,
      suggested_reading: courseContent.additional_information.suggested_reading,
      qualification_certification:
        courseContent.additional_information.qualification_certification,
      assessment_criteria:
        courseContent.additional_information.assessment_criteria,
      theory_time_ratio:
        parseInt(courseContent?.additional_information?.theory_time_ratio) || 0,
      practical_time_ratio:
        parseInt(courseContent?.additional_information?.practical_time_ratio) ||
        0,
      pre_course_work:
        courseContent?.additional_information?.pre_course_work || [],
      other_pre_course_works:
        courseContent?.additional_information?.other_pre_course_works || "",
      tooling_aids_required:
        courseContent.additional_information.tooling_aids_required,
      date: getCurrentDate(),
      prerequisite: prerequisiteMap,
      version: basicInputFormValue.version_number,
      conditions: basicInputFormValue.condition,
      min_requirements: basicInputFormValue.personnel_requirement,
      estimated_time: basicInputFormValue.estimated_delivery,
      classroom_lab: basicInputFormValue.classroom_environment,
      other_references: showOthers ? basicInputFormValue?.other_references : "",
      other_tools: showOtherTools ? basicInputFormValue?.other_tools : "",
      other_training_aids: showOtherTrainingAids
        ? basicInputFormValue?.other_training_aids
        : "",
      other_prerequisites: showOtherPrerequisite
        ? basicInputFormValue?.other_prerequisites
        : "",
      resources: toolsMap,
      webinar_link: "",
      reference: referenceMap,
      resources_activity: basicInputFormValue.resources.toString(),
      intended_audience:
        courseContent?.additional_information?.intended_audience,

      other_intended_audiences:
        courseContent?.additional_information?.other_intended_audiences,
      class_room_ratio: basicInputFormValue.class_room_ratio,
      practical_class_ratio: basicInputFormValue.practical_class_ratio,
      practical_activity_details:
        courseContent?.additional_information?.practical_activity_details || "",
      registration_requirement:
        courseContent?.additional_information?.registration_requirement || "",
      competency_statement_status: "completed",
    };

    await dispatch(updateCourseContent({ courseContent: flatCourseContent }));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };
  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "competency_statement",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "competency_statement",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="mb-5">
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{CompetencyStatements}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div className="basic-wizard important-validation">
            <StepperHorizontal
              data={competencyStepperData}
              level={numberLevel}
            />
            <div>
              {numberLevel === 1 && (
                <CompetencyFormOne
                  errors={errors}
                  prerequisiteListData={prerequisiteListDataRef.current}
                  getRichTextUserData={getRichTextUserData}
                  getUserData={getUserData}
                  getMultiDropdownDataData={getMultiDropdownDataData}
                  basicInputFormValue={basicInputFormValue}
                  preSelectedPrerequisite={preSelectedPrerequisite}
                  showOtherPrerequisite={showOtherPrerequisite}
                  setShowOtherPrerequisite={setShowOtherPrerequisite}
                  domainList={domainListData}
                  showOtherPrerequisiteRef={showOtherPrerequisiteRef}
                />
              )}
              {numberLevel === 2 && (
                <CompetencyFormTwo
                  errors={errors}
                  getRichTextUserData={getRichTextUserData}
                  resourceListData={resourceListDataRef.current}
                  toolsListData={toolsListDataRef.current}
                  trainingAidsListData={trainingAidsListDataRef.current}
                  referenceListData={prerequisiteListDataRef.current}
                  getUserData={getUserData}
                  basicInputFormValue={basicInputFormValue}
                  getMultiDropdownToolsData={getMultiDropdownToolsData}
                  getMultiDropdownTrainingAidsData={
                    getMultiDropdownTrainingAidsData
                  }
                  getMultiDropdownResourceData={getMultiDropdownResourceData}
                  getMultiDropdownReferenceData={getMultiDropdownReferenceData}
                  preSelectedResource={preSelectedResource}
                  preSelectedTools={preSelectedTools}
                  preSelectedTrainingAids={preSelectedTrainingAids}
                  preSelectedReference={preSelectedReference}
                  showOthers={showOthers}
                  showOtherReferenceRef={showOtherReferenceRef}
                  showOtherToolsRef={showOtherToolsRef}
                  showOtherTrainingAidsRef={showOtherTrainingAidsRef}
                  setShowOthers={setShowOthers}
                  showOtherTools={showOtherTools}
                  setShowOtherTools={setShowOtherTools}
                  showOtherTrainingAids={showOtherTrainingAids}
                  setShowOtherTrainingAids={setShowOtherTrainingAids}
                  domainList={domainListData}
                />
              )}
              {numberLevel === 3 && (
                <CompetencyFormThree
                  errors={errors}
                  reviewOverride={reviewOverride}
                  setReviewOverride={setReviewOverride}
                  getRichTextUserData={getRichTextUserData}
                  basicInputFormValue={basicInputFormValue}
                  prerequisiteListDataRef={prerequisiteListDataRef.current}
                  selectedPrerequisiteRef={selectedPrerequisiteRef.current}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <FixedFooter>
        {formatJSDate(autoSavedStatus.current) && (
          <H5 className="mt-2">
            {AutoSavedOn} {formatJSDate(autoSavedStatus.current)}
          </H5>
        )}
        <SecondaryButton onClick={() => autoSaveDataToDb("draft")}>
          {SaveAsDraft}
        </SecondaryButton>
        {numberLevel > 1 && (
          <Btn
            outline
            className="alert-light-primary"
            color="primary"
            onClick={() => {
              scrollToTop();
              dispatch(handleBackButton());
            }}
          >
            {Back}
          </Btn>
        )}
        <Btn
          color="primary"
          onClick={() => {
            if (!showFinish && validateFields()) {
              dispatch(handleNextButton());
            }
            scrollToTop();
            if (showFinish && validateFields()) {
              handleFinish();
            }
          }}
        >
          {showFinish ? Save : Next}
        </Btn>
      </FixedFooter>
    </div>
  );
};

export default CreateCompetencyStatement;
