import { Divider, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

import {
  DefaultButton,
  H3,
  SecondaryButton,
} from "../../../../../AbstractElements";
import DateRangeDisplay from "../../../../../CommonElements/DateRangeDisplay";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import { useAppDispatch } from "../../../../../ReduxToolkit/Hooks";
import { postOrUpdateModuleFeedback } from "../../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  AddValidNumberInRange100,
  Back,
  ContentType,
  ContentTypeSelectContentTypeToolTipMessage,
  Enter,
  Feedback,
  FeedbackEnterFeedbackToolTipMessage,
  Instructor,
  Location,
  ModuleName,
  ModuleNameEnterModuleNameToolTipMessage,
  PageNo,
  PageNoEnterPageNoToolTipMessage,
  Room,
  Submit,
  isRequired,
} from "../../../../../utils/Constant";
import {
  contentType,
  handleKeyDownOnNumericWithoutDecimal,
} from "../../../../../utils/helper/helper";

import "./style.scss";

const RateNow = () => {
  const [formValues, setFormValues] = useState({
    moduleName: "",
    contentType: "",
    pageNo: null,
    feedback: "",
  });

  const [errors, setErrors] = useState({
    moduleName: "",
    contentType: "",
    pageNo: null,
    feedback: "",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    course_name,
    location_name,
    classroom_name,
    tranierName,
    course_start_date,
    course_end_date,
    course_schedule_id,
    id,
    module_name,
    content_type,
    page_no,
    feedback,
  } = location.state;

  useEffect(() => {
    if (id) {
      setFormValues({
        moduleName: module_name,
        contentType: content_type,
        pageNo: page_no,
        feedback: feedback,
      });
    }
  }, []);

  const validateForm = () => {
    const newErrors = {
      moduleName: "",
      contentType: "",
      pageNo: "",
      feedback: "",
    };
    let isError = false;

    if (!formValues.moduleName) {
      newErrors.moduleName = `${ModuleName} ${isRequired}`;
      isError = true;
    }
    if (!formValues.contentType) {
      newErrors.contentType = `${ContentType} ${isRequired}`;
      isError = true;
    }
    if (!formValues.pageNo) {
      newErrors.pageNo = `${PageNo} ${isRequired}`;
      isError = true;
    } else if (formValues.pageNo < 1 || formValues.pageNo > 100) {
      newErrors.pageNo = AddValidNumberInRange100;
      isError = true;
    }
    if (!formValues.feedback) {
      newErrors.feedback = `${Feedback} ${isRequired}`;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }

    const payload = {
      course_schedule_id: course_schedule_id,
      module_name: formValues.moduleName,
      content_type: formValues.contentType,
      page_no: formValues.pageNo,
      feedback: formValues.feedback,
    };

    if (id) {
      payload["id"] = id;
    }

    dispatch(
      postOrUpdateModuleFeedback({
        moduleContent: payload,
      })
    ).then(res => {
      if (res.payload) {
        handleGoBack();
      }
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChange = e => {
    setFormValues(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="page-body module-feedaback-form">
      <Card className="p-2">
        <CardBody className="mb-5">
          <H3 className="fw-bold">{course_name}</H3>
          <Row className="mt-3 gap-4 mb-4">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={course_start_date}
                endDate={course_end_date}
              />
            </Col>
            <Col className="d-flex gap-5 mt-3">
              <StatusDisplay label={Location} number={location_name} />
              <StatusDisplay label={Room} number={classroom_name} />
              <StatusDisplay label={Instructor} number={tranierName} />
            </Col>
          </Row>
          <Divider />
          <Row className="mt-3">
            <Col md="6">
              <FormGroup>
                <LabelTooltip
                  label={ModuleName}
                  tooltipText={ModuleNameEnterModuleNameToolTipMessage}
                  important={true}
                  placement={"bottom"}
                />
                <Input
                  type="text"
                  className="option-input"
                  name="moduleName"
                  placeholder={`${Enter} ${ModuleName}`}
                  value={formValues?.moduleName}
                  onChange={handleChange}
                  maxLength={100}
                  invalid={!!errors.moduleName}
                />
                {errors.moduleName && (
                  <div className="invalid-feedback">{errors.moduleName}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <LabelTooltip
                  label={ContentType}
                  tooltipText={ContentTypeSelectContentTypeToolTipMessage}
                  important={true}
                />
                <Input
                  onChange={handleChange}
                  value={formValues.contentType}
                  name="contentType"
                  type="select"
                  invalid={!!errors.contentType}
                >
                  {contentType.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.contentType && (
                  <div className="invalid-feedback">{errors.contentType}</div>
                )}
              </FormGroup>
            </Col>

            <Row className="mt-3">
              <Col md="6">
                <FormGroup>
                  <LabelTooltip
                    label={PageNo}
                    tooltipText={PageNoEnterPageNoToolTipMessage}
                    important={true}
                    placement={"bottom"}
                  />
                  <Input
                    type="number"
                    className="option-input"
                    name="pageNo"
                    placeholder={`${Enter} ${PageNo}`}
                    value={formValues?.pageNo}
                    onChange={handleChange}
                    maxLength={100}
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                    invalid={!!errors.pageNo}
                  />
                  {errors.pageNo && (
                    <div className="invalid-feedback">{errors.pageNo}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="12">
                <FormGroup>
                  <LabelTooltip
                    label={Feedback}
                    tooltipText={FeedbackEnterFeedbackToolTipMessage}
                    important={true}
                    placement={"bottom"}
                  />
                  <Input
                    type="textarea"
                    className="option-input"
                    name="feedback"
                    placeholder={`${Enter} ${Feedback}`}
                    value={formValues?.feedback}
                    onChange={handleChange}
                    maxLength={100}
                    invalid={!!errors.feedback}
                    rows={4}
                  />
                  {errors.feedback && (
                    <div className="invalid-feedback">{errors.feedback}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </CardBody>
      </Card>

      <FixedFooter>
        <SecondaryButton onClick={handleGoBack}>{Back}</SecondaryButton>
        <DefaultButton color="primary" onClick={handleSubmit}>
          {Submit}
        </DefaultButton>
      </FixedFooter>
    </div>
  );
};

export default RateNow;
