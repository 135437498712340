import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { H2 } from "../../AbstractElements";
import { ALL_USERS_STATUS } from "../../Api/constants";
import Divider from "../../CommonElements/Divider";
import SliderModal from "../../CommonElements/SliderModal";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  ActivityLogs,
  Course,
  DurationCount,
  DurationType,
  EffectiveFrom,
  Grades,
  Policy,
  SelfAssessment,
  Domain,
  Competency,
  Proficiency,
  Outcome,
  FunctionPoints,
  Grade,
  Department,
  Division,
  Location,
  IsOnNoticePeriod,
  SubDivision,
  ReportingManager,
} from "../../utils/Constant";
import { formatDateTime } from "../../utils/helper/helper";
import ActivityLogContainer from "../ActivityLogsContainer";

const logLabels = {
  course: Course,
  grades: Grades,
  policy: Policy,
  competency_assessment: SelfAssessment,
  duration_type: DurationType,
  duration_count: DurationCount,
  effective_from: EffectiveFrom,
  domain: Domain,
  competency: Competency,
  proficiency: Proficiency,
  outcome: Outcome,
  function: FunctionPoints,
  grade: Grade,
  department: Department,
  division: Division,
  rm: ReportingManager,
  office_city: Location,
  location: Location,
  is_on_notice_period: IsOnNoticePeriod,
  sub_division: SubDivision,
};

const formatLogValue = (value: any): string => {
  if (Array.isArray(value)) {
    return value
      .map(
        (item: { name?: string; course_name?: string }) =>
          item.name || item.course_name || ""
      )
      .join(", ");
  }
  if (typeof value === "object" && value !== null) {
    return value.name || "";
  }
  return value?.toString() || "";
};

const AuditLog = ({ auditLogs, handleActivityModal, showActivityLog }) => {
  const [userNameMap, setUserNameMap] = useState(null);
  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.user);

  useEffect(() => {
    if (auditLogs?.length > 0 && showActivityLog) {
      dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    }
  }, [showActivityLog]);

  useEffect(() => {
    const userMapObj: Record<number, { user_name: string }> = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  const transformLogs = logs => {
    return logs?.map(logEntry => {
      const transformedData = [];
      Object.entries(logEntry.logs).forEach(([key, value]) => {
        const sourceLabel = logLabels[key];
        if (!sourceLabel) return;

        const previousValue = formatLogValue(value["old_value"]);
        const currentValue = formatLogValue(value["new_value"]);
        if (sourceLabel) {
          transformedData.push({
            sourceLabel,
            previousValue,
            currentValue,
          });
        }
      });

      return {
        date: formatDateTime(logEntry.created_at),
        name: userNameMap ? userNameMap[logEntry.created_by]?.user_name : null,
        data: transformedData,
      };
    });
  };

  return (
    <div>
      <SliderModal isOpen={showActivityLog} toggle={handleActivityModal}>
        <H2>{ActivityLogs}</H2>
        <Divider />
        <ActivityLogContainer logs={transformLogs(auditLogs)} />
      </SliderModal>
    </div>
  );
};

AuditLog.propTypes = {
  handleActivityModal: PropTypes.func.isRequired,
  auditLogs: PropTypes.array,
  showActivityLog: PropTypes.bool,
};

export default AuditLog;
