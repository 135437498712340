const courseApiUrl = `${process.env.REACT_APP_COURSE_API_ENDPOINT}`;
export const userApiUrl = `${process.env.REACT_APP_USER_API_ENDPOINT}`;
const assessmentApiUrl = `${process.env.REACT_APP_ASSESSMENT_API_ENDPOINT}`;
const learnerApiUrl = `${process.env.REACT_APP_LEARNER_API_ENDPOINT}`;

// Methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// Thunk names
export const FETCH_DATA = "/get";
export const PUT_DATA = "/put";
export const POST_DATA = "/post";
export const POST_DATA_URL = "/post";
export const GET_COURSE_REQUEST_LIST = "GET_COURSE_REQUEST_LIST";
export const GET_COURSE_REQUEST_LIST_BY_FILTER =
  "GET_COURSE_REQUEST_LIST_BY_FILTER";
export const GET_COURSE_REQUEST_BY_ID = "GET_COURSE_REQUEST_LIST_BY_ID";
export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const APPROVE_COURSE_REQUEST = "APPROVE_COURSE_REQUEST";
export const GET_USER_SPECIFIC_COURSE_LIST = "GET_USER_SPECIFIC_COURSE_LIST";
export const GET_COURSE_LIST = "GET_COURSE_LIST";
export const DOWNLOAD_STAGE_DATA = "DOWNLOAD_STAGE_DATA";
export const GET_COURSE_CONTENT = "GET_COURSE_CONTENT";
export const GET_COURSE_CONTENT_BY_IDS = "GET_COURSE_CONTENT_BY_IDS";
export const GET_ELIGIBLE_LIST = "GET_ELIGIBLE_LIST";
export const GET_GLOBAL_SETTINGS = "GET_GLOBAL_SETTINGS";
export const UPDATE_GLOBAL_SETTINGS = "UPDATE_GLOBAL_SETTINGS";
export const GET_PUBLISHED_COURSE_LIST = "GET_PUBLISHED_COURSE_LIST";
export const GET_COURSE_SCEDULED_LIST = "GET_COURSE_SCEDULED_LIST";
export const UPDATE_COURSE_CONTENT = "UPDATE_COURSE_CONTENT";
export const CREATE_COURSE_CONTENT = "CREATE_COURSE_CONTENT";
export const GET_TRAINERS_AVAILABILITY = "GET_TRAINERS_AVAILABILITY";
export const GET_SCHEDULED_TRAINERS = "GET_SCHEDULED_TRAINERS";
export const GET_NOMINEES_OF_SCHEDULE = "GET_NOMINEES_OF_SCHEDULE";
export const CREATE_LEARNING_OUTCOME = "CREATE_LEARNING_OUTCOME";
export const UPDATE_LEARNING_OUTCOME = "UPDATE_LEARNING_OUTCOME";
export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const CREATE_COURSE_MATERIALS = "CREATE_COURSE_MATERIALS";
export const SUBMIT_COURSE_DETAILS_BY_TRAINER =
  "SUBMIT_COURSE_DETAILS_BY_TRAINER";
export const REVIEW_LEARNING_OUTCOME = "REVIEW_LEARNING_OUTCOME";
export const GET_PRE_REQUISITES = "GET_PRE_REQUISITES";
export const CREATE_LESSON_PLAN = "CREATE_LESSON_PLAN";
export const UPDATE_LESSON_PLAN = "UPDATE_LESSON_PLAN";
export const GET_QUESTION_BANK = "GET_QUESTION_BANK";
export const ADD_COMMENT = "ADD_COMMENT";
export const GET_MODULE = "GET_MODULE";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const GET_DESIGNATION_BY_ID = "GET_DESIGNATION_BY_ID";
export const GET_BUSINESS_GROUP = "GET_BUSINESS_GROUP";
export const GET_POLICY = "GET_POLICY";
export const SUPERSET_REPORT_LIST = "SUPERSET_REPORT_LIST";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const DOWNLOADABLE_URL = "DOWNLOADABLE_URL";
export const REVIEW_COURSE = "REVIEW_COURSE";
export const ASSIGN_SECONDARY_TRSINER = "ASSIGN_SECONDARY_TRAINER";
export const REVIEW_TRAINER_OR_NOMINEE = "REVIEW_TRAINER_OR_NOMINEE";
export const PUT_ELIGIBLE_LIST = "PUT_ELIGIBLE_LIST";
export const NOMINATE_PARTICIPANTS = "NOMINATE_PARTICIPANTS";
export const REPLACE_TRAINER_OR_NOMINEE = "REPLACE_TRAINER_OR_NOMINEE";
export const UPDATE_COMMENT_STATUS = "UPDATE_COMMENT_STATUS";
export const GET_REGION = "/getRegion";
export const GET_TOOLS = "/GET_TOOLS";
export const GET_TRAINING_AID = "/GET_TRAINING_AID";
export const GET_RESOURCE = "/getResource";
export const GET_REFERENCE = "/getRefernce";
export const GET_LEARNING_OUTCOME = "/getLearningOutcome";
export const GET_DOMAIN = "/getDomain";
export const GET_COMPETENCY = "/getCompetency";
export const GET_PROFICIENCY = "/getProficiency";
export const GET_CURICULUM = "/getCuriculum";
export const GET_INDUSTRY = "/GET_INDUSTRY";
export const GET_USERS = "/getUsers";
export const GET_USERS_ON_TABS = "/GET_USERS_ON_TABS";
export const GET_ENCRYPTION_TOKEN = "/GET_ENCRYPTION_TOKEN";
export const GET_USER_DETAILS_BY_ID = "/GET_USER_DETAILS_BY_ID";
export const GET_LMS_ROLES = "/GET_LMS_ROLES";
export const CREATE_EXTERNAL_USER = "/CREATE_EXTERNAL_USER";
export const UPDATE_EXTERNAL_USER = "/UPDATE_EXTERNAL_USER";
export const SUSPEND_EXTERNAL_USER = "/SUSPEND_EXTERNAL_USER";
export const MAP_ROLES_TO_USER = "/MAP_ROLES_TO_USER";
export const UN_MAP_ROLES_TO_USER = "/UN_MAP_ROLES_TO_USER";
export const GET_USERS_DETAIL = "/getUsersDetail";
export const GET_LOGGED_IN_USERS_DETAIL = "/getLoggedInUsersDetail";
export const GET_COURSE_REQUEST = "/getCourseRequest";
export const GET_COURSE = "/getCourse";
export const PUT_ASSIGN_REQUEST = "/putAssign";
export const GET_ATTENDANCE_FOR_SCHEDULE = "/GET_ATTENDANCE_FOR_SCHEDULE";
export const GET_ASSET_LIST = "/GET_ASSET_LIST";
export const GET_ASSET_CATEGORY_LIST = "/GET_ASSET_CATEGORY_LIST";
export const GET_ASSET_HISTORY = "/GET_ASSET_HISTORY";
export const GET_ASSET_LIST_BY_ID = "/GET_ASSET_LIST_BY_ID";
export const GET_DOCUMENTS = "/GET_DOCUMENTS";
export const GET_ASSET_DETAILS_BY_ID = "/GET_ASSET_DETAILS_BY_ID";
export const GET_ASSET_TYPES = "/GET_ASSET_TYPES";
export const MARK_ATTENDANCE_FOR_SCHEDULE = "/MARK_ATTENDANCE_FOR_SCHEDULE";
export const CREATE_ASSET_DATA = "/CREATE_ASSET_DATA";
export const UPDATE_MAINTAINANCE_DATA = "/UPDATE_MAINTAINANCE_DATA";
export const UPDATE_ASSET_DATA = "/UPDATE_ASSET_DATA";
export const PUT_ASSIGN_REQUEST_EXISTING = "PUT_ASSIGN_REQUEST_EXISTING";
export const GET_ASSESSMENT_REQUEST = "/getAssessment";
export const GET_ASSESSMENT_CATLOG_BY_ID = "/GET_ASSESSMENT_CATLOG_BY_ID";
export const GET_SCHEDULED_BY_ASSESSMENT_ID = "/GET_SCHEDULED_BY_ASSESSMENT_ID";
export const GET_PARTICIPANT_BY_SCHEDULE_ID = "/GET_PARTICIPANT_BY_SCHEDULE_ID";
export const GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID =
  "/GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID";
export const GET_ASSIGNED_ASSESSMENT_TO_LEARNER =
  "/GET_ASSIGNED_ASSESSMENT_TO_LEARNER";
export const CREATE_ASSESSMENT_CATLOG = "/CREATE_ASSESSMENT_CATLOG";
export const GET_LEARNER_AND_RM_RESPONSE = "/GET_LEARNER_AND_RM_RESPONSE";
export const GET_ASSESSEMENT_RESULT_FOR_REVIEW =
  "/GET_ASSESSEMENT_RESULT_FOR_REVIEW";
export const START_LEARNER_ASSESSMENT = "/START_LEARNER_ASSESSMENT";
export const ASSESSMENTS_TO_BE_REVIEWED = "/ASSESSMENTS_TO_BE_REVIEWED";
export const END_LEARNER_ASSESSMENT = "/END_LEARNER_ASSESSMENT";
export const REQUEST_FOR_MODIFICATION = "/REQUEST_FOR_MODIFICATION";
export const SCHEDULE_ASSESSMENT_CATLOG = "/SCHEDULE_ASSESSMENT_CATLOG";
export const EDIT_SCHEDULE_ASSESSMENT_CATLOG =
  "/EDIT_SCHEDULE_ASSESSMENT_CATLOG";
export const BULK_UPLOAD_USERS = "/BULK_UPLOAD_USERS";
export const BULK_UPLOAD_ELIGIBLE_USERS = "/BULK_UPLOAD_ELIGIBLE_USERS";
export const REVIEW_MODIFICATION_REQUEST = "/REVIEW_MODIFICATION_REQUEST";
export const DELETE_SCHEDULED = "/DELETE_SCHEDULED";
export const UPDATE_ASSESSMENT_CATLOG = "/UPDATE_ASSESSMENT_CATLOG";
export const REVIEW_LEARNER_ASSESSMENT = "/REVIEW_LEARNER_ASSESSMENT";
export const GRADING_LIST = "/GRADING_LIST";
export const DELETE_SCHEDULED_ASSESSMENT = "/DELETE_SCHEDULED_ASSESSMENT";
export const GET_ASSESSMENT_CATLOG_LIST = "/GET_ASSESSMENT_CATLOG_LIST";
export const DELETE_ASSESSMENT_REQUEST = "/deleteAssessment";
export const GET_CURRICULUM_LIST = "GET_CURRICULUM_LIST";
export const CREATE_CURRICULUM_LIST = "CREATE_CURRICULUM_LIST";
export const UNMAP_CURRICULUM = "UNMAP_CURRICULUM";
export const UNMAP_SGA = "UNMAP_SGA";
export const DELETE_CURRICULUM_COURSE = "DELETE_CURRICULUM_COURSE";
export const GET_CURRICULUM_BY_ID = "GET_CURRICULUM_BY_ID";
export const GET_QUESTION_BANK_LIST = "GET_QUESTION_BANK_LIST";
export const BULK_UPLOAD_QB = "BULK_UPLOAD_QB";
export const GET_MODULE_QB_BY_IDS = "GET_MODULE_QB_BY_IDS";
export const GET_RA_BY_MODULE_ID = "GET_RA_BY_MODULE_ID";
export const GET_QUESTION_AND_OPTION_BY_MODULE =
  "GET_QUESTION_AND_OPTION_BY_MODULE";
export const DELETE_MODULE_OR_QB = "DELETE_MODULE_OR_QB";
export const CREATE_RA_FOR_QB = "CREATE_RA_FOR_QB";
export const UPDATE_RA_FOR_QB = "UPDATE_RA_FOR_QB";
export const UPDATE_QUESTION_IN_QB = "UPDATE_QUESTION_IN_QB";
export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";
export const CREATE_QUESTION_BANK = "CREATE_QUESTION_BANK";
export const UPDATE_QUESTION_BANK = "UPDATE_QUESTION_BANK";
export const GET_ASSESSMENT_MODULE_BY_QB_ID = "GET_ASSESSMENT_MODULE_BY_QB_ID";
export const GET_ALL_QUESTIONS_AND_MODULES = "GET_ALL_QUESTIONS_AND_MODULES";
export const GET_POLICY_LIST = "GET_POLICY_LIST";
export const GET_POLICY_LIST_BY_ID = "GET_POLICY_LIST_BY_ID";
export const GET_SCORM_DATA = "GET_SCORM_DATA";
export const SYNC_SCORM_DATA = "SYNC_SCORM_DATA";
export const CREATE_POLICY = "CREATE_POLICY";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const DELETE_POLICY = "DELETE_POLICY";
export const GET_SGA_LIST = "GET_SGA_LIST";
export const GET_ROLES = "GET_ROLES";
export const GET_EXPERTISELEVEL_LIST = "GET_EXPERTISELEVEL_LIST";
export const GET_ASSESSMENT_BY_PARTICIPANT_ID =
  "GET_ASSESSMENT_BY_PARTICIPANT_ID";
export const SUBMIT_LEARNING_ASSESSMENT = "SUBMIT_LEARNING_ASSESSMENT";
export const GET_FILTER_ROLES = "GET_FILTER_ROLES";
export const GET_INDUSTRY_JOB_ROLES = "GET_INDUSTRY_JOB_ROLES";
export const GET_SBU = "GET_SBU";
export const GET_LEVEL = "GET_LEVEL";
export const GET_ALL_SELF_ASSESSMENT = "GET_ALL_SELF_ASSESSMENT";
export const GET_CURRICULUM_BY_ROLE = "GET_CURRICULUM_BY_ROLE_ID";
export const DELETE_SGA_COURSE = "DELETE_SGA_COURSE";
export const GET_GRADES = "GET_GRADES";
export const GET_LOCATION = "GET_LOCATION";
export const GET_USER_LOCATION = "GET_USER_LOCATION";
export const GET_USER_MASTER = "GET_USER_MASTER";
export const GET_TAGS = "GET_TAGS";
export const GET_CLASSROOM = "GET_CLASSROOM";
export const FETCH_CALENDAR = "FETCH_CALENDAR";
export const GET_SGA_BY_ID = "GET_SGA_BY_ID";
export const MAP_TO_SGA = "MAP_TO_SGA";
export const GET_ASSIGNED_ASSESSMENT_FOR_LEARNER =
  "GET_ASSIGNED_ASSESSMENT_FOR_LEARNER";
export const CREATE_EXTENSION_REQUEST = "CREATE_EXTENSION_REQUEST_URL";
export const CREATE_SGA_EXTENSION_REQUEST = "CREATE_SGA_EXTENSION_REQUEST";
export const UPDATE_RE_ATTEMPT_DAYS = "UPDATE_RE_ATTEMPT_DAYS";

export const GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID =
  "GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID";
export const GET_ILP_COURSE = "GET_ILP_COURSE";
export const GET_UN_MAPPED_COURSE = "GET_UN_MAPPED_COURSE";
export const GET_MAPPED_CURRICULUM_LIST = "GET_MAPPED_CURRICULUM_LIST";
export const GET_COURSE_BY_CURRICULUM_USER_ID =
  "GET_COURSE_BY_CURRICULUM_USER_ID";
export const GET_MAPPED_SGA_LIST = "GET_MAPPED_SGA_LIST";
export const GET_OVERALL_STATISTICS = "GET_OVERALL_STATISTICS";
export const GET_TRAINING_HISTORY = "GET_TRAINING_HISTORY";
export const GET_MAPPED_ASSESSMENT = "GET_MAPPED_ASSESSMENT";
export const GET_PENDING_SKILL_UPDATE = "GET_PENDING_SKILL_UPDATE";
export const UPLOAD_SKILL_DOCS = "UPLOAD_SKILL_DOCS";
export const APPROVE_SKILL_DOCS = "APPROVE_SKILL_DOCS";
export const GET_LO_FOR_ASSESSMENT = "GET_LO_FOR_ASSESSMENT";
export const GET_LIST_OF_SGA = "GET_LIST_OF_SGA";
export const MAP_SGA = "MAP_SGA";
export const MAP_CURRICULUM = "MAP_CURRICULUM";
export const MAP_COURSE = "MAP_COURSE";
export const APPROVE_MAPPED_YEAR = "APPROVE_MAPPED_YEAR";
export const MAP_YEAR = "MAP_YEAR";
export const GET_COURSE_COUNT_FOR_YEAR = "GET_COURSE_COUNT_FOR_YEAR";
export const GET_ASSESSMENT_BY_STATUS = "GET_ASSESSMENT_BY_STATUS";
export const MAP_ASSESSMENT = "MAP_ASSESSMENT";
export const GET_MAPPED_CERTIFICATION_LIST = "GET_MAPPED_CERTIFICATION_LIST";
export const GET_USER_OVERVIEW_DATA = "GET_USER_OVERVIEW_DATA";
export const GET_MODIFICATION_REQUEST_DETAILS =
  "GET_MODIFICATION_REQUEST_DETAILS";
export const GET_MAPPED_MENTOR_LIST = "GET_MAPPED_MENTOR_LIST";
export const POST_MAP_MENTOR_AND_MENTEE = "POST_MAP_MENTOR_AND_MENTEE";
export const PUT_REPLACE_MENTOR = "PUT_REPLACE_MENTOR";
export const GET_ALL_MENTEES_UNDER_MENTOR = "GET_ALL_MENTEES_UNDER_MENTOR";
export const GET_ALL_MENTOR_SESSION_LIST = "GET_ALL_MENTOR_SESSION_LIST";
export const GET_ALL_MENTOR_MENTEE_SESSION_LIST =
  "GET_ALL_MENTOR_MENTEE_SESSION_LIST";
export const CREATE_SESSION_FOR_MENTEE = "CREATE_SESSION_FOR_MENTEE";
export const UPDATE_SESSION_FOR_MENTEE = "UPDATE_SESSION_FOR_MENTEE";
export const GET_LIST_OF_COMPLETE_SESSION_DETAIL =
  "GET_LIST_OF_COMPLETE_SESSION_DETAIL";
export const GET_ALL_MENTEE_SESSION_LIST = "GET_ALL_MENTEE_SESSION_LIST";
export const GET_ALL_MENTOR_UNDER_MENTEE_LIST =
  "GET_ALL_MENTOR_UNDER_MENTEE_LIST";
export const GET_MENTOR_SESSION_LIST = "GET_MENTOR_SESSION_LIST_URL";
export const PUT_FEEDBACK_FROM_MENTEE = "PUT_FEEDBACK_FROM_MENTEE";
export const PUT_UNLINK_MENTEE_FROM_MENTOR = "PUT_UNLINK_MENTEE_FROM_MENTOR";
export const LOAD_FACTOR = "/LOAD_FACTOR";
export const GET_SCHEDULED_COURSE_BY_ID = "/GET_SCHEDULED_COURSE_BY_ID";
export const FREEZE_LOAD_FACTOR = "/FREEZE_LOAD_FACTOR";
export const REFRESH_LOAD_FACTOR = "/REFRESH_LOAD_FACTOR";
export const SCEDULE_COURSE_PUBLISH = "/SCEDULE_COURSE_PUBLISH";
export const UPDATE_SCHEDULED_COURSE = "/UPDATE_SCHEDULED_COURSE";
export const MODIFY_LOAD_FACTOR = "/MODIFY_LOAD_FACTOR";
export const GET_COURSE_PEOPLE = "/GET_COURSE_PEOPLE";
export const GET_LEARNER_FEEDBACK = "GET_LEARNER_FEEDBACK";
export const GET_LEARNER_FEEDBACK_BY_ID = "GET_LEARNER_FEEDBACK_BY_ID";
export const GET_TRAINER_FEEDBACK = "GET_TRAINER_FEEDBACK";
export const UPDATE_TRAINER_FEEDBACK = "UPDATE_TRAINER_FEEDBACK";
export const GET_COURSE_RATINGS = "GET_COURSE_RATINGS";
export const GET_SCHEDULE_DETAILS = "GET_SCHEDULE_DETAILS";
export const GET_COURSE_ACTIVITY = "GET_COURSE_ACTIVITY";
export const GET_SKILL_ASSESSMENT = "GET_SKILL_ASSESSMENT";
export const GET_SKILL_ASSESSMENT_TRAINING_HISTORY =
  "GET_SKILL_ASSESSMENT_TRAINING_HISTORY";
export const GET_SCHEDULED_COURSE = "GET_SCHEDULED_COURSE";
export const MAP_SCHEDULE_COURSE = "MAP_SCHEDULE_COURSE";
export const MAIL_COURSE = "MAIL_COURSE";
export const GET_FEEDBACK_QUESTION = "GET_FEEDBACK_QUESTION";
export const POST_FEEDBACK_QUESTION = "POST_FEEDBACK_QUESTION";
export const POST_MODULE_FEEDBACK = "POST_MODULE_FEEDBACK";
export const DELETE_MODULE_FEEDBACK = "DELETE_MODULE_FEEDBACK";
export const GET_NOMINATION_REQUEST = "GET_NOMINATION_REQUEST";
export const GET_REPLACEMENT_REQUEST = "GET_REPLACEMENT_REQUEST";
export const GET_CLASSROOM_LIST = "GET_CLASSROOM_LIST";
export const GET_CLASSROOM_LIST_BY_ID = "GET_CLASSROOM_LIST_BY_ID";
export const CREATE_CLASSROOM = "CREATE_CLASSROOM";
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const GET_JOB_ROLE_LIST = "GET_IJOB_ROLE_LIST";
export const GET_JOB_ROLE_LIST_BY_ID = "GET_INTENDED_AUDIENCE_LIST_BY_ID";
export const CREATE_JOB_ROLE = "CREATE_JOB_ROLE";
export const UPDATE_JOB_ROLE = "UPDATE_JOB_ROLE";
export const DELETE_JOB_ROLE = "DELETE_JOB_ROLE";
export const GET_INDUSTRY_LIST = "GET_INDUSTRY_LIST";
export const GET_INDUSTRY_LIST_BY_ID = "GET_INDUSTRY_LIST_BY_ID";
export const CREATE_INDUSTRY = "CREATE_INDUSTRY";
export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const DELETE_INDUSTRY = "DELETE_INDUSTRY";
export const GET_DOMAIN_LIST = "GET_DOMAIN_LIST";
export const GET_DOMAIN_BY_ID = "GET_DOMAIN_BY_ID";
export const CREATE_DOMAIN = "CREATE_DOMAIN";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const GET_COMPETENCY_LIST = "GET_COMPETENCY_LIST";
export const GET_COMPETENCY_BY_ID = "GET_COMPETENCY_BY_ID";
export const CREATE_COMPETENCY = "CREATE_COMPETENCY";
export const UPDATE_COMPETENCY = "UPDATE_COMPETENCY";
export const DELETE_COMPETENCY = "DELETE_COMPETENCY";
export const DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE";
export const DOWNLOAD_ASSESSMENT_CERTIFICATE =
  "DOWNLOAD_ASSESSMENT_CERTIFICATE";
export const UPDATE_WBT_COURSE_STATUS = "UPDATE_WBT_COURSE_STATUS";
export const GET_ALL_LEARNING_OUTCOME_LIST = "GET_ALL_LEARNING_OUTCOME_LIST";
export const GET_LEARNING_OUTCOME_BY_ID = "GET_LEARNING_OUTCOME_BY_ID";
export const CREATE_MASTER_LEARNING_OUTCOME = "CREATE_MASTER_LEARNING_OUTCOME";
export const UPDATE_MASTER_LEARNING_OUTCOME = "UPDATE_MASTER_LEARNING_OUTCOME";
export const UN_ARCHIVE_LEARNING_OUTCOME = "UN_ARCHIVE_LEARNING_OUTCOME";
export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN";
export const LOGIN_EXTERNAL_USER = "LOGIN_EXTERNAL_USER";
export const CREATE_PASSWORD_EXTERNAL_USER = "CREATE_PASSWORD_EXTERNAL_USER";
export const TRIGGER_RESET_PASSWORD_EXTERNAL_USER =
  "TRIGGER_RESET_PASSWORD_EXTERNAL_USER";
export const GET_SSO_LOGIN_URL = "GET_SSO_LOGIN_URL";
export const LOGOUT = "LOGOUT";
export const GET_REGION_LIST = "GET_REGION_LIST";
export const GET_REGION_LIST_BY_ID = "GET_REGION_LIST_BY_ID";
export const CREATE_REGION = "CREATE_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";
export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const GET_SUB_LOCATION_LIST = "GET_SUB_LOCATION_LIST";
export const GET_SUB_LOCATION_LIST_BY_LOCATION =
  "GET_SUB_LOCATION_LIST_BY_LOCATION";
export const GET_SUB_LOCATION_LIST_BY_ID = "GET_SUB_LOCATION_LIST_BY_ID";
export const GET_LOCATION_LIST_BY_ID = "GET_LOCATION_LIST_BY_ID";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_SUB_LOCATION = "CREATE_SUB_LOCATION";
export const UPDATE_SUB_LOCATION = "UPDATE_SUB_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_SUB_LOCATION = "DELETE_SUB_LOCATION";
export const CREATE_ASSET = "CREATE_ASSET";
export const EDIT_ASSET = "EDIT_ASSET";
export const FETCH_ASSETS_FOR_SCHEDULE = "FETCH_ASSETS_FOR_SCHEDULE";
export const POST_ASSETS_FOR_SCHEDULE = "POST_ASSETS_FOR_SCHEDULE";
export const DELETE_ASSETS_FOR_SCHEDULE = "DELETE_ASSETS_FOR_SCHEDULE";
export const GET_CLASSROOM_LIST_BY_LOCATION_ID =
  "GET_CLASSROOM_LIST_BY_LOCATION_ID";

// Master endpoints
export const PUT_DATA_URL = (id: string) => `${courseApiUrl} ${id}`;
export const GET_REGION_REQUEST_URL = () =>
  `${userApiUrl}/master?request_type=region`;
export const GET_DESIGNATION_LIST_URL = () =>
  `${userApiUrl}/master?request_type=designation`;
export const GET_DESIGNATION_BY_ID_URL = id =>
  `${userApiUrl}/master?request_type=designation&id=${id}`;
export const GET_BUSINESS_GROUP_LIST_URL = () =>
  `${userApiUrl}/master?request_type=business-group`;
export const GET_RESOURCE_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=resource`;
export const GET_REFERNCE_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=reference`;
export const GET_DOMAIN_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=domain`;
export const GET_PROFICIENCY_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=proficiency`;
export const GET_COMPETENCY_REQUEST_URL = (domainId: string) =>
  `${courseApiUrl}/master?request_type=competency&domain=${domainId}`;
export const GET_PRE_REQUISITES_URL = () =>
  `${courseApiUrl}/master?request_type=prerequisite`;
export const GET_TOOLS_URL = () =>
  `${courseApiUrl}/master?request_type=resource&type=tool`;
export const GET_TRAINING_AID_URL = () =>
  `${courseApiUrl}/master?request_type=resource&type=training_aid`;
export const GET_LEARNING_OUTCOME_REQUEST_URL = (
  domainId: string,
  competencyId: string,
  proficiencyId: string
) =>
  `${courseApiUrl}/master?request_type=learning-outcome&domain=${domainId}&competency=${competencyId}&proficiency=${proficiencyId}`;
export const GET_CURICULUM_REQUEST_URL = () =>
  `${courseApiUrl}/master?request_type=curriculum`;
export const GET_INDUSTRY_URL = () =>
  `${userApiUrl}/master?request_type=industry`;
export const REVIEW_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=review-learning-outcome`;
export const GET_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/master?request_type=question-bank`;
export const GET_MODULES_URL = id =>
  `${assessmentApiUrl}/master?request_type=module&question_bank_id=${id}`;
export const GET_POLICY_URL = () =>
  `${assessmentApiUrl}/master?request_type=policy`;
export const GET_ROLES_URL = (getMappedRole?: string) => {
  let url = `${userApiUrl}/master?request_type=job-role`;
  if (getMappedRole) {
    url += `&get_mapped_role=${getMappedRole}`;
  }
  return url;
};
export const GET_FILTER_ROLES_URL = () =>
  `${userApiUrl}/master?request_type=job-role`;
export const GET_INDUSTRY_JOB_ROLES_URL = ind_id => {
  let url = `${userApiUrl}/master?request_type=job-role`;
  if (ind_id) {
    url += `&industry_id=${ind_id}`;
  }
  return url;
};
export const GET_GRADES_URL = () => `${userApiUrl}/master?request_type=grade`;
export const GET_TAGS_URL = (tagType: string) =>
  `${assessmentApiUrl}/master?request_type=tag&type=${tagType}`;
export const GET_LOCATION_URL = () =>
  `${courseApiUrl}/master?request_type=location`;
export const GET_USER_LOCATION_URL = () =>
  `${userApiUrl}/master?request_type=location`;
export const GET_CLASSROOM_URL = (id, start_date, end_date, schedule_id) => {
  let url = `${courseApiUrl}/master?request_type=class-room&location_id=${id}`;
  if (start_date) {
    url += `&start_date=${start_date}`;
  }
  if (end_date) {
    url += `&end_date=${end_date}`;
  }
  if (schedule_id) {
    url += `&course_schedule_id=${schedule_id}`;
  }
  return url;
};
export const GET_CALENDAR_URL = (date, location_id) =>
  `${courseApiUrl}/master?request_type=calendar&date=${date}&location_id=${location_id}`;

export const GET_ASSESSMENT_ROLES_URL = () =>
  `${userApiUrl}/master?request_type=job-role`;
export const GET_SBU_URL = () => `${userApiUrl}/master?request_type=sbu`;
export const GET_LEVEL_URL = () => `${userApiUrl}/master?request_type=level`;
export const GET_USER_MASTER_WITHOUT_FILTER_URL = type => {
  let url = `${userApiUrl}/master?request_type=user`;
  if (type) {
    url += `&role=${type}`;
  }

  return url;
};

export const GET_USER_MASTER_URL = (
  roleId: string,
  sbuId: string,
  type?: string
) => {
  let url = `${userApiUrl}/master?request_type=user`;
  if (type) {
    url += `&role=${type}`;
  }
  if (roleId) {
    url += `&role_id=${roleId}`;
  }

  if (sbuId) {
    url += `&sbu_id=${sbuId}`;
  }

  return url;
};

export const BULK_UPLOAD_USERS_URL = () =>
  `${userApiUrl}/user?request_type=participant`;

export const BULK_UPLOAD_ELIGIBLE_USERS_URL = id =>
  `${courseApiUrl}/course?request_type=eligible-list&course_schedule_id=${id}`;

export const SUPERSET_REPORT_LIST_URL = (
  type?: string,
  dashboard_id?: string
) => {
  let url = `${userApiUrl}/master?request_type=report`;
  if (type) {
    url += `&type=${type}`;
  }
  if (dashboard_id) {
    url += `&dashboard_id=${dashboard_id}`;
  }
  return url;
};

// Course request endpoints
export const GET_COURSE_REQUEST_LIST_URL = (status: string) =>
  `${courseApiUrl}/course?request_type=course-request&status=${status}`;
export const GET_COURSE_REQUEST_BY_ID_URL = (id: string) =>
  `${courseApiUrl}/course?request_type=course-request&id=${id}`;
export const GET_COURSE_REQUEST_FILTERS_URL = (
  status: string,
  filters: string
) =>
  `${courseApiUrl}/course?request_type=course-request&status=${status}&course_request_type=${filters}`;
export const GET_COURSE_REQUEST_URL = (status: string) =>
  `${courseApiUrl}/course?request_type=course-request?status=${status}`;
export const CREATE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=course-request`;
export const UPDATE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=course-request`;
export const APPROVE_COURSE_REQUEST_URL = () =>
  `${courseApiUrl}/course?request_type=review-course-request`;

// Course endpoints
export const GET_COURSE_LIST_API = (
  status: string,
  course_type: string,
  domain: string | null,
  page?: string
) => {
  let url = `${courseApiUrl}/course?request_type=course-content&status=${status}&course_type=${course_type}`;
  if (domain) url += `&domain=${domain}`;
  if (page) url += `&page=${page}`;
  return url;
};
export const GET_USER_SPECIFIC_COURSE_LIST_API = (
  status: string,
  course_type: string,
  user_id: string
) =>
  `${courseApiUrl}/course?request_type=course-content&status=${status}&course_type=${course_type}&user_id=${user_id}`;
export const GET_COURSE_CONTENT_API = (
  id: string,
  course_version_id?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=course-content`;

  if (course_version_id) {
    queryString += `&course_version_id=${course_version_id}`;
  } else if (id) {
    queryString += `&id=${id}`;
  }

  return queryString;
};

export const CREATE_COURSE_CONTENT_API = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const GET_TRAINERS_AVAILABILITY_URL = schedule_id => {
  let url = `${courseApiUrl}/course?request_type=trainer-availability`;
  if (schedule_id) {
    url += `&course_schedule_id=${schedule_id}`;
  }
  return url;
};
export const GET_SCHEDULED_TRAINERS_URL = id =>
  `${courseApiUrl}/course?request_type=trainer&schedule_id=${id}`;
export const GET_NOMINEES_OF_SCHEDULE_URL = (schedule_id, type) =>
  `${courseApiUrl}/course?request_type=nominee&schedule_id=${schedule_id}&type=${type}`;
export const UPDATE_COURSE_CONTENT_API = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const REVIEW_COURSE_API = () =>
  `${courseApiUrl}/course?request_type=review-course`;
export const ASSIGN_SECONDARY_TRAINERS = () =>
  `${courseApiUrl}/course?request_type=trainer`;
export const REVIEW_TRAINER_OR_NOMINEE_URL = () =>
  `${courseApiUrl}/course?request_type=review-schedule`;
export const NOMINATE_PARTICIPANTS_URL = () =>
  `${courseApiUrl}/course?request_type=nominee`;
export const PUT_ELIGIBLE_LIST_URL = id =>
  `${courseApiUrl}/course?request_type=eligible-list&course_schedule_id=${id}`;
export const REPLACE_TRAINER_OR_NOMINEE_URL = () =>
  `${courseApiUrl}/course?request_type=replace`;
export const GET_PUBLISHED_COURSE_LIST_API = () =>
  `${courseApiUrl}/course?request_type=course-content&status=published`;
export const SUBMIT_COURSE_DETAILS_BY_TRAINER_URL = () =>
  `${courseApiUrl}/course?request_type=course-content`;
export const DOWNLOAD_COURSE_STAGE_DATA_API = (id: string, type: string) =>
  `${courseApiUrl}/course?request_type=course-content&id=${id}&type=${type}`;

export const GET_COURSE_CONTENT_BY_IDS_API = (id: number[]) =>
  `${courseApiUrl}/course?request_type=remedial_action&id=${id}`;
export const GET_ELIGIBLE_LIST_URL = (
  id,
  filters,
  roleId,
  employeeType,
  sbuId
) => {
  let url = `${courseApiUrl}/course?request_type=eligible-list&course_schedule_id=${id}&filters=${filters}`;

  if (roleId) {
    url += `&role_id=${roleId}`;
  }
  if (employeeType) {
    url += `&employee_type=${employeeType}`;
  }
  if (sbuId) {
    url += `&sbu_id=${sbuId}`;
  }

  return url;
};

export const GET_GLOBAL_SETTINGS_URL = () =>
  `${courseApiUrl}/course?request_type=global-setting`;
export const GET_COURSE_SCHEDULED_LIST_URL = (
  courseId,
  fromDate,
  toDate,
  instructorId,
  locationId,
  classroomId,
  status
) => {
  let url = `${courseApiUrl}/course?request_type=schedule`;

  if (courseId) {
    url += `&course_id=${courseId}`;
  }
  if (fromDate) {
    url += `&from_date=${fromDate}`;
  }
  if (toDate) {
    url += `&to_date=${toDate}`;
  }
  if (instructorId) {
    url += `&instructor_id=${instructorId}`;
  }
  if (locationId) {
    url += `&location_id=${locationId}`;
  }
  if (classroomId) {
    url += `&classroom_id=${classroomId}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  return url;
};
export const GET_TAINERS_AVAILABILITY_URL = () =>
  `${courseApiUrl}/course?request_type=trainer-availability`;

// Course Stages endpoints
export const CREATE_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=learning-outcome`;
export const UPDATE_LEARNING_OUTCOME_URL = () =>
  `${courseApiUrl}/course?request_type=learning-outcome`;
export const GET_ASSESSMENT_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment&course_id=${id}`;
export const CREATE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const UPDATE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const DELETE_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=course-assessment`;
export const CREATE_COURSE_MATERIALS_URL = () =>
  `${courseApiUrl}/course?request_type=course-material`;
export const CREATE_LESSON_PLAN_URL = () =>
  `${courseApiUrl}/course?request_type=lesson-plan`;
export const UPDATE_LESSON_PLAN_URL = () =>
  `${courseApiUrl}/course?request_type=lesson-plan`;
export const ADD_COMMENT_URL = () =>
  `${courseApiUrl}/course?request_type=review-learning-outcome`;
export const GET_ASSESSMENT_BY_PARTICIPANT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=start&participant_id=${id}`;
export const SUBMIT_LEARNING_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=end`;
export const UPDATE_COMMENT_STATUS_URL = () =>
  `${courseApiUrl}/course?request_type=comment-status`;
export const GET_ASSIGNED_ASSESSMENT_FOR_LEARNER_URL = (
  status,
  assessmentType,
  tag
) => {
  let url = `${assessmentApiUrl}/assessment?request_type=participant&status=${status}`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};
export const CREATE_EXTENSION_REQUEST_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=raise_request`;
export const GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=participant&participant_id=${id}`;
export const UPDATE_RE_ATTEMPT_DAYS_API = () =>
  `${assessmentApiUrl}/assessment?request_type=reattempt`;
// Users endpoints
export const GET_ALL_USERS_REQUEST_URL = (role: string) =>
  `${userApiUrl}/master?request_type=user&role=${role}`;
export const GET_USERS_ON_TABS_URL = (tab: string) =>
  `${userApiUrl}/master?request_type=user&tab=${tab}`;
export const CREATE_EXTERNAL_USERS_URL = () =>
  `${userApiUrl}/user?request_type=user`;
export const SUSPEND_EXTERNAL_USERS_URL = () =>
  `${userApiUrl}/user?request_type=user`;
export const GET_USER_DETAILS_BY_ID_URL = id =>
  `${userApiUrl}/master?request_type=user&id=${id}`;
export const MAP_ROLES_TO_USER_URL = () =>
  `${userApiUrl}/user?request_type=map-role`;
export const GET_LMS_ROLES_URL = () =>
  `${userApiUrl}/master?request_type=lms-role`;
export const GET_USER_DETAILS_REQUEST_URL = (id: number[]) =>
  `${userApiUrl}/master?id=${id}&request_type=user`;
export const PUT_ASSIGN_URL = () =>
  `${courseApiUrl}/course?request_type=assign`;
export const UPLOAD_FILE_URL = () =>
  `${userApiUrl}/common?request_type=file-upload`;
export const GET_REFRESH_TOKEN_API = (type: string, role: string) =>
  `${userApiUrl}/user?request_type=generate-token&type=${type}&role=${role}`;
export const LOGIN_EXTERNAL_USER_API = () =>
  `${userApiUrl}/user?request_type=login`;
export const CREATE_PASSWORD_EXTERNAL_USER_API = (emailToken: string) =>
  `${userApiUrl}/user?request_type=reset-password&token=${emailToken}`;
export const TRIGGER_RESET_PASSWORD_EXTERNAL_USER_API = () =>
  `${userApiUrl}/user?request_type=reset-password`;
export const GET_SSO_LOGIN_URL_API = () =>
  `${userApiUrl}/user?request_type=get-oauth-login-url`;
export const LOGOUT_API = () => `${userApiUrl}/user?request_type=logout`;

export const GET_ENCRYPTION_TOKEN_URL = () =>
  `${userApiUrl}/user?request_type=key`;

export const DOWNLOADABLE_URL_API = () =>
  `${userApiUrl}/common?request_type=get-templates`;

export const GET_CURRICULUM_LIST_API = (
  status: string,
  filters?: {
    domain?: string[];
    role?: string[];
    specialCurriculum?: boolean;
  },
  roles?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=curriculum&status=${status}`;

  if (filters?.domain && filters.domain.length > 0) {
    const domainQuery = `domain_id=${filters.domain}`;
    queryString += `&${domainQuery}`;
  }

  if (filters?.role && filters.role.length > 0) {
    const roleQuery = filters.role.map(id => `role_id=${id}`).join("&");
    queryString += `&${roleQuery}`;
  }

  if (roles) {
    queryString += `&role_id=${roles}`;
  }

  if (filters && "specialCurriculum" in filters) {
    queryString += `&is_special_curriculum=${filters?.specialCurriculum ? "1" : "0"}`;
  }

  return queryString;
};

export const CREATE_CURRICULUM = () =>
  `${courseApiUrl}/course?request_type=curriculum`;
export const UNMAP_CURRICULUM_URL = () =>
  `${courseApiUrl}/course?request_type=unmap`;

export const UNMAP_SGA_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=unmap`;

export const GET_CURRICULUM_BY_ID_API = (id: number) =>
  `${courseApiUrl}/course?request_type=curriculum&id=${id}`;

// Question Bank API endpoints
export const GET_QUESTION_BANK_LIST_URL = (status: string) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}`;
export const GET_QUESTION_BANK_LIST_WITH_BOTH_URL = (
  status: string,
  tag: string,
  self_assessment: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&tag=${tag}&self_assessment=${self_assessment}`;
export const GET_QUESTION_BANK_LIST_WITH_TAG_URL = (
  status: string,
  tag: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&tag=${tag}`;
export const GET_QUESTION_BANK_LIST_WITH_SELF_URL = (
  status: string,
  self_assessment: string
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&status=${status}&self_assessment=${self_assessment}`;
export const CREATE_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const UPDATE_QUESTION_BANK_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const GET_QUESTIONS_LIST_URL = (module_id: string, page: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&module_id=${module_id}&page=${page}`;
export const GET_MODULE_QB_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=module&question_bank_id=${id}`;
export const GET_ASSESSMENT_MODULE_QB_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/master?request_type=module&question_bank_id=${id}`;
export const GET_ALL_QUESTIONS_AND_MODULES_URL = (question_bank_id: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&question_bank_id=${question_bank_id}`;
export const BULK_UPLOAD_QB_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&id=${id}`;
export const CREATE_RA_FOR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action`;
export const UPDATE_RA_FOR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action`;
export const UPDATE_QUESTION_IN_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question`;
export const GET_RA_BY_MODULE_ID_URL = (module_id: number) =>
  `${assessmentApiUrl}/assessment?request_type=remedial-action&module_id=${module_id}`;
export const DELETE_MODULE_OR_QB_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=question-bank`;
export const GET_QUESTION_AND_OPTION_BY_MODULE_URL = (
  module_id: string,
  page: number
) =>
  `${assessmentApiUrl}/assessment?request_type=question-bank&module_id=${module_id}&page_no=${page}`;
// Policy endpoints
export const UPSERT_POLICY_API = (type?: string, id?: string) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=policy`;

  if (type !== undefined) {
    queryString += `&type=${type}`;
  }

  if (id) {
    queryString += `&id=${id}`;
  }

  return queryString;
};

export const DELETE_POLICY_API = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=policy&id=${id}`;

//SGA endpoints
export const GET_SGA_LIST_API = () =>
  `${assessmentApiUrl}/assessment?request_type=sga`;

export const GET_ALL_SELF_ASSESSMENT_API = () =>
  `${assessmentApiUrl}/assessment?request_type=competency`;

export const GET_CURRICULUM_BY_ROLE_ID = (
  role_id: number,
  level_id?: number,
  course_type?: string[]
) => {
  let queryString = `${courseApiUrl}/course?request_type=curriculum&role_id=${role_id}`;

  if (level_id !== undefined) {
    queryString += `&level_id=${level_id}`;
  }

  if (course_type) {
    queryString += `&course_type=${course_type}`;
  }

  return queryString;
};

export const GET_SGA_BY_ID_API = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=sga&id=${id}`;

// Assessment Catlog
export const GET_ASSESSMENT_CATLOG_LIST_URL = (status, assessmentType, tag) => {
  let url = `${assessmentApiUrl}/assessment?request_type=assessment&status=${status}`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};

export const GET_ASSESSMENT_CATLOG_BY_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=assessment&id=${id}`;
export const CREATE_ASSESSMENT_CATLOG_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=assessment`;
export const SCHEDULE_ASSESSMENT_CATLOG_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=schedule`;
export const GET_SCHEDULED_BY_ASSESSMENT_ID_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=schedule&assessment_id=${id}`;
export const GET_PARTICIPANT_BY_SCHEDULE_ID_URL = (id: string) =>
  `${assessmentApiUrl}/assessment?request_type=schedule&schedule_id=${id}`;
export const REQUEST_FOR_MODIFICATION_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=raise_request`;
export const REVIEW_MODIFICATION_REQUEST_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review_request`;
export const DELETE_SCHEDULED_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=schedule`;
export const GET_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL = (
  schedule_id: number,
  assessment_id: number
) =>
  `${assessmentApiUrl}/assessment?request_type=review&schedule_id=${schedule_id}&assessment_id=${assessment_id}`;
export const GET_COMPETENCY_SCHEDULED_DETAILS_BY_SCHEDULE_ID_URL = (
  assessment_id: number
) =>
  `${assessmentApiUrl}/assessment?request_type=review&assessment_id=${assessment_id}`;
export const GET_ASSIGNED_ASSESSMENT_TO_LEARNER_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=participant&status=current`;
export const START_LEARNER_ASSESSMENT_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=start&participant_id=${id}`;
export const END_LEARNER_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=end`;
export const ASSESSMENTS_TO_BE_REVIEWED_URL = (assessmentType, tag) => {
  let url = `${assessmentApiUrl}/assessment?request_type=review&status=under_review`;
  if (assessmentType?.length > 0) {
    url += `&assessment_type=${assessmentType}`;
  }
  if (tag?.length > 0) {
    url += `&tag=${tag}`;
  }
  return url;
};
export const GET_ASSESSMENT_RESULT_FOR_REVIEW_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=review&participant_id=${id}`;
export const REVIEW_LEARNER_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review`;
export const GRADING_LISTING_URL = (user_id, course_schedule_id) =>
  `${assessmentApiUrl}/assessment?request_type=review&user_id=${user_id}&course_schedule_id=${course_schedule_id}`;
export const DELETE_SCHEDULED_ASSESSMENT_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=review`;
export const MAP_TO_SGA_API = () =>
  `${assessmentApiUrl}/assessment?request_type=sga`;
export const GET_LEARNER_AND_RM_RESPONSE_URL = (id: number) =>
  `${assessmentApiUrl}/assessment?request_type=competency&participant_id=${id}`;

// Scorm endpoint
export const GET_SCORM_DATA_API = (courseId: string) =>
  `${learnerApiUrl}/learner?request_type=scorm&course_id=${courseId}`;

export const SYNC_SCORM_DATA_API = () =>
  `${learnerApiUrl}/learner?request_type=scorm`;

//ILP endpoints
export const GET_ILP_COURSE_URL = (
  courseType: string,
  selfEnrollment: boolean,
  fyYear: string,
  userId?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&course_type=${courseType}&year=${fyYear}&type=mapped`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  queryString += `&is_self_enrollment=${selfEnrollment ? "1" : "0"}`;
  return queryString;
};

export const GET_UN_MAPPED_COURSE_URL = (userId?: number) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&type=unmapped`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_MAPPED_CURRICULUM_LIST_URL = (userId?: number) => {
  let queryString = `${courseApiUrl}/course?request_type=mapped-curriculum`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_COURSE_BY_CURRICULUM_USER_ID_URL = (
  userCurriculumId?: number
) => {
  let queryString = `${courseApiUrl}/course?request_type=mapped-curriculum`;
  if (userCurriculumId) {
    queryString += `&user_curriculum_id=${userCurriculumId}`;
  }
  return queryString;
};

export const GET_MAPPED_SGA_LIST_URL = (
  type: string,
  userId?: number,
  jobRoleId?: string
) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=mapped-sga&type=${type}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  if (jobRoleId) {
    queryString += `&job_role_id=${jobRoleId}`;
  }
  return queryString;
};

export const GET_OVERALL_STATISTICS_URL = (
  selfEnrollment: boolean,
  userId?: number
) => {
  let queryString = `${courseApiUrl}/course?request_type=statistics&is_self_enrolled=${selfEnrollment ? "1" : "0"}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_TRAINING_HISTORY_URL = (
  userId?: number,
  fyYear?: string,
  course_type?: string,
  status?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&type=history&year=${fyYear}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  if (course_type) {
    queryString += `&course_type=${course_type}`;
  }
  if (status) {
    queryString += `&status=${status}`;
  }
  return queryString;
};

export const GET_MAPPED_ASSESSMENT_URL = (
  fyYear?: string,
  userId?: number,
  user_sga_id?: number
) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=ilp`;
  if (fyYear) {
    queryString += `&year=${fyYear}`;
  }
  if (userId) {
    queryString += `&user_id=${userId}`;
  }

  if (user_sga_id) {
    queryString += `&user_sga_id=${user_sga_id}`;
  }
  return queryString;
};

export const GET_MODIFICATION_REQUEST_DETAILS_URL = (id: string) =>
  `${courseApiUrl}/course?request_type=ilp&type=reason&id=${id}`;

export const GET_LIST_OF_SGA_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=sga&status=published`;

export const MAP_SGA_URL = () =>
  `${assessmentApiUrl}/assessment?request_type=map`;

export const MAP_COURSE_RELEVANT_URL = () => {
  return `${courseApiUrl}/course?request_type=map`;
};

export const MAP_YEAR_URL = () => {
  return `${courseApiUrl}/course?request_type=map`;
};

export const GET_COURSE_COUNT_FOR_YEAR_URL = (year: string, userId: string) => {
  return `${courseApiUrl}/course?request_type=ilp&type=course_count&user_id=${userId}&year=${year}`;
};

export const GET_ASSESSMENT_BY_STATUS_URL = (status, rqquestType, userId) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=${rqquestType}&status=${status}`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const MAP_ASSESSMENT_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=map`;
};

export const GET_PENDING_SKILL_UPDATE_URL = (userId?: number) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=skill-document`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

export const GET_LO_FOR_ASSESSMENT_URL = (ids?: string) => {
  return `${courseApiUrl}/course?request_type=course-content&learning_outcome=1&ids=${ids}`;
};

export const UPLOAD_SKILL_DOCS_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=skill-document`;
};

export const APPROVE_SKILL_DOCS_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=skill-document`;
};

export const GET_MAPPED_CERTIFICATION_LIST_URL = () => {
  return `${assessmentApiUrl}/assessment?request_type=certification`;
};

export const GET_USER_OVERVIEW_DATA_URL = (userId?: string) => {
  let queryString = `${courseApiUrl}/course?request_type=ilp&&type=certification`;
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  return queryString;
};

// Load Factor API URL function
export const LOAD_FACTOR_API_URL = filters => {
  const queryParams: string[] = [];

  const { industry_id, job_role_id, curriculum_id, proficiency, year } =
    filters;

  if (industry_id?.length > 0) {
    queryParams.push(`industry_id=${industry_id.join(",")}`);
  }
  if (job_role_id?.length > 0) {
    queryParams.push(`job_role_id=${job_role_id.join(",")}`);
  }
  if (curriculum_id?.length > 0) {
    queryParams.push(`curriculum_id=${curriculum_id.join(",")}`);
  }
  if (proficiency?.length > 0) {
    queryParams.push(`proficiency=${proficiency.join(",")}`);
  }
  if (year?.length > 0) {
    queryParams.push(`year=${year}`);
  }

  const queryString = queryParams.length > 0 ? `&${queryParams.join("&")}` : "";
  return `${courseApiUrl}/course?request_type=load-factor${queryString}`;
};

export const GET_SCHEDULED_COURSE_BY_ID_URL = id => {
  return `${courseApiUrl}/course?request_type=schedule&id=${id}`;
};

export const FREEZE_LOAD_FACTOR_URL = () => {
  return `${courseApiUrl}/course?request_type=freeze`;
};

export const REFRESH_LOAD_FACTOR_URL = () => {
  return `${courseApiUrl}/course?request_type=load-factor`;
};

export const SCHEDULED_FOR_PUBLISH_URL = () => {
  return `${courseApiUrl}/course?request_type=schedule`;
};

export const UPDATE_SCHEDULED_COURSE_URL = () => {
  return `${courseApiUrl}/course?request_type=schedule`;
};

export const MODIFY_LOAD_FACTOR_URL = () => {
  return `${courseApiUrl}/course?request_type=modify`;
};

export const GET_COURSE_PEOPLE_URL = (id: string) => {
  return `${courseApiUrl}/course?request_type=view-people&load_factor_id=${id}`;
};

// Attendance
export const GET_ATTENDANCE_FOR_SCHEDULE_URL = schedule_id =>
  `${learnerApiUrl}/attendance?request_type=mark&course_schedule_id=${schedule_id}`;
export const MARK_ATTENDANCE_FOR_SCHEDULE_URL = () =>
  `${learnerApiUrl}/attendance?request_type=mark`;

// Asset Management
export const GET_ASSET_LIST_URL = (
  status?: string,
  location?: string,
  resource_type_id?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=resource&type=resource_type`;

  if (status) {
    queryString += `&filters=${status}`;
  }

  if (location) {
    queryString += `&location=${location}`;
  }

  if (resource_type_id) {
    queryString += `&resource_type_id=${resource_type_id}`;
  }

  return queryString;
};

export const GET_ASSET_HISTORY_URL = (id: string, instructor?: string) => {
  let queryString = `${courseApiUrl}/course?request_type=resource&type=history&resource_id=${id}`;
  if (instructor) {
    queryString += `&instructor=${instructor}`;
  }
  return queryString;
};

export const GET_ASSET_LIST_BY_ID_URL = (
  id: string,
  status?: string,
  location?: string
) => {
  let queryString = `${courseApiUrl}/course?request_type=resource&resource_type_id=${id}`;
  if (status) {
    queryString += `&status=${status}`;
  }
  if (location) {
    queryString += `&location=${location}`;
  }
  return queryString;
};

export const GET_DOCUMENTS_URL = (id: string) => {
  return `${courseApiUrl}/course?request_type=resource&type=document&resource_id=${id}`;
};

export const GET_ASSET_DETAILS_BY_ID_URL = (id: string) => {
  return `${courseApiUrl}/course?request_type=resource&resource_id=${id}`;
};

export const GET_ASSET_TYPES_URL = () => {
  return `${courseApiUrl}/master?request_type=resource&type=resource_type`;
};

export const CREATE_ASSET_DATA_URL = () => {
  return `${courseApiUrl}/course?request_type=resource`;
};

export const UPDATE_MAINTAINANCE_DATA_URL = () => {
  return `${courseApiUrl}/course?request_type=resource&is_document=1`;
};

// Feedback

export const GET_LEARNER_FEEDBACK_URL = id => {
  return `${learnerApiUrl}/learner?request_type=feedback&type=trainer-course&course_schedule_id=${id}`;
};

export const GET_TRAINER_FEEDBACK_URL = id => {
  return `${learnerApiUrl}/learner?request_type=feedback&type=rating&course_schedule_id=${id}`;
};

export const UPDATE_TRAINER_FEEDBACK_URL = () => {
  return `${learnerApiUrl}/learner?request_type=feedback&type=rating`;
};

export const GET_COURSE_RATINGS_URL = id => {
  return `${learnerApiUrl}/learner?request_type=feedback&course_schedule_id=${id}&type=review-rating`;
};

export const GET_SCHEDULE_DETAILS_API = (
  courseScheduleId: number | string,
  courseId: number | string
) => {
  let queryString = `${courseApiUrl}/course?request_type=course-activity`;
  if (courseScheduleId) {
    queryString += `&course_schedule_id=${courseScheduleId}`;
  }
  if (courseId) {
    queryString += `&course_id=${courseId}`;
  }
  return queryString;
};

export const GET_COURSE_ACTIVITY_API = (
  courseScheduleId: number | string,
  courseId: number | string
) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=course-activity`;
  if (courseScheduleId) {
    queryString += `&course_schedule_id=${courseScheduleId}`;
  }
  if (courseId) {
    queryString += `&course_id=${courseId}`;
  }

  return queryString;
};

export const GET_SKILL_ASSESSMENT_API = (
  courseScheduleId: number | string,
  courseId: number,
  userId?: string
) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=skill-document`;
  if (courseScheduleId) {
    queryString += `&course_schedule_id=${courseScheduleId}`;
  }
  if (userId) {
    queryString += `&user_id=${userId}`;
  }
  if (courseId) {
    queryString += `&course_id=${courseId}`;
  }

  return queryString;
};

export const GET_SCHEDULED_COURSE_API = (status, course_id, filterData) => {
  let queryString = `${courseApiUrl}/course?request_type=schedule&tab=${status}`;
  if (course_id) {
    queryString += `&course_id=${course_id}`;
  }
  if (filterData?.start_date) {
    queryString += `&from_date=${filterData.start_date}`;
  }
  if (filterData?.end_date) {
    queryString += `&to_date=${filterData.end_date}`;
  }
  if (filterData?.location) {
    queryString += `&location_id=${filterData.location}`;
  }
  if (filterData?.classroom) {
    queryString += `&classroom_id=${filterData.classroom}`;
  }
  if (filterData?.status) {
    queryString += `&status=${filterData.status}`;
  }

  return queryString;
};

export const GET_LEARNER_FEEDBACK_BY_ID_URL = (id, participant_id) => {
  return `${learnerApiUrl}/learner?request_type=feedback&course_schedule_id=${id}&participant_id=${participant_id}&type=review-rating`;
};

export const MAP_SCHEDULE_COURSE_API = () => {
  return `${courseApiUrl}/course?request_type=map`;
};

export const MAIL_COURSE_API = () => {
  return `${courseApiUrl}/course?request_type=mail`;
};

export const GET_FEEDBACK_QUESTION_API = (
  courseScheduleId,
  type,
  id,
  schedule_id,
  participant_id
) => {
  let queryString = `${learnerApiUrl}/learner?request_type=feedback&type=${type}`;

  if (courseScheduleId) {
    queryString += `&course_schedule_id=${courseScheduleId}`;
  }
  if (id) {
    queryString += `&id=${id}`;
  }

  if (schedule_id) {
    queryString += `&course_schedule_id=${schedule_id}`;
  }
  if (participant_id) {
    queryString += `&participant_id=${participant_id}`;
  }

  return queryString;
};

export const POST_FEEDBACK_QUESTION_API = () => {
  return `${learnerApiUrl}/learner?request_type=feedback&type=course`;
};

export const POST_MODULE_FEEDBACK_API = () => {
  return `${learnerApiUrl}/learner?request_type=feedback&type=module`;
};

export const GET_NOMINATION_REQUEST_API = (type, course_id, filterData) => {
  let queryString = `${courseApiUrl}/course?request_type=nominee&type=${type}`;

  if (course_id) {
    queryString += `&course_id=${course_id}`;
  }
  if (filterData?.start_date) {
    queryString += `&from_date=${filterData.start_date}`;
  }
  if (filterData?.end_date) {
    queryString += `&to_date=${filterData.end_date}`;
  }
  if (filterData?.location) {
    queryString += `&location_id=${filterData.location}`;
  }
  if (filterData?.classroom) {
    queryString += `&classroom_id=${filterData.classroom}`;
  }

  return queryString;
};

export const GET_REPLACEMENT_REQUEST_API = schedule_id => {
  return `${courseApiUrl}/course?request_type=replacement${
    schedule_id ? `&schedule_id=${schedule_id}` : ""
  }`;
};

export const DOWNLOAD_COURSE_CERTIFICATE_API = (
  type,
  courseId,
  courseScheduleId,
  isParticipated
) => {
  let queryString = `${courseApiUrl}/course?request_type=certificate`;

  if (type) {
    queryString += `&type=${type}`;
  }

  if (courseId) {
    queryString += `&course_id=${courseId}`;
  }

  if (courseScheduleId) {
    queryString += `&course_schedule_id=${courseScheduleId}`;
  }
  if (isParticipated) {
    queryString += `&is_participated=${isParticipated}`;
  }
  return queryString;
};

export const DOWNLOAD_ASSESSMENT_CERTIFICATE_API = (
  type,
  sgaID,
  assessmentResultId,
  userId,
  id
) => {
  let queryString = `${assessmentApiUrl}/assessment?request_type=certificate`;

  if (type) {
    queryString += `&type=${type}`;
  }

  if (sgaID) {
    queryString += `&sga_id=${sgaID}`;
  }

  if (id) {
    queryString += `&id=${id}`;
  }

  if (assessmentResultId) {
    queryString += `&assessment_result_id=${assessmentResultId}`;
  }

  if (userId) {
    queryString += `&user_id=${userId}`;
  }

  return queryString;
};

export const UPDATE_WBT_COURSE_STATUS_API = () => {
  return `${courseApiUrl}/course?request_type=update-wbt-status`;
};

//Mentoring & Coaching

export const GET_MAPPED_MENTOR_LIST_URL = () => {
  return `${learnerApiUrl}/coaching?request_type=mentor`;
};

export const POST_MAP_MENTOR_AND_MENTEE_URL = () => {
  return `${learnerApiUrl}/coaching?request_type=map`;
};

export const PUT_REPLACE_MENTOR_URL = () => {
  return `${learnerApiUrl}/coaching?request_type=replace`;
};

export const GET_ALL_MENTEES_UNDER_MENTOR_URL = (
  mentorDomainId?: number,
  status?: string,
  domainId?: string
) => {
  let queryString = `${learnerApiUrl}/coaching?request_type=mentees`;

  if (domainId) {
    queryString += `&domain_id=${domainId}`;
  }
  if (mentorDomainId) {
    queryString += `&mentor_domain_id=${mentorDomainId}`;
  }

  if (status) {
    queryString += `&type=${status}`;
  }
  return queryString;
};

export const GET_ALL_MENTOR_SESSION_LIST_URL = (
  mentorDomainId?: number,
  mentorMenteeId?: number
) => {
  let queryString = `${learnerApiUrl}/coaching?request_type=session`;

  if (mentorDomainId) {
    queryString += `&mentor_domain_id=${mentorDomainId}`;
  }

  if (mentorMenteeId) {
    queryString += `&mentor_mentee_id=${mentorMenteeId}`;
  }
  return queryString;
};

export const PUT_UNLINK_MENTEE_FROM_MENTOR_URL = () => {
  return `${learnerApiUrl}/coaching?request_type=mentees`;
};

export const UPSERT_SESSION_FOR_MENTEE_URL = (
  mentorMenteeId?: number,
  sessionId?: number
) => {
  let queryString = `${learnerApiUrl}/coaching?request_type=session`;

  if (mentorMenteeId) {
    queryString += `&mentor_mentee_id=${mentorMenteeId}`;
  }

  if (sessionId) {
    queryString += `&session_id=${sessionId}`;
  }
  return queryString;
};

export const GET_ALL_MENTEE_SESSION_LIST_URL = () => {
  return `${learnerApiUrl}/coaching?request_type=session&type=mentee`;
};

export const GET_ALL_MENTOR_UNDER_MENTEE_LIST_URL = (status: string) => {
  return `${learnerApiUrl}/coaching?request_type=mentor&type=mentee&status=${status}`;
};

export const GET_MENTOR_SESSION_LIST_URL = (mentorMenteeId: number) => {
  return `${learnerApiUrl}/coaching?request_type=session&type=mentee&mentor_mentee_id=${mentorMenteeId}`;
};

export const PUT_FEEDBACK_FROM_MENTEE_URL = (sessionId: number) => {
  return `${learnerApiUrl}/coaching?request_type=feedback&type=mentee&session_id=${sessionId}`;
};

//Manage Master Data

export const UPSERT_CLASSROOM_LIST_URL = (
  id?: number,
  locationId?: number[]
) => {
  let queryString = `${courseApiUrl}/master?request_type=class-room`;

  if (id) {
    queryString += `&id=${id}`;
  }

  if (locationId) {
    queryString += `&location_id=${locationId}`;
  }
  return queryString;
};

export const GET_JOB_ROLE_LIST_URL = (id?: number) => {
  let queryString = `${userApiUrl}/master?request_type=job-role&is_master=1`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_JOB_ROLE_LIST_URL = (id?: number) => {
  let queryString = `${userApiUrl}/master?request_type=job-role`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_INDUSTRY_LIST_URL = (id?: number) => {
  let queryString = `${userApiUrl}/master?request_type=industry`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const GET_DOMAIN_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=domain&tab=master`;
  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_DOMAIN_LIST_URL = () => {
  return `${courseApiUrl}/master?request_type=domain`;
};

export const UPSERT_COMPETENCY_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=competency`;
  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const GET_ALL_LEARNING_OUTCOME_LIST_URL = (
  status: string,
  domainIds: string,
  competencyIds: string,
  proficiencyIds: string
) => {
  let queryString = `${courseApiUrl}/master?request_type=learning-outcome&tab=${status}`;

  if (domainIds) {
    queryString += `&domain=${domainIds}`;
  }

  if (competencyIds) {
    queryString += `&competency=${competencyIds}`;
  }

  if (proficiencyIds) {
    queryString += `&proficiency=${proficiencyIds}`;
  }

  return queryString;
};

export const UPSERT_LEARNING_OUTCOME_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=learning-outcome`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_REGION_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=region`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_LOCATION_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=location`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_SUB_LOCATION_LIST_URL = (id?: number) => {
  let queryString = `${courseApiUrl}/master?request_type=sub-location`;

  if (id) {
    queryString += `&id=${id}`;
  }
  return queryString;
};

export const UPSERT_SUB_LOCATION_LIST_BY_LOCATION_URL = (
  location_id?: string
) => {
  let queryString = `${courseApiUrl}/master?request_type=sub-location`;

  if (location_id) {
    queryString += `&location=${location_id}`;
  }
  return queryString;
};

export const UPSERT_ASSET_URL = () => {
  return `${courseApiUrl}/master?request_type=resource`;
};

export const GET_ASSETS_URL_FOR_SCHEDULE = (schedule_id?: any) => {
  let queryString = `${courseApiUrl}/course?request_type=resource&type=history`;

  if (schedule_id) {
    queryString += `&course_schedule_id=${schedule_id}`;
  }
  return queryString;
};

export const DELETE_ASSETS_URL_FOR_SCHEDULE = () => {
  return `${courseApiUrl}/course?request_type=resource`;
};
