import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  MUIIcons,
  H4,
  H2,
  DefaultButton,
  SecondaryButton,
} from "../../../../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  ALL_USERS_STATUS,
  EXPERTISE_LEVEL_STATUS,
} from "../../../../../../Api/constants";
import Divider from "../../../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../../CommonElements/MultiSelectDropdown";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import Comments from "../../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  getCourseList,
  updateCommentStatus,
  updateCourseContent,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getDomainList,
  getRolesList,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddCourseStructure,
  AssessmentCriteria,
  AssessmentCriteriaToolTipMessage,
  Comment,
  CourseCreationOtherIntendedAudienceToolTipMessage,
  CourseCreationOtherPreCourseToolTipMessage,
  CourseCreationPreCourseToolTipMessage,
  CourseType,
  Domain,
  Enter,
  EnterComments,
  IntendedAudience,
  IntendedAudienceToolTipMessage,
  Literature,
  MaximumAttendance,
  MaximumAttendanceRequired,
  MaximumAttendanceToolTipMessage,
  MinimumAttendance,
  MinimumAttendanceRequired,
  MinimumAttendanceToolTipMessage,
  OtherIntendedAudience,
  OtherIntendedAudienceRequired,
  OtherPreCourseWork,
  OtherPreCourseWorkRequired,
  PleaseEnterPositiveError,
  PleaseProvideRequiredInformation,
  PracticalActivityDetails,
  PracticalActivityDetailsToolTipMessage,
  PracticalRatio,
  PracticalRatioToolTipMessage,
  PreCourseWork,
  Qualification,
  QualificationToolTipMessage,
  RegistrationRequirement,
  RegistrationRequirementToolTipMessage,
  Save,
  SaveAsDraft,
  Select,
  SelectIntendedAudience,
  SuggestionToolTipMessage,
  SumOfTheoryAndPracticalError,
  TheoryRatio,
  TheoryRatioToolTipMessage,
} from "../../../../../../utils/Constant";
import {
  courseFilterDropdownList,
  handleKeyDownOnNumericWithoutDecimal,
  scrollToTop,
  showToast,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";

const CreateAdditionalInformation = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId } = location.state || "";
  const [sliderModal, setSliderModal] = useState(false);
  const [iconComments, setIconComments] = useState(null);
  const [userMap, setUserMap] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [preSelectedIntendedAudience, setPreSelectedIntendedAudience] =
    useState([]);
  const [preSelectedCourseWork, setPreSelectedCourseWork] = useState([]);
  const [intendedAudiences, setIntendedAudiences] = useState([]);
  const [intendedAudiencesMap, setIntendedAudiencesMap] = useState({});
  const { usersList } = useAppSelector(state => state.user);
  const { courseContent, courseList } = useAppSelector(state => state.course);
  const { roleList } = useAppSelector(state => state.master);
  const [isComentRead, setIsComentRead] = useState(null);
  const [showOtherIntendedAudience, setShowOtherIntendedAudience] =
    useState(false);
  const [showOtherPreCourseWork, setShowOtherPreCourseWork] = useState(false);
  const [courseListData, setCourseListData] = useState([]);
  const [domainListData, setDomainListData] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  const { domainList } = useAppSelector(state => state.master);
  const [formValues, setFormValues] = useState({
    intended_audience: [],
    other_intended_audiences: "",
    min_attendance: 0,
    max_attendance: 0,
    suggested_reading: "",
    qualification_certification: "",
    assessment_criteria: "",
    theory_time_ratio: 0,
    practical_time_ratio: 0,
    practical_activity_details: "",
    tooling_aids_required: "",
    other_pre_course_works: "",
    pre_course_work: [],
    registration_requirement: "",
  });

  const [errors, setErrors] = useState({
    intended_audience: "",
    other_intended_audiences: "",
    min_attendance: "",
    max_attendance: "",
    suggested_reading: "",
    qualification_certification: "",
    assessment_criteria: "",
    theory_time_ratio: "",
    practical_time_ratio: "",
    practical_activity_details: "",
    tooling_aids_required: "",
    other_pre_course_works: "",
    pre_course_work: "",
    registration_requirement: "",
  });

  useEffect(() => {
    getAllData();
  }, [dispatch, courseId]);

  const getAllData = async () => {
    dispatch(getDomainList());
    await dispatch(getRolesList({ getMappedRole: null }));
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    await dispatch(getCourseContent({ id: courseId }));
  };

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const publishedCourse = [];
    if (courseList) {
      courseList?.course_list?.forEach(item => {
        publishedCourse.push({
          name: item?.id?.toString(),
          label: item?.course_name,
          value: item?.course_name,
        });
      });
    }
    setCourseListData(publishedCourse);
  }, [courseList]);

  useEffect(() => {
    const audiences = [];
    const audiencesMap = {};
    roleList?.job_role?.forEach(audience => {
      const audienceObj = {
        name: audience.id.toString(),
        value: audience.name,
        label: audience.name,
      };
      audiencesMap[audience.id] = audienceObj;
      audiences.push(audienceObj);
      setIntendedAudiencesMap(audiencesMap);
      setIntendedAudiences(audiences);
    });
  }, [roleList]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.additional_information) {
      comment = sortCommentsByDate(
        courseContent.comment.additional_information
      );
    }
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) =>
            stage.stages === "additional_information"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
    if (courseContent) {
      const information = courseContent?.additional_information;

      if (intendedAudiencesMap) {
        const perselectedAudienceMap =
          information?.intended_audience?.map((element: any) => ({
            name: element.id?.toString(),
            value: intendedAudiencesMap[element.id]?.label || "",
            label: intendedAudiencesMap[element.id]?.label || "",
          })) || [];

        setPreSelectedIntendedAudience(perselectedAudienceMap);
      }

      const perselectedCourseMap =
        information?.pre_course_work?.map((element: any) => ({
          name: element.id?.toString(),
          value: element?.name || "",
          label: element?.name || "",
        })) || [];

      setPreSelectedCourseWork(perselectedCourseMap);
      setShowOtherIntendedAudience(
        information?.other_intended_audiences?.length > 0
      );
      setShowOtherPreCourseWork(
        information?.other_pre_course_works?.length > 0
      );

      setFormValues({
        pre_course_work: information?.pre_course_work,
        intended_audience: information.intended_audience,
        other_intended_audiences: information.other_intended_audiences || "",
        min_attendance: information.min_attendance,
        max_attendance: information.max_attendance,
        suggested_reading: information.suggested_reading,
        qualification_certification: information.qualification_certification,
        assessment_criteria: information.assessment_criteria,
        theory_time_ratio: information.theory_time_ratio || 0,
        practical_time_ratio: information.practical_time_ratio || 0,
        practical_activity_details: information.practical_activity_details,
        tooling_aids_required: information.tooling_aids_required,
        registration_requirement: information.registration_requirement,
        other_pre_course_works: information?.other_pre_course_works || "",
      });
    }
    setCommentList(comment);
  }, [courseContent]);

  const getMultiDropdownData = (value: any[]) => {
    const perselectedAudienceMap = intendedAudiences
      .filter(element => value.includes(element.name))
      .map(({ name, label }) => ({
        name: name?.toString(),
        value: label,
        label: label,
      }));

    const audienceFormData = perselectedAudienceMap.map(({ name, value }) => ({
      id: name,
      name: value,
    }));

    setPreSelectedIntendedAudience(perselectedAudienceMap);

    setFormValues(prevValues => ({
      ...prevValues,
      intended_audience: audienceFormData,
    }));

    if (value.length) {
      setErrors(prevErrors => ({ ...prevErrors, intended_audience: "" }));
    }
  };
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const numberValue = parseInt(value, 10);

    const validateNumber = (
      value: number,
      min: number,
      max: number,
      errorMessage: string
    ) => {
      if (
        !/^\d+$/.test(value?.toString()) ||
        isNaN(value) ||
        value < min ||
        value > max
      ) {
        updateErrors(errorMessage, true);
        return false;
      }
      return true;
    };

    const updateErrors = (errorMessage = "", resetValue = false) => {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
      if (resetValue) {
        updateFormValues({ [name]: "" });
      }
    };

    const updateFormValues = (updatedValues: Partial<typeof formValues>) => {
      setFormValues(prevValues => ({
        ...prevValues,
        ...updatedValues,
      }));
    };

    if (name === "theory_time_ratio" || name === "practical_time_ratio") {
      if (isNaN(numberValue) || numberValue < 0 || numberValue > 99) {
        setErrors({
          ...errors,
          [name]: PleaseEnterPositiveError,
        });
        updateFormValues({
          theory_time_ratio: 0,
          practical_time_ratio: 0,
        });
        return;
      }
      const isTheory = name === "theory_time_ratio";
      const theoryTime = isTheory ? numberValue : 100 - numberValue;
      const practicalTime = 100 - theoryTime;

      if (theoryTime < 0 || practicalTime < 0) {
        updateErrors(SumOfTheoryAndPracticalError, true);
        return;
      }

      updateErrors();
      updateFormValues({
        theory_time_ratio: theoryTime,
        practical_time_ratio: practicalTime,
      });
    } else if (name === "max_attendance") {
      if (!validateNumber(numberValue, 0, 500, MaximumAttendanceRequired))
        return;

      updateErrors();
      updateFormValues({ max_attendance: numberValue });
    } else if (name === "min_attendance") {
      if (!validateNumber(numberValue, 0, 100, MinimumAttendanceRequired))
        return;

      updateErrors();
      updateFormValues({ min_attendance: numberValue });
    } else {
      updateFormValues({ [name]: value });
    }
  };

  const handleFinish = async type => {
    if (type !== "draft") {
      const requiredFieldsMessage = {
        intended_audience: IntendedAudience,
        pre_course_work: PreCourseWork,
        min_attendance: MinimumAttendance,
        max_attendance: MaximumAttendance,
        suggested_reading: Literature,
        qualification_certification: Qualification,
        assessment_criteria: AssessmentCriteria,
        theory_time_ratio: TheoryRatio,
        practical_time_ratio: PracticalRatio,
        practical_activity_details: PracticalActivityDetails,
        registration_requirement: RegistrationRequirement,
        other_intended_audiences: OtherIntendedAudience,
        other_pre_course_works: OtherPreCourseWork,
      };

      const requiredFields = [
        "min_attendance",
        "max_attendance",
        "suggested_reading",
        "qualification_certification",
        "assessment_criteria",
        "theory_time_ratio",
        "practical_time_ratio",
        "practical_activity_details",
        "registration_requirement",
      ];

      let hasErrors = false;

      const newErrors = {
        intended_audience: "",
        other_intended_audiences: "",
        min_attendance: "",
        max_attendance: "",
        suggested_reading: "",
        qualification_certification: "",
        assessment_criteria: "",
        theory_time_ratio: "",
        practical_time_ratio: "",
        practical_activity_details: "",
        tooling_aids_required: "",
        registration_requirement: "",
        other_pre_course_works: "",
        pre_course_work: "",
      };

      requiredFields.forEach(field => {
        if (
          !formValues[field] ||
          (Array.isArray(formValues[field]) && formValues[field].length === 0)
        ) {
          newErrors[field] = `${requiredFieldsMessage[field]} is required.`;
          hasErrors = true;
        }
      });
      if (
        !showOtherIntendedAudience &&
        preSelectedIntendedAudience?.length === 0
      ) {
        newErrors["intended_audience"] =
          `${requiredFieldsMessage["intended_audience"]} is required.`;
        hasErrors = true;
      }
      if (!showOtherPreCourseWork && preSelectedCourseWork?.length === 0) {
        newErrors["pre_course_work"] =
          `${requiredFieldsMessage["pre_course_work"]} is required.`;
        hasErrors = true;
      }
      if (
        showOtherIntendedAudience &&
        formValues?.other_intended_audiences?.length === 0
      ) {
        newErrors["other_intended_audiences"] =
          `${requiredFieldsMessage["other_intended_audiences"]} is required.`;
        hasErrors = true;
      }
      if (
        showOtherPreCourseWork &&
        formValues?.other_pre_course_works?.length === 0
      ) {
        newErrors["other_pre_course_works"] =
          `${requiredFieldsMessage["other_pre_course_works"]} is required.`;
        hasErrors = true;
      }

      if (hasErrors) {
        setErrors(newErrors);
        showToast(PleaseProvideRequiredInformation, "error");
        scrollToTop();
        return;
      }
    }

    const { competency_statement, ...rest } = courseContent;
    const combinedArray = [
      ...(courseContent?.competency_statement?.tool_aid?.tool || []),
      ...(courseContent?.competency_statement?.tool_aid?.training_aid || []),
    ];
    const flatCourseContent = {
      ...rest,
      ...formValues,
      prerequisite: competency_statement.prerequisite || [],
      date: competency_statement.date || "",
      version: competency_statement.version || "",
      conditions: competency_statement.conditions || "",
      min_requirements: competency_statement.min_requirements || "",
      media_demonstration: competency_statement.media_demonstration || "",
      estimated_time: competency_statement.estimated_time || "",
      classroom_lab: competency_statement.classroom_lab || "",
      resources: combinedArray || [],
      webinar_link: competency_statement?.webinar_link || "",
      reference: competency_statement.reference || [],
      resources_activity: competency_statement.resources_activity || "",
      review: competency_statement.review || "",
      assessment: competency_statement.assessment || "",
      other_prerequisites: competency_statement.other_prerequisites || "",
      other_references: competency_statement.other_references || "",
      other_tools: competency_statement?.other_tools || "",
      other_training_aids: competency_statement?.other_training_aids || "",
      class_room_ratio: competency_statement.class_room_ratio || 0,
      practical_class_ratio: competency_statement.practical_class_ratio || 0,
      additional_information_status: type,
      competency_statement_status:
        courseContent?.component_status?.competency_statement_status,
    };
    flatCourseContent.practical_activity_details =
      formValues?.practical_activity_details?.toString() || "";
    if (flatCourseContent.additional_information) {
      delete flatCourseContent.additional_information;
    }

    await dispatch(updateCourseContent({ courseContent: flatCourseContent }));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "additional_information",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "additional_information",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const getMultiDropdownDataData = (value: any[]) => {
    const perselectedCourseMap = courseListData
      .filter(element => value.includes(element.name))
      .map(({ name, label }) => ({
        name: name?.toString(),
        value: label,
        label: label,
      }));

    const courseFormData = perselectedCourseMap.map(({ name, value }) => ({
      id: name,
      name: value,
    }));

    setPreSelectedCourseWork(perselectedCourseMap);

    setFormValues(prevValues => ({
      ...prevValues,
      pre_course_work: courseFormData,
    }));

    if (value.length) {
      setErrors(prevErrors => ({ ...prevErrors, pre_course_work: "" }));
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const { course_type = ALL_COURSE_FILTER_STATUS, domain = [] } = values;
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type:
          course_type.length > 0 ? course_type : ALL_COURSE_FILTER_STATUS,
        domain: domain.length > 0 ? domain : null,
      })
    );
  };

  const handleClear = async () => {
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="mt-4 pb-4">
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{AddCourseStructure}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mt-4">
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={IntendedAudience}
                  tooltipText={IntendedAudienceToolTipMessage}
                  important={!showOtherIntendedAudience}
                />
                <MultiSelectDropdown
                  onChange={values => {
                    getMultiDropdownData(values);
                  }}
                  options={intendedAudiences}
                  placeholder={SelectIntendedAudience}
                  defaultSelected={preSelectedIntendedAudience}
                />
                {errors.intended_audience && (
                  <div className="mt-1 text-danger">
                    {errors.intended_audience}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" lg="6">
              <Row>
                <Col>
                  <FormGroup>
                    <LabelTooltip
                      label={MinimumAttendance}
                      tooltipText={MinimumAttendanceToolTipMessage}
                      important
                    />
                    <Input
                      name="min_attendance"
                      type="number"
                      inputMode="numeric"
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      placeholder={`${Enter} ${MinimumAttendance}`}
                      onChange={handleChange}
                      value={formValues.min_attendance}
                      invalid={!!errors.min_attendance}
                    />
                    <div className="invalid-feedback">
                      {errors.min_attendance}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <LabelTooltip
                      label={MaximumAttendance}
                      tooltipText={MaximumAttendanceToolTipMessage}
                      important
                    />
                    <Input
                      name="max_attendance"
                      type="number"
                      inputMode="numeric"
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      placeholder={`${Enter} ${MaximumAttendance}`}
                      onChange={handleChange}
                      value={formValues.max_attendance}
                      invalid={!!errors.max_attendance}
                    />
                    <div className="invalid-feedback">
                      {errors.max_attendance}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="mt-2">
              <div className="form-check text-dark">
                <Input
                  className="primary-checkbox"
                  id="flexCheckAddAudience"
                  type="checkbox"
                  checked={showOtherIntendedAudience}
                  onChange={() => {
                    setFormValues(prevValues => ({
                      ...prevValues,
                      other_intended_audiences: "",
                    }));
                    setShowOtherIntendedAudience(!showOtherIntendedAudience);
                  }}
                />
                <Label
                  className="text-dark"
                  htmlFor="flexCheckAddAudience"
                  check
                >
                  {OtherIntendedAudienceRequired}
                </Label>
              </div>
            </div>
            {showOtherIntendedAudience && (
              <Col sm="12" xs="12">
                <FormGroup>
                  <LabelTooltip
                    label={OtherIntendedAudience}
                    tooltipText={
                      CourseCreationOtherIntendedAudienceToolTipMessage
                    }
                    important
                  />
                  <Input
                    rows={4}
                    name="other_intended_audiences"
                    type="textarea"
                    placeholder={`${Enter} ${OtherIntendedAudience}`}
                    onChange={handleChange}
                    maxLength={255}
                    value={formValues.other_intended_audiences}
                    invalid={!!errors.other_intended_audiences}
                  />
                  <div className="invalid-feedback">
                    {errors.other_intended_audiences}
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Col className="mt-3">
            <FormGroup>
              <LabelTooltip
                label={Literature}
                tooltipText={SuggestionToolTipMessage}
                important
              />
              <Input
                rows={4}
                name="suggested_reading"
                type="textarea"
                placeholder={`${Enter} ${Literature}`}
                maxLength={255}
                onChange={handleChange}
                value={formValues.suggested_reading}
                invalid={!!errors.suggested_reading}
              />
              <div className="invalid-feedback">{errors.suggested_reading}</div>
            </FormGroup>
          </Col>
          <Col className="mt-3">
            <FormGroup>
              <LabelTooltip
                label={Qualification}
                tooltipText={QualificationToolTipMessage}
                important
              />
              <Input
                rows={4}
                name="qualification_certification"
                type="textarea"
                placeholder={`${Enter} ${Qualification}`}
                onChange={handleChange}
                maxLength={255}
                value={formValues.qualification_certification}
                invalid={!!errors.qualification_certification}
              />
              <div className="invalid-feedback">
                {errors.qualification_certification}
              </div>
            </FormGroup>
          </Col>
          <Col className="mt-3">
            <FormGroup>
              <LabelTooltip
                label={AssessmentCriteria}
                tooltipText={AssessmentCriteriaToolTipMessage}
                important
              />
              <Input
                rows={4}
                name="assessment_criteria"
                type="textarea"
                placeholder={`${Enter} ${AssessmentCriteria}`}
                onChange={handleChange}
                maxLength={255}
                value={formValues.assessment_criteria}
                invalid={!!errors.assessment_criteria}
              />
              <div className="invalid-feedback">
                {errors.assessment_criteria}
              </div>
            </FormGroup>
          </Col>
          <Row className="mt-4">
            <Col sm="12" lg="6">
              <Row>
                <Col>
                  <FormGroup>
                    <LabelTooltip
                      label={TheoryRatio}
                      tooltipText={TheoryRatioToolTipMessage}
                      important
                    />
                    <Input
                      name="theory_time_ratio"
                      type="number"
                      placeholder={`${Enter} ${TheoryRatio}`}
                      onChange={handleChange}
                      value={formValues.theory_time_ratio}
                      invalid={!!errors.theory_time_ratio}
                    />
                    <div className="invalid-feedback">
                      {errors.theory_time_ratio}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <LabelTooltip
                      label={PracticalRatio}
                      tooltipText={PracticalRatioToolTipMessage}
                      important
                    />
                    <Input
                      name="practical_time_ratio"
                      type="number"
                      placeholder={`${Enter} ${PracticalRatio}`}
                      onChange={handleChange}
                      value={formValues.practical_time_ratio}
                      invalid={!!errors.practical_time_ratio}
                    />
                    <div className="invalid-feedback">
                      {errors.practical_time_ratio}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col className="mt-3">
            <FormGroup>
              <LabelTooltip
                label={PracticalActivityDetails}
                tooltipText={PracticalActivityDetailsToolTipMessage}
                important
              />
              <Input
                rows={4}
                name="practical_activity_details"
                type="textarea"
                placeholder={`${Enter} ${PracticalActivityDetails}`}
                onChange={handleChange}
                maxLength={255}
                value={formValues.practical_activity_details}
                invalid={!!errors.practical_activity_details}
              />
              <div className="invalid-feedback">
                {errors.practical_activity_details}
              </div>
            </FormGroup>
          </Col>
          <Row className="mt-4">
            <Col sm="12" lg="5">
              <FormGroup>
                <LabelTooltip
                  label={PreCourseWork}
                  tooltipText={CourseCreationPreCourseToolTipMessage}
                  important={!showOtherPreCourseWork}
                />
                <div
                  className={
                    errors.pre_course_work ? "border border-danger" : ""
                  }
                >
                  <MultiSelectDropdown
                    onChange={values => {
                      getMultiDropdownDataData(values);
                    }}
                    options={courseListData}
                    placeholder={`${Select} ${PreCourseWork}`}
                    defaultSelected={preSelectedCourseWork}
                  />
                </div>
                <div className="mt-1 text-sm text-danger">
                  {errors.pre_course_work}
                </div>
              </FormGroup>
            </Col>
            <Col className="filter-margin">
              <FilterSliderModal
                dropdowns={[
                  {
                    label: CourseType,
                    key: "course_type",
                    tooltipText: `${Select} ${CourseType}`,
                    options: courseFilterDropdownList,
                    isMultiSelect: true,
                  },
                  {
                    label: Domain,
                    key: "domain",
                    tooltipText: `${Select} ${Domain}`,
                    options: domainListData,
                    isMultiSelect: true,
                  },
                ]}
                selectedFilterValues={selectedFilterValues}
                defaultFilterValues={defaultFilterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                setDefaultFilterValues={setDefaultFilterValues}
                onDone={handleDone}
                onClear={handleClear}
              />
            </Col>
          </Row>
          <Col className="mt-3">
            <div className="form-check text-dark">
              <Input
                className="primary-checkbox"
                id="flexCheckAddCourseWork"
                type="checkbox"
                checked={showOtherPreCourseWork}
                onChange={() => {
                  setFormValues(prevValues => ({
                    ...prevValues,
                    other_pre_course_works: "",
                  }));
                  setShowOtherPreCourseWork(!showOtherPreCourseWork);
                }}
              />
              <Label
                className="text-dark"
                htmlFor="flexCheckAddCourseWork"
                check
              >
                {OtherPreCourseWorkRequired}
              </Label>
            </div>
            {showOtherPreCourseWork && (
              <FormGroup>
                <LabelTooltip
                  label={OtherPreCourseWork}
                  tooltipText={CourseCreationOtherPreCourseToolTipMessage}
                  important
                />
                <Input
                  rows={4}
                  name="other_pre_course_works"
                  type="textarea"
                  placeholder={`${Enter} ${OtherPreCourseWork}`}
                  onChange={handleChange}
                  maxLength={255}
                  value={formValues.other_pre_course_works}
                  invalid={!!errors.other_pre_course_works}
                />
                <div className="invalid-feedback">
                  {errors.other_pre_course_works}
                </div>
              </FormGroup>
            )}
          </Col>
          <Col className="mt-3">
            <FormGroup>
              <LabelTooltip
                label={RegistrationRequirement}
                tooltipText={RegistrationRequirementToolTipMessage}
                important
              />
              <Input
                rows={4}
                name="registration_requirement"
                type="textarea"
                placeholder={`${Enter} ${RegistrationRequirement}`}
                onChange={handleChange}
                maxLength={255}
                value={formValues.registration_requirement}
                invalid={!!errors.registration_requirement}
              />
              <div className="invalid-feedback">
                {errors.registration_requirement}
              </div>
            </FormGroup>
          </Col>
        </CardBody>
      </Card>
      <FixedFooter>
        <SecondaryButton onClick={() => handleFinish("draft")}>
          {SaveAsDraft}
        </SecondaryButton>
        <Row>
          <Col xs="auto">
            <DefaultButton
              onClick={() => handleFinish("completed")}
              color="primary"
            >
              {Save}
            </DefaultButton>
          </Col>
        </Row>
      </FixedFooter>
    </div>
  );
};

export default CreateAdditionalInformation;
