import PropTypes from "prop-types";
import { useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import "./style.scss";

import { CurrentLevel, TargetLevel } from "../../utils/Constant";
import H2 from "../Headings/H2Element";
import H4 from "../Headings/H4Element";
import MUIIcons from "../MUIIcon/MUIIcons";
import Progressbar from "../Progressbar";
import TargetLevelModal, { TargetLevelModalData } from "../TargetLevelModal";
interface TargetLevelProps {
  cardData: any;
  infoData: TargetLevelModalData[];
  userId?;
  getAllLevelData?;
}

const TargetLevelCard: React.FC<TargetLevelProps> = ({
  cardData,
  infoData,
  userId,
  getAllLevelData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (!isModalOpen) {
      getAllLevelData();
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Card>
      <CardBody className="p-4">
        <Row className="align-items-center text-start gap-2 gap-lg-0">
          <Col xs="12" lg="7">
            <div className="d-flex flex-column flex-lg-row gap-lg-5 gap-2">
              <H2 className="text-black">
                {TargetLevel} : {cardData.targetLevel}
              </H2>
              <span className="custom-expertise-level" onClick={toggleModal}>
                {CurrentLevel}: {cardData.currentLevel}
                <MUIIcons
                  className="text-black pointer"
                  iconName="InfoOutlined"
                  size={24}
                />
              </span>
              <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalBody>
                  <TargetLevelModal
                    targetLevelData={infoData}
                    userId={userId}
                  />
                </ModalBody>
              </Modal>
            </div>
          </Col>
          <Col xs="12" lg="5">
            <Progressbar
              barClassName="custom-progressBar"
              value={cardData.percentageCompleted}
              className="custom-progress-bar"
            >
              <H4>{cardData.percentageCompleted}%</H4>
            </Progressbar>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

TargetLevelCard.propTypes = {
  cardData: PropTypes.exact({
    targetLevel: PropTypes.string.isRequired,
    currentLevel: PropTypes.string.isRequired,
    percentageCompleted: PropTypes.number.isRequired,
  }).isRequired,
  infoData: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      expertiseLevel: PropTypes.string.isRequired,
      totalCount: PropTypes.number.isRequired,
      completedCount: PropTypes.number.isRequired,
      percentageCompleted: PropTypes.number.isRequired,
      completedDate: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ).isRequired,
  userId: PropTypes.any,
  getAllLevelData: PropTypes.func,
};

export default TargetLevelCard;
