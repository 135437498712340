import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { MUIIcons, ToolTip } from "../../../AbstractElements";
import { STATUS_200 } from "../../../Api/constants";
import { UpsertIndustryPayload } from "../../../Api/entities/ManageMasterDataEntity";
import Btn from "../../../CommonElements/Button";
import Divider from "../../../CommonElements/Divider";
import H3 from "../../../CommonElements/Headings/H3Element";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import FlatContainer from "../../../container/FlatContainer";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getIndustryList,
  createIndustry,
  updateIndustry,
  getIndustryListById,
  deleteIndustry,
  setIndustryListByIdToIntialValue,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  Action,
  AddNew,
  Confirm,
  DeleteIndustryHeader,
  DeleteIndustryMessage,
  Description,
  Enter,
  Industries,
  Industry,
  IndustryDescriptionToolTipMessage,
  IndustryEmptyDescription,
  IndustryNameToolTipMessage,
  Name,
  NoDataText,
  NotYetAdded,
} from "../../../utils/Constant";
import { truncateText } from "../../../utils/helper/helper";

const IndustryPage = () => {
  const dispatch = useAppDispatch();
  const { industryList, industryListById, loading } = useAppSelector(
    state => state.manageMasterData
  );
  const [flatContainerData, setFlatContainerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [industryToDelete, setIndustryToDelete] = useState(null);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [tooltipIndustryNameOpen, setTooltipIndustryNameOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const [tooltipDescriptionOpen, setTooltipDescriptionOpen] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getIndustryList());
  }, [dispatch]);

  useEffect(() => {
    if (industryList?.industry.length > 0) {
      setFlatContainerData(industryList?.industry);
    }
  }, [industryList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = flatContainerData.filter(industry =>
        industry.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(flatContainerData);
    }
  }, [searchQuery, flatContainerData]);

  useEffect(() => {
    if (industryListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: industryListById.name,
        description: industryListById.description,
      }));
    }
  }, [industryListById]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSave = useCallback(
    async (name: string, description: string) => {
      const payload: UpsertIndustryPayload = { name, description };
      let res = null;

      if (industryListById) {
        payload.id = industryListById.id;
        res = await dispatch(
          updateIndustry({ updateIndustryPayload: payload })
        ).then(val => {
          return val;
        });
      } else {
        res = await dispatch(
          createIndustry({ createIndustryPayload: payload })
        ).then(val => {
          return val;
        });
      }
      if (res?.payload?.status_code === STATUS_200) {
        toggleModal();
      }
      await dispatch(getIndustryList());
    },
    [dispatch, toggleModal]
  );

  const handleEditClick = (id: number) => {
    setIsEdit(true);
    dispatch(getIndustryListById({ id }));
    toggleModal();
  };

  const handleDeleteClick = (id: number) => {
    setIndustryToDelete(id);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (industryToDelete) {
      dispatch(deleteIndustry({ id: industryToDelete })).then(() => {
        dispatch(getIndustryList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (flatContainerData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={IndustryEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
          showCard={false}
        />
      );
    }

    if (filteredData?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    return (
      <>
        <Row className="mt-3">
          <Col className="text-gray">{Name}</Col>
          <Col className="text-gray" lg="8">
            {Description}
          </Col>
          <Col lg="1" className="text-center text-gray">
            {Action}
          </Col>
        </Row>
        <Divider />
        {filteredData?.map(industry => (
          <div className="mt-3" key={industry.id}>
            <FlatContainer
              id={industry.id}
              description={[
                {
                  value: (
                    <div>
                      <span
                        id={`tooltip-industry-description-${industry.id}`}
                        className="text-truncate"
                      >
                        {industry.description
                          ? truncateText(industry.description, 80)
                          : "-"}
                      </span>
                      <ToolTip
                        placement="top"
                        target={`tooltip-industry-description-${industry.id}`}
                        isOpen={tooltipDescriptionOpen[industry.id] || false}
                        toggle={() => handleDescriptionTooltip(industry.id)}
                      >
                        {industry?.description}
                      </ToolTip>
                    </div>
                  ),
                  columnSize: 8,
                  label: "",
                },
              ]}
              title={
                <div>
                  <span
                    id={`tooltip-industry-name-${industry.id}`}
                    className="text-truncate"
                  >
                    {industry.name && truncateText(industry.name, 20)}
                  </span>
                  <ToolTip
                    placement="top"
                    target={`tooltip-industry-name-${industry.id}`}
                    isOpen={tooltipIndustryNameOpen[industry.id] || false}
                    toggle={() => handleIndustryNameTooltip(industry.id)}
                  >
                    {industry.name ? industry.name : "-"}
                  </ToolTip>
                </div>
              }
              actions={[
                {
                  iconName: "DeleteOutlineOutlined",
                  onClick: () => handleDeleteClick(industry.id),
                },
                {
                  iconName: "EditOutlined",
                  onClick: () => handleEditClick(industry.id),
                },
              ]}
            />
            <Divider />
          </div>
        ))}
      </>
    );
  };

  const handleIndustryNameTooltip = (id: number) => {
    setTooltipIndustryNameOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDescriptionTooltip = (id: number) => {
    setTooltipDescriptionOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteIndustryHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteIndustryMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Industries}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {flatContainerData?.length > 0 && (
                <Btn
                  icon={
                    <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                  }
                  color="primary"
                  onClick={() => {
                    setIsEdit(false);
                    dispatch(setIndustryListByIdToIntialValue());
                    setModalFormValues(null);
                    toggleModal();
                  }}
                >
                  {AddNew}
                </Btn>
              )}
            </div>
          </div>
          {renderContent()}
        </CardBody>
      </Card>
      <MasterDataAddNewModal
        isEdit={isEdit}
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Industry}
        placeholderText={`${Enter} ${Industry}`}
        toolTipText={IndustryNameToolTipMessage}
        toolTipDescriptionText={IndustryDescriptionToolTipMessage}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default IndustryPage;
