import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { H5, P } from "../../../../AbstractElements";
import CommonModal from "../../../../CommonElements/Modal";
import {
  Close,
  Details,
  NeedByDate,
  PartNumber,
  Quantity,
  Reason,
  UrgencyLevel,
} from "../../../../utils/Constant";
import {
  assetsReasonMap,
  capitalizeFirstLetter,
  formatCustomDate,
} from "../../../../utils/helper/helper";

const InfoModal = ({
  reasonModalOpen,
  toggleReasonModalClick,
  assetDetails,
}) => {
  return (
    <CommonModal
      sizeTitle={Details}
      isOpen={reasonModalOpen}
      toggle={toggleReasonModalClick}
      backdrop="static"
      size="lg"
      showFooter
      onPrimaryBtnClick={toggleReasonModalClick}
      primaryBtnText={Close}
    >
      {assetDetails?.status === "replaced" && (
        <>
          <Row>
            <Col>
              <div className="flex-grow-1">
                <P className="mb-2 text-gray">{PartNumber}</P>
                <H5 className="fw-bold fs-16">
                  {assetDetails?.replacement_details?.[0]?.part_no || "-"}
                </H5>
              </div>
            </Col>
            <Col>
              <div className="flex-grow-1">
                <P className="mb-2 text-gray">{Quantity}</P>
                <H5 className="fw-bold fs-16">
                  {assetDetails?.replacement_details?.[0]?.quantity || "-"}
                </H5>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="flex-grow-1">
                <P className="mb-2 text-gray">{NeedByDate}</P>
                <H5 className="fw-bold fs-16">
                  {formatCustomDate(
                    assetDetails?.replacement_details?.[0]?.need_by_date
                  ) || "-"}
                </H5>
              </div>
            </Col>
            <Col>
              <div className="flex-grow-1">
                <P className="mb-2 text-gray">{UrgencyLevel}</P>
                <H5 className="fw-bold fs-16">
                  {capitalizeFirstLetter(
                    assetDetails?.replacement_details?.[0]?.urgency_level
                  ) || "-"}
                </H5>
              </div>
            </Col>
          </Row>
        </>
      )}
      <div
        className={`flex-grow-1 ${assetDetails?.status === "replaced" ? "mt-4" : ""}`}
      >
        <P className="mb-2 text-gray">{Reason}</P>
        <H5 className="fw-bold fs-16">
          {assetsReasonMap[assetDetails?.reason] || assetDetails?.reason}
        </H5>
      </div>
    </CommonModal>
  );
};

InfoModal.propTypes = {
  reasonModalOpen: PropTypes.bool.isRequired,
  toggleReasonModalClick: PropTypes.func.isRequired,
  assetDetails: PropTypes.shape({
    status: PropTypes.string,
    replacement_details: PropTypes.arrayOf(
      PropTypes.shape({
        part_no: PropTypes.string,
        quantity: PropTypes.number,
        need_by_date: PropTypes.string,
        urgency_level: PropTypes.string,
      })
    ),
    reason: PropTypes.string,
  }).isRequired,
};

export default InfoModal;
