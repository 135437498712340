import PropTypes from "prop-types";
import DataTable from "react-data-table-component";

const TableView = ({ onRowClick, tableColumns, tableData }) => {
  return (
    <DataTable
      onRowClicked={onRowClick}
      columns={tableColumns}
      data={tableData}
      pagination
      fixedHeader
      striped
    />
  );
};

TableView.defaultProps = {
  onRowClick: () => {
    return null;
  },
  tableColumns: [],
  tableData: [],
};

TableView.propTypes = {
  onRowClick: PropTypes.func,
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
};

export default TableView;
