import React, { useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip,
} from "reactstrap";

import { Badges, H5, Image, MUIIcons } from "../../AbstractElements";
import {
  Copied,
  EmployeeId,
  External,
  Mobile,
  RM,
  SBU,
} from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import { UserProfileCardDetailsProps } from "../../utils/helper/propTypes";

const UserProfileCardDetails: React.FC<UserProfileCardDetailsProps> = ({
  userProfileUrl,
  name,
  rmName,
  phoneNumber,
  mailId,
  uniqueKey,
  empId,
  sbu,
  showDrop,
  dropVal,
  tag,
  onDropClick = () => {
    return;
  },
  badgeText,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleTooltip = (id: string) => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };
  const handleCopy = (text: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    showToast(Copied, "success");
  };

  const toggleDropdown = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  const handleDropdownClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Row className="d-flex align-items-center">
      <Col
        lg={4}
        xs={12}
        className="d-flex align-items-center justify-content-center justify-content-lg-start p-1 mb-2 mb-lg-0"
      >
        <Image
          className="img-48 rounded-circle p-0"
          src={userProfileUrl}
          alt="users"
        />
        <div className="text-start ms-2">
          <div className="d-flex gap-2">
            <H5 className="mb-1 fw-bold">{name}</H5>
            {tag && <Badges status="draft">{External}</Badges>}
            {badgeText && <Badges status="new">{badgeText}</Badges>}
          </div>

          <div className="text-muted">
            {EmployeeId}:{" "}
            {empId ? <span className="text-dark">{empId}</span> : "-"}
            {" | "}
            {Mobile}:{" "}
            {phoneNumber ? (
              <span className="text-dark">{phoneNumber}</span>
            ) : (
              "-"
            )}
          </div>
        </div>
      </Col>
      <Col
        lg={7}
        xs={12}
        className="d-flex flex-column flex-sm-row align-items-center  justify-content-center gap-1 gap-lg-3 text-gray mb-2 mb-lg-0"
      >
        {sbu && (
          <Col sm={4} className="d-flex align-items-center gap-1 mb-2 mb-sm-0">
            <MUIIcons iconName="BadgeOutlined" size={20} />
            <span>
              {SBU}: <span className="text-dark">{sbu}</span>
            </span>
          </Col>
        )}
        {rmName && (
          <Col sm={4} className="d-flex align-items-center gap-1">
            <MUIIcons iconName="PersonOutline" size={20} />
            <span>
              {RM} <span className="text-dark">{rmName}</span>
            </span>
          </Col>
        )}
      </Col>
      <Col
        lg={1}
        xs={12}
        className="d-flex justify-content-center align-items-center gap-2 text-gray"
      >
        <span
          id={`tooltipIcon-${uniqueKey}-mailTooltip`}
          className="pointer"
          onClick={event => handleCopy(mailId, event)}
        >
          <MUIIcons
            className="primary-icon-color"
            iconName="MailOutline"
            size={20}
          />
        </span>
        <Tooltip
          placement="left-end"
          isOpen={tooltipOpen === `tooltipIcon-${uniqueKey}-mailTooltip`}
          toggle={() => toggleTooltip(`tooltipIcon-${uniqueKey}-mailTooltip`)}
          target={`tooltipIcon-${uniqueKey}-mailTooltip`}
        >
          {mailId}
        </Tooltip>

        <span
          id={`tooltipIcon-${uniqueKey}-phoneTooltip`}
          className="pointer ms-2"
          onClick={event => handleCopy(phoneNumber.toString(), event)}
        >
          <MUIIcons
            className="primary-icon-color"
            iconName="PhoneOutlined"
            size={20}
          />
        </span>
        <Tooltip
          placement="left-end"
          isOpen={tooltipOpen === `tooltipIcon-${uniqueKey}-phoneTooltip`}
          toggle={() => toggleTooltip(`tooltipIcon-${uniqueKey}-phoneTooltip`)}
          target={`tooltipIcon-${uniqueKey}-phoneTooltip`}
        >
          {phoneNumber}
        </Tooltip>

        {showDrop && (
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            onClick={handleDropdownClick}
            className="p-0 m-0"
          >
            <DropdownToggle color="" className="three-dots p-0 more-options">
              <MUIIcons
                iconName="MoreVertOutlined"
                className="primary-icon-color"
                size={17}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {dropVal?.map((item, ind) => (
                <DropdownItem
                  key={ind}
                  onClick={() => onDropClick(uniqueKey, item)}
                >
                  {item}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
};

export default UserProfileCardDetails;
