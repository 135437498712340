import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { TAG_ASSESSMENT } from "../../Api/constants";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import SearchBar from "../../CommonElements/SearchBar";
import TextTooltip from "../../CommonElements/TextTooltip/inxex";
import ViewModeDropdown from "../../CommonElements/ViewMoreDropdown";
import GenericCard from "../../container/GenericCard";
import NoData from "../../container/NoData";
import TableView from "../../container/TableView";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { setLearnerAssessmentDetailsToInitial } from "../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getAssignedAssessmentForLearner } from "../../ReduxToolkit/Reducers/AssessmentSlice";
import { getTagsList } from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  Action,
  AssessmentType,
  CompletedOn,
  CoolingPeriod,
  Days,
  End,
  EndDate,
  ExamAlreadyInProgress,
  Expired,
  FinalScore,
  Mins,
  Modules,
  Name,
  NoDataText,
  Note,
  NotYetAdded,
  Overdue,
  Questions,
  Resume,
  Retake,
  Score,
  Select,
  Start,
  StartDate,
  StartNote,
  Status,
  SubmittedOn,
  Tags,
  Time,
} from "../../utils/Constant";
import {
  AssessmentTabs,
  formatCustomDate,
  calculateDayDifference,
  formatDate,
  assessmentStatus,
  examStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  assessmentTypes,
  showToast,
  assessmentParticipantStatus,
  capitalizeFirstLetter,
} from "../../utils/helper/helper";
import "./style.scss";
import useIsMobile from "../../utils/helper/responsive";

const AssessmentList = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("tab");
  const viewParam = params.get("view");
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || assessmentTabStatus.current
  );
  const { tagList } = useAppSelector(state => state.master);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const [viewMode, setViewMode] = useState("card");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const assessmentOptions = assessmentTypes.map(item => ({
    ...item,
    name: item.value,
  }));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { learnerAssessmentList } = useAppSelector(state => state.assessment);
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getTagsList({ tagType: TAG_ASSESSMENT }));
  }, []);

  useEffect(() => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: selectedFilterValues["assessment_type"],
      tag: selectedFilterValues["tags"],
    };
    getAssessmentList(params);
  }, [activeTab]);

  useEffect(() => {
    if (viewParam) {
      setViewMode(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  const getAssessmentList = params => {
    const updatedParams = {
      ...params,
      status: activeTab,
    };
    dispatch(getAssignedAssessmentForLearner(updatedParams)).then(response => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const isResumeExam = status => {
    return status !== assessmentStatus.inProgress && isResumeExamAvailable();
  };

  const handleExam = assessment => {
    if (isResumeExam(assessment?.status)) {
      showToast(ExamAlreadyInProgress, "error");
      return;
    }

    if (assessment?.assessment_type === assessmentTypesMap.competency) {
      dispatch(setLearnerAssessmentDetailsToInitial());
      return navigate("/self-assessment", {
        state: {
          participantId: assessment?.participant_id,
        },
      });
    }
    if (assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment?.name,
          termsAndCondition: assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment.name,
        },
      });
    }
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const isResumeExamAvailable = () => {
    return learnerAssessmentList?.assessments?.some(
      item => item.status === assessmentStatus.inProgress
    );
  };

  const getButtonType = assessment => {
    const { status, cooling_period_date } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);
    const isCoolingPeriod = coolingPeriod > 0;

    if (
      activeTab === assessmentTabStatus.underReview ||
      activeTab === assessmentTabStatus.past
    ) {
      return null;
    }

    if (activeTab === assessmentTabStatus.upcoming) {
      return {
        title: Start,
        onClick: () => {
          return;
        },
        disabled: true,
      };
    }

    if (activeTab === assessmentTabStatus.current) {
      return {
        title: getButtonTitle(status),
        disabled: isCoolingPeriod,
        onClick: isCoolingPeriod
          ? () => {
              return;
            }
          : () => handleExam(assessment),
      };
    }

    return null;
  };

  const footerDate = assessment => {
    if (
      assessment.status === assessmentStatus.overdue ||
      assessment.status === assessmentStatus.expired
    ) {
      return "-";
    }
    if (activeTab === assessmentTabStatus.past) {
      return `${CompletedOn} ${formatDate(assessment.end_time)}`;
    } else if (activeTab === assessmentTabStatus.underReview) {
      return `${SubmittedOn} ${formatDate(assessment.end_time)}`;
    }
    return null;
  };

  const renderAssessmentCard = assessment => {
    const {
      participant_id,
      name,
      start_date,
      end_date,
      estimated_time,
      cooling_period_date,
      module_count,
      overall_question_count,
      score,
      status,
      assessment_type,
    } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);

    let footerBadge = "";
    if (
      assessment_type !== assessmentTypesMap.competency &&
      activeTab === assessmentTabStatus.past
    ) {
      if (
        assessment.status !== assessmentStatus.overdue &&
        assessment.status !== assessmentStatus.expired
      ) {
        footerBadge = `${FinalScore} ${score}%`;
      } else {
        footerBadge = Expired;
      }
    }
    if (
      assessment_type !== assessmentTypesMap.competency &&
      activeTab === assessmentTabStatus.current &&
      (assessment.status === assessmentStatus.overdue ||
        assessment.status === assessmentStatus.expired)
    ) {
      footerBadge = Overdue;
    }

    return (
      <Col key={participant_id} sm={12} lg={4}>
        <Link
          to={
            assessment.status !== assessmentStatus.overdue &&
            assessment.status !== assessmentStatus.expired
              ? "/assessment-list/assessment-details"
              : "#"
          }
          state={{
            assessment,
            activeTab: activeTab,
            isResume: isResumeExam(assessment.status),
          }}
        >
          <GenericCard
            id={participant_id}
            header={name}
            moduleDetails={[
              {
                label: Start,
                value: start_date ? formatCustomDate(start_date) : "-",
                key: `${participant_id}_${Start}`,
              },
              {
                label: End,
                value: end_date ? formatCustomDate(end_date) : "-",
                key: `${participant_id}_${End}`,
              },
              {
                label: Time,
                value: estimated_time ? `${estimated_time} ${Mins}` : "-",
                key: `${participant_id}_${Time}`,
              },
              {
                label: Questions,
                value: overall_question_count ?? "-",
                key: `${participant_id}_${Questions}`,
              },
              {
                label: Modules,
                value: module_count ?? "-",
                key: `${participant_id}_${Modules}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadgeRight={
              coolingPeriod && activeTab !== assessmentTabStatus.past
                ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
                : ""
            }
            footerBadge={footerBadge}
            footerBadgeStatus={
              activeTab === assessmentTabStatus.past &&
              status === examStatus.passed
                ? "completed"
                : "rejected"
            }
            btnType={getButtonType(assessment)}
            altText={
              activeTab === assessmentTabStatus.upcoming &&
              `${Note}: ${StartNote}`
            }
            footerDate={footerDate(assessment)}
          />
        </Link>
      </Col>
    );
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    params.set("tab", tabId);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleSearch = (query: string) => {
    const filteredAssessment = learnerAssessmentList?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: values["assessment_type"],
      tag: tagIds?.toString() || null,
    };
    getAssessmentList(params);
  };

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const handleSetView = type => {
    setViewMode(type);
    params.set("view", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleClear = () => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: [],
      tag: "",
    };
    getAssessmentList(params);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const renderContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (learnerAssessmentList?.assessments?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    const cardData =
      searchResults?.length > 0
        ? searchResults
        : learnerAssessmentList?.assessments;

    return viewMode === "card"
      ? cardData?.map(assessment => renderAssessmentCard(assessment))
      : renderRequestTable();
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const renderRequestTable = () => {
    const tableColumns = [
      {
        name: Name,
        selector: row => capitalizeFirstLetter(row.name),
        cell: (row: any) =>
          createTooltipCell(row.name, `assessment-name-${row.participant_id}`),
        sortable: true,
        width: "14rem",
      },
      {
        name: StartDate,
        selector: row => formatCustomDate(row.start_date),
        sortable: true,
        width: "10rem",
      },
      {
        name: EndDate,
        selector: row => formatCustomDate(row.end_date),
        sortable: true,
        width: "10rem",
      },
      {
        name: Time,
        selector: row =>
          `${row.estimated_time ? `${row.estimated_time}  ${Mins}` : "-"}`,
        sortable: true,
      },
      {
        name: Questions,
        selector: row => row.overall_question_count || "-",
        sortable: true,
        width: "10rem",
      },
      {
        name: Modules,
        selector: row => row.module_count || "-",
        sortable: true,
        width: "10rem",
      },
      {
        name: CoolingPeriod,
        selector: row => {
          const coolingPeriod = calculateDayDifference(row.cooling_period_date);
          return coolingPeriod && activeTab !== assessmentTabStatus.past
            ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
            : "-";
        },
        sortable: true,
        width: "10rem",
      },
    ];

    if (activeTab === assessmentTabStatus.past) {
      tableColumns.push(
        {
          name: CompletedOn,
          selector: row => formatDate(row.end_time) || "-",
          sortable: true,
          width: "10rem",
        },
        {
          name: Score,
          selector: row => `${row.score}%` || "-",
          sortable: true,
        },
        {
          name: Status,
          selector: row => assessmentParticipantStatus[row.status] || "-",
          sortable: true,
        }
      );
    }

    if (activeTab === assessmentTabStatus.underReview) {
      tableColumns.push({
        name: SubmittedOn,
        selector: row => formatDate(row.end_time) || "-",
        sortable: true,
        width: "10rem",
      });
    }

    tableColumns.push({
      name: Action,
      selector: (row: any) => {
        const btnType = getButtonType(row);
        if (!btnType?.title) return "-";
        return (
          <div className="d-flex gap-2">
            <button
              className={`edit-details-button border-0 bg-transparent ${btnType.disabled ? "disabled" : ""}`}
              disabled={btnType.disabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (!btnType.disabled && btnType.onClick) {
                  btnType.onClick();
                }
              }}
            >
              {btnType.title}
            </button>
          </div>
        );
      },
      sortable: false,
      center: true,
    } as any);

    const tableData =
      searchResults?.length > 0
        ? searchResults
        : learnerAssessmentList?.assessments;

    return (
      <Col>
        <Card className="p-1">
          <CardBody>
            <TableView
              tableColumns={tableColumns}
              tableData={tableData}
              onRowClick={row => {
                if (
                  row?.status !== assessmentStatus.overdue &&
                  row?.status !== assessmentStatus.expired
                )
                  navigate("/assessment-list/assessment-details", {
                    state: {
                      assessment: row,
                      activeTab: activeTab,
                      isResume: isResumeExam(row.status),
                    },
                  });
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <div className="page-body assessment-list page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={AssessmentTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: assessmentOptions,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              <ViewModeDropdown
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onChange={handleSetView}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderContent()}</Row>
    </div>
  );
};

export default AssessmentList;
