import { Divider } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { Btn, H4 } from "../../../AbstractElements";
import FixedFooter from "../../../CommonElements/FixedFooter";
import {
  Back,
  InstructionHeadingForLearnerTakingAssessment,
  Start,
} from "../../../utils/Constant";
import {
  InstructionDataForLearnerForTakingAssessment,
  sanitizedContent,
  scrollToTop,
} from "../../../utils/helper/helper";
import Header from "../Header";

import "./style.scss";
const AssessmentInstruction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  let sections = [];
  let clickInfo = "";
  sections = InstructionDataForLearnerForTakingAssessment.sections;
  clickInfo = InstructionDataForLearnerForTakingAssessment.clickInfo;

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleStart = () => {
    return navigate("/learning-assessment", {
      state: {
        participantId: state?.participantId,
        name: state.name,
        scheduleCourse: state?.scheduleCourse,
      },
    });
  };
  return (
    <>
      <Header name={state?.name} />
      <Row className="instruction-assessment">
        <Card>
          <CardBody>
            <H4 className="p-2 mb-2">
              {InstructionHeadingForLearnerTakingAssessment}
            </H4>
            <Divider />
            {sections.map(section => (
              <div key={section.id} className="mt-3 p-1">
                <h5 className="fw-bold">{section.heading}</h5>
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(section.content),
                  }}
                />
              </div>
            ))}
            <H4 className="p-2">{"Terms and Conditions : "}</H4>
            <Divider />
            <div
              className="p-2 sanitized-content"
              dangerouslySetInnerHTML={{
                __html: sanitizedContent(state?.termsAndCondition),
              }}
            />
            <div
              className="sanitized-content"
              dangerouslySetInnerHTML={{
                __html: sanitizedContent(clickInfo),
              }}
            />
          </CardBody>
        </Card>
      </Row>

      <FixedFooter>
        <Btn
          onClick={handleBack}
          outline
          className="alert-light-primary"
          color="primary"
        >
          {Back}
        </Btn>
        <Btn onClick={handleStart} color="primary">
          {Start}
        </Btn>
      </FixedFooter>
    </>
  );
};

export default AssessmentInstruction;
