import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import { DRAFT_STATUS, curriculumTabsStatusMap } from "../../../Api/constants";
import { CurriculumListItem } from "../../../Api/entities/CurriculumEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import TextTooltip from "../../../CommonElements/TextTooltip/inxex";
import ViewModeDropdown from "../../../CommonElements/ViewMoreDropdown";
import AssessmentCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import TableView from "../../../container/TableView";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  getDomainList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Course,
  Courses,
  CreatedOn,
  Domain,
  ILT,
  IsSpecial,
  IsSpecialCurriculum,
  JobRole,
  Level,
  Levels,
  Name,
  No,
  NoDataText,
  NotYetAdded,
  Role,
  Select,
  Special,
  VILT,
  WBT,
  Yes,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  curriculumTabs,
  mapListToOptions,
  convertMinutesToHours,
  capitalizeFirstLetter,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import "./style.scss";
import useIsMobile from "../../../utils/helper/responsive";

const CurriculumList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const viewParam = params.get("view");
  const [activeTab, setActiveTab] = useState<string>(statusParam || "draft");
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { domainList, roleList } = useAppSelector(state => state.master);

  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [domainListData, setDomainListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const [viewMode, setViewMode] = useState("card");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getRolesList({}));
  }, []);

  useEffect(() => {
    if (viewParam) {
      setViewMode(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    fetchCurriculum(curriculumTabsStatusMap[activeTab], selectedFilterValues);
  }, [activeTab]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  const fetchCurriculum = (tabStatus, filteredStatus) => {
    dispatch(
      getCurriculumList({
        status: tabStatus,
        curriculum_type: filteredStatus,
      })
    ).then(response => {
      if (response.payload.curriculum?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const handleSetView = type => {
    setViewMode(type);
    params.set("view", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/master-data/curriculum?status=${tabId}`);
  };

  const handleSearch = (query: string) => {
    const filteredCurriculum = curriculumList?.curriculum?.filter(curriculum =>
      curriculum.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCurriculum || []);
    setNoDataFound(!filteredCurriculum || filteredCurriculum?.length === 0);
  };

  const getRoleNameById = id => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "-";
  };

  const renderCurriculumCard = (curriculum: CurriculumListItem, index) => {
    const domainValue = curriculumList.domain[curriculum.id];
    const durationValue = curriculumList.duration[curriculum.id];
    const combinedIltViltDuration =
      durationValue?.total_ilt_duration + durationValue?.total_vilt_duration;

    const roleName = getRoleNameById(curriculum.role_id);
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={"/master-data/add-new-curriculum"}
          state={{ curriculumRequestId: curriculum.id }}
        >
          <AssessmentCard
            id={index}
            header={curriculum?.name}
            footerDate={formatCustomDate(curriculum?.created_at)}
            moduleDetails={[
              {
                label: Role,
                value: roleName,
                key: `${curriculum.id}-${Role}`,
              },
              {
                label: Level,
                value: curriculum.total_levels.toString(),
                key: `${curriculum.id}-${Level}`,
              },
              {
                label: Course,
                value: curriculum.total_courses.toString(),
                key: `${curriculum.id}-${Course}`,
              },
              {
                label: Domain,
                value: domainValue?.map(item => item.name)?.join(", ") || "-",
                key: `${curriculum.id}-${Domain}`,
              },
              {
                label: `${ILT}/${VILT}`,
                value: `${convertMinutesToHours(combinedIltViltDuration)} h`,
                key: `${curriculum.id}-${VILT}`,
              },
              {
                label: WBT,
                value: `${convertMinutesToHours(durationValue?.total_wbt_duration)} h`,
                key: `${curriculum.id}-${WBT}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadge={curriculum.is_special_curriculum ? Special : ""}
            status={curriculum.status === DRAFT_STATUS ? "" : curriculum.status}
          />
        </Link>
      </Col>
    );
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const handleRowClick = curriculum => {
    const path = "/master-data/add-new-curriculum";
    navigate(path, {
      state: { curriculumRequestId: curriculum.id },
    });
  };

  const renderRequestTable = () => {
    const tableColumns = [
      {
        name: Name,
        selector: row => capitalizeFirstLetter(row.name),
        cell: (row: any) =>
          createTooltipCell(row.name, `curriculum-name-${row.id}`),
        sortable: true,
      },
      {
        name: Role,
        selector: row => getRoleNameById(row.role_id),
        sortable: true,
      },
      {
        name: Levels,
        selector: row => row.total_levels.toString(),
        sortable: true,
      },
      {
        name: Courses,
        selector: row => row.total_courses.toString(),
        sortable: true,
      },
      {
        name: Domain,
        selector: row => {
          const domainValue = curriculumList.domain[row.id];
          return createTooltipCell(
            domainValue?.map(item => item.name)?.join(", ") || "-",
            `domain-${row.id}`
          );
        },
        sortable: true,
      },
      {
        name: `${ILT}/${VILT}`,
        selector: row => {
          const durationValue = curriculumList.duration[row.id];
          const combinedIltViltDuration =
            durationValue?.total_ilt_duration +
            durationValue?.total_vilt_duration;
          return `${convertMinutesToHours(combinedIltViltDuration)} h`;
        },
        sortable: true,
      },
      {
        name: WBT,
        selector: row => {
          const durationValue = curriculumList.duration[row.id];
          return `${convertMinutesToHours(durationValue?.total_wbt_duration)} h`;
        },
        sortable: true,
      },
      {
        name: CreatedOn,
        selector: row => formatCustomDate(row.created_at),
        sortable: true,
      },
      {
        name: IsSpecial,
        selector: row => {
          return row.is_special_curriculum ? Yes : No;
        },
        sortable: true,
        center: true,
      },
    ];

    const tableData =
      searchResults?.length > 0 ? searchResults : curriculumList.curriculum;

    return (
      <Col>
        <Card className="p-1">
          <CardBody>
            <TableView
              tableColumns={tableColumns}
              tableData={tableData}
              onRowClick={row => handleRowClick(row)}
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    fetchCurriculum(curriculumTabsStatusMap[activeTab], {
      ...values,
      specialCurriculum: values?.specialCurriculum ? true : false,
    });
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    fetchCurriculum(curriculumTabsStatusMap[activeTab], {});
  };

  const renderCurriculumContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (curriculumList.curriculum?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    const cardData =
      searchResults?.length > 0 ? searchResults : curriculumList?.curriculum;

    return viewMode === "card"
      ? cardData?.map((assessment, index) =>
          renderCurriculumCard(assessment, index)
        )
      : renderRequestTable();
  };

  return (
    <div className="page-body page-body-margin curriculum-list">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={curriculumTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                    {
                      label: IsSpecialCurriculum,
                      key: "specialCurriculum",
                      tooltipText: `${Select} ${IsSpecialCurriculum}`,
                      isCheckbox: true,
                      isMultiSelect: false,
                    },
                    {
                      label: JobRole,
                      key: "role",
                      tooltipText: `${Select} ${JobRole}`,
                      options: roleListData,
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              <ViewModeDropdown
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onChange={handleSetView}
              />
              {hasPermissionToComponent("CREATE_CURRICULUM") && (
                <Link to={"/master-data/add-new-curriculum"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderCurriculumContent()}</Row>
    </div>
  );
};

export default CurriculumList;
