import { useEffect, useState, useCallback } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { STATUS_200, ALL_USERS_STATUS } from "../../../Api/constants";
import { UpsertCompetencyPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createCompetency,
  deleteCompetency,
  getCompetencyList,
  getManageMasterCompetencyListById,
  setCompetencyListByIdToInitialValue,
  updateCompetency,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getDomainList,
  getProficiencyList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddNew,
  Competency,
  DeleteCompetencyHeader,
  DeleteCompetencyMessage,
  Competencies,
  Enter,
  Confirm,
  LO,
  Levels,
  Domain,
  NoDataText,
  NotYetAdded,
  CompetencyEmptyDescription,
  CompetencyNameToolTipMessage,
  CompetencyDescriptionToolTipMessage,
  DomainNameToolTipMessage,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const CompetencyPage = () => {
  const dispatch = useAppDispatch();
  const { competencyList, competencyListById, loading } = useAppSelector(
    state => state.manageMasterData
  );
  const [isEdit, setIsEdit] = useState(false);
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const { usersList } = useAppSelector(state => state.user);
  const { domainList, proficiencyList } = useAppSelector(state => state.master);
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [domainListData, setDomainListData] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [competencyToDelete, setCompetencyToDelete] = useState(null);
  const [selectedDomainId, setSelectedDomainId] = useState("");
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [userMap, setUserMap] = useState(null);
  const [levelMap, setLevelMap] = useState(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getCompetencyList());
    dispatch(getProficiencyList());
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    if (!modalOpen) {
      dispatch(getDomainList());
    }
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const levelMapObj: Record<number, string> = {};
    proficiencyList?.proficiency?.forEach(proficiency => {
      levelMapObj[proficiency.id] = proficiency.level_name;
    });
    setLevelMap(levelMapObj);
  }, [proficiencyList]);

  useEffect(() => {
    if (competencyList?.competency?.length > 0) {
      setAccordionData(
        competencyList?.competency?.map(comp => {
          const levelNames = comp.level_ids
            ?.map(id => levelMap?.[id])
            .filter(Boolean)
            .join(", ");
          return {
            id: comp.id.toString(),
            name: comp.competency_name,
            description: comp.description,
            createdOn: comp.created_at,
            modifiedOn: comp.updated_at,
            modifiedBy: userMap ? userMap[comp.updated_by]?.user_name : "-",
            createdBy: userMap ? userMap[comp.created_by]?.user_name : "-",
            depedentBadges: [
              {
                id: 1,
                badge: ` ${comp.level_count || "-"} ${Levels}`,
                toolTip: true,
                toolTipValues: levelNames,
                iconName: "SignalCellularAltOutlined",
              },
              {
                id: 2,
                badge: `${LO}: ${comp.lo_count}`,
                toolTip: true,
                iconName: "LocalLibraryOutlined",
                className: "d-none",
              },
            ],
          };
        })
      );
    }
  }, [competencyList, levelMap, userMap]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData.filter(comp =>
        comp.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    const domains = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    if (competencyListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: competencyListById.competency_name,
        description: competencyListById.description,
        selectedOption: competencyListById.domain_id.toString(),
      }));
    }
  }, [competencyListById]);

  const handleSave = useCallback(
    async (name: string, description: string, domainId: string) => {
      const payload: UpsertCompetencyPayload = {
        name,
        description,
        domain_id: parseInt(domainId),
      };

      let res = null;
      if (competencyListById) {
        payload.id = competencyListById.id;
        res = await dispatch(
          updateCompetency({ updateCompetencyPayload: payload })
        ).then(val => {
          return val;
        });
      } else {
        res = await dispatch(
          createCompetency({ createCompetencyPayload: payload })
        ).then(val => {
          return val;
        });
      }
      if (res?.payload?.status_code === STATUS_200) {
        toggleModal();
      }
      await dispatch(getCompetencyList());
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    setIsEdit(true);
    const selectedId = parseInt(id);
    dispatch(getManageMasterCompetencyListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setCompetencyToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (competencyToDelete) {
      dispatch(deleteCompetency({ id: competencyToDelete })).then(() => {
        dispatch(getCompetencyList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={CompetencyEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteCompetencyHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteCompetencyMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Competencies}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {accordionData?.length > 0 && (
                <Btn
                  icon={
                    <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                  }
                  color="primary"
                  onClick={() => {
                    setIsEdit(false);
                    setSelectedDomainId("");
                    dispatch(setCompetencyListByIdToInitialValue());
                    setModalFormValues(null);
                    toggleModal();
                  }}
                >
                  {AddNew}
                </Btn>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}
      <MasterDataAddNewModal
        isEdit={isEdit}
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Competency}
        placeholderText={`${Enter} ${Competency}`}
        toolTipText={CompetencyNameToolTipMessage}
        toolTipDescriptionText={CompetencyDescriptionToolTipMessage}
        initialValues={modalFormValues}
        dropdownLabel={Domain}
        dropdownLabelTooltip={DomainNameToolTipMessage}
        dropdownOptions={domainListData}
        selectedDropdownValue={selectedDomainId}
        onDropdownChange={value => setSelectedDomainId(value)}
      />
    </div>
  );
};

export default CompetencyPage;
