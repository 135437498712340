import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H4 } from "../../../../AbstractElements";
import { STATUS_200 } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import ReasonAssetModal from "../../../../CommonElements/ReasonAssetModal";
import SearchDropDownPaginator from "../../../../CommonElements/SearchDropDownPaginator";
import CheckboxButton from "../../../../CommonElements/SwitchButton";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  clearAssetDetails,
  createAssetData,
  getAssetsDetailsById,
  getAssetTypes,
  updateAssetData,
} from "../../../../ReduxToolkit/Reducers/AssetManagementSlice";
import {
  getManageMasterLocationList,
  getManageMasterSubLocationListByLocation,
} from "../../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import { uploadFileApi } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Add,
  Asset,
  AssetCategory,
  AssetIdRequired,
  AssetIDToolTipMessage,
  AssetLocationRequired,
  AssetName,
  AssetNameRequired,
  AssetsID,
  AssetsStatus,
  AssetsStatusRequired,
  AssetsStatusToolTipMessage,
  AssetsType,
  AssetSubLocationRequired,
  AssetTypeRequired,
  AssetTypesRequired,
  AssetTypeToolTipMessage,
  CalibrationEveryRequired,
  CalibrationFrequencyRequired,
  CalibrationRequired,
  CalibrationRequiredToolTipMessage,
  CapitalOrConsumable,
  DateReceipt,
  DateReceiptRequired,
  DateReceiptToolTipMessage,
  Dates,
  Edit,
  Enter,
  Every,
  EveryCalibrationToolTipMessage,
  EveryMaintenanceToolTipMessage,
  Frequency,
  FrequencyCalibrationToolTipMessage,
  FrequencyMaintenanceToolTipMessage,
  InvoiceDate,
  InvoiceDateRequired,
  InvoiceNumber,
  InvoiceNumberRequired,
  InvoiceValue,
  InvoiceValueRequired,
  Location,
  MaintenanceEveryRequired,
  MaintenanceFrequencyRequired,
  MaintenanceRequired,
  MaintenanceRequiredToolTipMessage,
  NeedAttention,
  NewAssets,
  PartNumber,
  PartNumberRequired,
  PleaseProvideRequiredInformation,
  Select,
  SubLocation,
  Type,
  Update,
  UploadDocumentInvoice,
} from "../../../../utils/Constant";
import {
  AssetsCategoryOptions,
  AssetsManagementStatus,
  customStyles,
  formatDate,
  frequencyRangeOptions,
  handleKeyDownOnNumericWithoutDecimal,
  mapListToOptions,
  scrollToTop,
  showToast,
} from "../../../../utils/helper/helper";

const initialFormValues: any = {
  asset_type: null,
  asset_name: "",
  part_no: "",
  resource_type_id: null,
  asset_id: "",
  invoice_number: "",
  invoice_date: null,
  invoice_value: "",
  receipt_date: null,
  calibrationRequired: false,
  calibration_frequency: null,
  calibration_value: "",
  maintenanceRequired: false,
  maintenance_frequency: null,
  maintenance_value: "",
  location_id: null,
  sub_location_id: null,
  status: "",
  reason: "",
  replacement_part_no: "",
  quantity: null,
  need_by_date: null,
  urgency_level: "",
};

const errorValues = {
  asset_type: "",
  asset_name: "",
  part_no: "",
  resource_type_id: "",
  asset_id: "",
  invoice_number: "",
  invoice_date: "",
  invoice_value: "",
  receipt_date: "",
  calibrationRequired: "",
  calibration_frequency: "",
  calibration_value: "",
  maintenanceRequired: "",
  maintenance_frequency: "",
  maintenance_value: "",
  location_id: "",
  sub_location_id: "",
  status: "",
  reason: "",
};

const UpsertAssetManagementForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(errorValues);
  const { locationList, subLocationListBylocation } = useAppSelector(
    state => state.manageMasterData
  );
  const { assetTypes, assetDetails } = useAppSelector(state => state.assets);
  const [modalOpen, setModalOpen] = useState(false);
  const [formState, setFormState] = useState(initialFormValues);
  const [document, setDocument] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const assetId = queryParams.get("asset_id");
  const editData = queryParams.get("edit");
  const preselectedType = queryParams.get("type");
  const [assetStatusDropdown, setAssetStatusDropdown] = useState(
    AssetsManagementStatus
  );

  const locationListData = useMemo(() => {
    return mapListToOptions(locationList?.location, "name", "id");
  }, [locationList]);

  const subLocationListData = useMemo(() => {
    return mapListToOptions(
      subLocationListBylocation?.sub_location,
      "name",
      "id"
    );
  }, [subLocationListBylocation]);

  const assetTypesData = useMemo(() => {
    return mapListToOptions(
      assetTypes?.resource_type,
      "name",
      "resource_type_id"
    );
  }, [assetTypes]);

  useEffect(() => {
    dispatch(getManageMasterLocationList());
    dispatch(getAssetTypes());
  }, [dispatch]);

  useEffect(() => {
    setDocument([]);
    dispatch(clearAssetDetails());
    if (assetId) {
      dispatch(getAssetsDetailsById({ id: assetId }));
    }
  }, [assetId, dispatch]);

  useEffect(() => {
    if (assetDetails?.location_id && assetDetails?.location_id?.toString()) {
      getAllSubLocations(assetDetails?.location_id?.toString());
    }
  }, [assetDetails?.location_id]);

  const getAllSubLocations = async location_id => {
    await dispatch(
      getManageMasterSubLocationListByLocation({
        location_id: location_id,
      })
    );
  };

  useEffect(() => {
    const updated = {
      asset_type: null,
      asset_name: "",
      part_no: "",
      resource_type_id: null,
      asset_id: "",
      invoice_number: "",
      invoice_date: null,
      invoice_value: "",
      receipt_date: null,
      calibrationRequired: false,
      calibration_frequency: null,
      calibration_value: "",
      maintenanceRequired: false,
      maintenance_frequency: null,
      maintenance_value: "",
      location_id: null,
      sub_location_id: null,
      status: null,
      reason: "",
      replacement_part_no: "",
      quantity: null,
      need_by_date: null,
      urgency_level: "",
    };
    const statusDropdown = [...assetStatusDropdown];

    if (assetDetails) {
      updated.asset_type = assetDetails?.asset_type?.toString();
      updated.asset_name = assetDetails?.asset_name;
      updated.part_no = assetDetails?.part_no;
      updated.resource_type_id = assetDetails?.resource_type_id?.toString();
      updated.asset_id = assetDetails?.asset_id;
      updated.invoice_number = assetDetails?.invoice_number;
      updated.invoice_date = assetDetails?.invoice_date
        ? new Date(assetDetails?.invoice_date)
        : new Date();
      updated.invoice_value = assetDetails?.invoice_value;
      updated.receipt_date = assetDetails?.receipt_date
        ? new Date(assetDetails?.receipt_date)
        : new Date();
      updated.calibration_frequency =
        assetDetails?.calibration_frequency?.toString();
      updated.calibration_value = assetDetails?.calibration_value;
      updated.maintenance_frequency =
        assetDetails?.maintenance_frequency?.toString();
      updated.maintenance_value = assetDetails?.maintenance_value;
      updated.location_id = assetDetails?.location_id?.toString();
      updated.sub_location_id = assetDetails?.sub_location_id?.toString();
      updated.status = assetDetails?.status?.toString();
      updated.calibrationRequired =
        assetDetails?.calibration_value &&
        assetDetails?.calibration_frequency?.toString();
      updated.maintenanceRequired =
        assetDetails?.maintenance_value &&
        assetDetails?.maintenance_frequency?.toString();
      updated.replacement_part_no =
        assetDetails?.replacement_details?.[0]?.part_no || "";
      updated.quantity =
        assetDetails?.replacement_details?.[0]?.quantity || null;
      updated.need_by_date =
        assetDetails?.replacement_details?.[0]?.need_by_date || null;
      updated.urgency_level =
        assetDetails?.replacement_details?.[0]?.urgency_level || "";

      if (assetDetails?.status?.toString() === "need_attention") {
        statusDropdown.push({ label: NeedAttention, value: "need_attention" });
      }

      if (assetDetails?.status?.toString() === "replaced") {
        statusDropdown?.splice(0, 1);
      }
    }
    setAssetStatusDropdown(statusDropdown);
    if (preselectedType) {
      updated.resource_type_id = preselectedType?.toString();
    }
    setFormState(updated);
  }, [assetDetails, preselectedType]);

  const handleCheckboxChange = fieldName => {
    setErrors(errorValues);
    setFormState(prevState => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleInputChange = (name, value) => {
    setErrors(errorValues);
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const validateField = () => {
    const errorValue = { ...errors };
    let hasErrors = false;

    if (!formState?.asset_type || formState?.asset_type === null) {
      errorValue.asset_type = AssetTypesRequired;
      hasErrors = true;
    }
    if (!formState?.asset_name || formState?.asset_name === "") {
      errorValue.asset_name = AssetNameRequired;
      hasErrors = true;
    }
    if (!formState?.part_no || formState?.part_no === "") {
      errorValue.part_no = PartNumberRequired;
      hasErrors = true;
    }
    if (!formState?.resource_type_id || formState?.resource_type_id === null) {
      errorValue.resource_type_id = AssetTypeRequired;
      hasErrors = true;
    }
    if (!formState?.asset_id || formState?.asset_id === "") {
      errorValue.asset_id = AssetIdRequired;
      hasErrors = true;
    }
    if (!formState?.invoice_number || formState?.invoice_number === "") {
      errorValue.invoice_number = InvoiceNumberRequired;
      hasErrors = true;
    }
    if (!formState?.invoice_date || formState?.invoice_date === null) {
      errorValue.invoice_date = InvoiceDateRequired;
      hasErrors = true;
    }
    if (
      !formState?.invoice_value ||
      formState?.invoice_value === "" ||
      formState?.invoice_value === 0 ||
      Number(formState?.invoice_value) === 0
    ) {
      errorValue.invoice_value = InvoiceValueRequired;
      hasErrors = true;
    }
    if (!formState?.receipt_date || formState?.receipt_date === null) {
      errorValue.receipt_date = DateReceiptRequired;
      hasErrors = true;
    }
    if (
      formState?.calibrationRequired &&
      (!formState?.calibration_frequency ||
        formState?.calibration_frequency === null)
    ) {
      errorValue.calibration_frequency = CalibrationFrequencyRequired;
      hasErrors = true;
    }
    if (
      formState?.calibrationRequired &&
      (!formState?.calibration_value || formState?.calibration_value === "")
    ) {
      errorValue.calibration_value = CalibrationEveryRequired;
      hasErrors = true;
    }
    if (
      formState?.maintenanceRequired &&
      (!formState?.maintenance_frequency ||
        formState?.maintenance_frequency === null)
    ) {
      errorValue.maintenance_frequency = MaintenanceFrequencyRequired;
      hasErrors = true;
    }
    if (
      formState?.maintenanceRequired &&
      (!formState?.maintenance_value || formState?.maintenance_value === "")
    ) {
      errorValue.maintenance_value = MaintenanceEveryRequired;
      hasErrors = true;
    }
    if (!formState?.location_id || formState?.location_id === null) {
      errorValue.location_id = AssetLocationRequired;
      hasErrors = true;
    }
    if (!formState?.sub_location_id || formState?.sub_location_id === null) {
      errorValue.sub_location_id = AssetSubLocationRequired;
      hasErrors = true;
    }
    if (
      assetDetails?.resource_id &&
      (!formState?.status || formState?.status === null)
    ) {
      errorValue.status = AssetsStatusRequired;
      hasErrors = true;
    }
    setErrors(errorValue);
    return hasErrors;
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handleDoneClick = async () => {
    const errors = validateField();
    if (errors) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
      return;
    }
    if (
      (formState.status === "retired" ||
        formState.status === "replaced" ||
        formState.status === "need_repair") &&
      (!formState.reason || formState.reason === "")
    ) {
      toggleModal();
    } else {
      const data = { ...formState };
      const payload = { ...formState };
      let uploadedFile = [];
      if (document?.length > 0)
        await uploadFile(document).then(async uploaded => {
          uploadedFile = uploaded;
        });
      payload.asset_type = data?.asset_type || "";
      payload.calibration_frequency = data?.calibration_frequency || null;

      payload.calibration_value = data?.calibration_value || null;
      payload.location_id = data?.location_id || "";
      payload.sub_location_id = data?.sub_location_id || "";
      payload.maintenance_value = data?.maintenance_value || null;

      payload.maintenance_frequency = data?.maintenance_frequency || null;
      payload.resource_type_id = data?.resource_type_id || "";
      payload.status = data?.status || "";

      payload.invoice_date = formatDate(data?.invoice_date) || null;
      payload.receipt_date = formatDate(data?.receipt_date) || null;

      delete payload.calibrationRequired;
      delete payload.maintenanceRequired;

      payload.other_document = uploadedFile || [];
      payload.reason = data?.otherReason || data?.reason;
      delete payload.otherReason;
      payload.replacement_part_no = data?.replacement_part_no || "";
      payload.quantity = data?.quantity || null;
      payload.need_by_date = data?.need_by_date
        ? formatDate(data?.need_by_date)
        : null;
      payload.urgency_level = data?.urgency_level || "low";

      let res = null;
      if (assetDetails?.resource_id) {
        payload.resource_id = assetDetails?.resource_id;
        res = await dispatch(updateAssetData({ payload }));
      } else {
        payload.status = "active";
        res = await dispatch(createAssetData({ payload }));
      }
      if (res?.payload?.status_code === STATUS_200) {
        if (editData) {
          const passName =
            assetTypes?.resource_type?.find(
              item =>
                item?.resource_type_id?.toString() ===
                data?.resource_type_id?.toString()
            )?.name || "";

          return navigate(
            `${process.env.PUBLIC_URL}/master-data/asset-management/assets-list?asset_id=${data?.resource_type_id}&name=${passName}`
          );
        }
        return navigate(
          `${process.env.PUBLIC_URL}/master-data/asset-management`
        );
      }
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleReasonSubmit = () => {
    handleDoneClick();
  };

  return (
    <div className="page-body pb-5">
      <Card>
        <CardBody>
          <H4 className="mt-3 mb-2">
            {assetId ? `${Edit} ${Asset}` : `${Add} ${NewAssets}`}
          </H4>
          <Divider />
          <Row className="align-items-center">
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={AssetsType}
                  important
                  tooltipText={AssetTypeToolTipMessage}
                />
                <ReactSelect
                  name="asset_type"
                  className="text-gray"
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={false}
                  placeholder={`${Select} ${CapitalOrConsumable}`}
                  options={AssetsCategoryOptions?.map(asset => ({
                    label: asset.label,
                    value: asset.value,
                  }))}
                  onChange={selectedOption =>
                    handleInputChange("asset_type", selectedOption?.value)
                  }
                  value={
                    AssetsCategoryOptions?.find(
                      user => user?.value === formState?.asset_type
                    ) || null
                  }
                  classNamePrefix="react-select"
                  components={{
                    MenuList: props => (
                      <SearchDropDownPaginator {...props} maxHeight={80} />
                    ),
                  }}
                />

                {errors.asset_type && (
                  <div className="text-danger">{errors.asset_type}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={AssetName}
                  important
                  tooltipText={`${Enter} ${AssetName}`}
                />
                <Input
                  type="text"
                  name="asset_name"
                  placeholder={`${Enter} ${AssetName}`}
                  value={formState.asset_name}
                  onChange={e =>
                    handleInputChange("asset_name", e.target.value)
                  }
                  invalid={!!errors.asset_name}
                  maxLength={100}
                />
                {errors.asset_name && (
                  <div className="invalid-feedback">{errors.asset_name}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={PartNumber}
                  important
                  tooltipText={`${Enter} ${PartNumber}`}
                />
                <Input
                  type="text"
                  name="part_no"
                  placeholder={`${Enter} ${PartNumber}`}
                  value={formState.part_no}
                  onChange={e => handleInputChange("part_no", e.target.value)}
                  invalid={!!errors.part_no}
                  maxLength={20}
                />
                {errors.part_no && (
                  <div className="invalid-feedback">{errors.part_no}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={AssetCategory}
                  important
                  tooltipText={`${Select} ${AssetCategory}`}
                />
                <ReactSelect
                  name="resource_type_id"
                  className="text-gray"
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={false}
                  placeholder={`${Select} ${AssetCategory}`}
                  options={assetTypesData}
                  classNamePrefix="react-select"
                  components={{
                    MenuList: props => (
                      <SearchDropDownPaginator {...props} maxHeight={400} />
                    ),
                  }}
                  value={
                    assetTypesData?.find(
                      user => user?.value === formState?.resource_type_id
                    ) || null
                  }
                  onChange={selectedOption =>
                    handleInputChange("resource_type_id", selectedOption?.value)
                  }
                />
                {errors.resource_type_id && (
                  <div className="text-danger">{errors.resource_type_id}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={AssetsID}
                  important
                  tooltipText={AssetIDToolTipMessage}
                />
                <Input
                  type="text"
                  name="asset_id"
                  placeholder={`${Enter} ${AssetsID}`}
                  value={formState.asset_id}
                  onChange={e => handleInputChange("asset_id", e.target.value)}
                  invalid={!!errors.asset_id}
                  maxLength={20}
                />
                {errors.asset_id && (
                  <div className="invalid-feedback">{errors.asset_id}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={InvoiceNumber}
                  important
                  tooltipText={`${Enter} ${InvoiceNumber}`}
                />
                <Input
                  type="text"
                  name="invoice_number"
                  placeholder={`${Enter} ${InvoiceNumber}`}
                  value={formState.invoice_number}
                  onChange={e =>
                    handleInputChange("invoice_number", e.target.value)
                  }
                  invalid={!!errors.invoice_number}
                  maxLength={20}
                />
                {errors.invoice_number && (
                  <div className="invalid-feedback">
                    {errors.invoice_number}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={InvoiceDate}
                  important
                  tooltipText={`${Enter} ${InvoiceDate}`}
                />
                <DatePicker
                  selected={
                    formState?.invoice_date instanceof Date
                      ? formState?.invoice_date
                      : new Date(formState?.invoice_date)
                  }
                  onChange={(date: Date | null) =>
                    handleInputChange("invoice_date", date)
                  }
                  maxDate={new Date()}
                  placeholderText={`${Select} ${Dates}`}
                  dateFormat="dd-MM-yyyy"
                  className={`w-100 form-control ${errors?.invoice_date ? "is-invalid" : ""}`}
                  disabledKeyboardNavigation={true}
                  onKeyDown={e => e.preventDefault()}
                />
                {errors?.invoice_date && (
                  <div className="invalid-date-picker-feedback">
                    {errors?.invoice_date}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={InvoiceValue}
                  important
                  tooltipText={`${Enter} ${InvoiceValue}`}
                />
                <Input
                  type="number"
                  name="invoice_value"
                  placeholder={`${Enter} ${InvoiceValue}`}
                  value={formState.invoice_value}
                  onChange={e => {
                    if (
                      (e.target.value &&
                        e.target.value?.toString()?.length <= 10) ||
                      e.target.value === ""
                    )
                      handleInputChange("invoice_value", e.target.value);
                  }}
                  min={0}
                  invalid={!!errors.invoice_value}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.invoice_value && (
                  <div className="invalid-feedback">{errors.invoice_value}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={DateReceipt}
                  important
                  tooltipText={DateReceiptToolTipMessage}
                />
                <DatePicker
                  selected={
                    formState?.receipt_date instanceof Date
                      ? formState?.receipt_date
                      : new Date(formState?.receipt_date)
                  }
                  onChange={(date: Date | null) =>
                    handleInputChange("receipt_date", date)
                  }
                  maxDate={new Date()}
                  placeholderText={`${Select} ${Dates}`}
                  dateFormat="dd-MM-yyyy"
                  className={`w-100 form-control ${errors?.receipt_date ? "is-invalid" : ""}`}
                  disabledKeyboardNavigation={true}
                  onKeyDown={e => e.preventDefault()}
                />
                {errors?.receipt_date && (
                  <div className="invalid-date-picker-feedback">
                    {errors?.receipt_date}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="align-items-center mt-2">
            <Col md={12} className="d-flex align-items-center">
              <Col md={2}>
                <LabelTooltip
                  label={CalibrationRequired}
                  tooltipText={CalibrationRequiredToolTipMessage}
                  important={false}
                />
              </Col>
              <Col md={4}>
                <div className="mt-1 ms-3">
                  <CheckboxButton
                    checked={formState.calibrationRequired}
                    onChange={() => {
                      handleInputChange("calibration_frequency", null);
                      handleInputChange("calibration_value", "");
                      handleCheckboxChange("calibrationRequired");
                    }}
                  />
                </div>
              </Col>
            </Col>
          </Row>

          {formState.calibrationRequired && (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={Every}
                    important
                    tooltipText={EveryCalibrationToolTipMessage}
                  />
                  <Input
                    type="number"
                    name="calibration_value"
                    placeholder={`${Enter} ${Every}`}
                    value={formState.calibration_value}
                    onChange={e => {
                      if (
                        (e.target.value &&
                          Number(e.target.value) &&
                          Number(e.target.value) <= 99) ||
                        e.target.value === ""
                      )
                        handleInputChange("calibration_value", e.target.value);
                    }}
                    min={0}
                    invalid={!!errors.calibration_value}
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  />
                  {errors.calibration_value && (
                    <div className="invalid-feedback">
                      {errors.calibration_value}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={Frequency}
                    important
                    tooltipText={FrequencyCalibrationToolTipMessage}
                  />
                  <ReactSelect
                    name="type"
                    className="text-gray"
                    styles={customStyles}
                    isSearchable={true}
                    isClearable={false}
                    placeholder={`${Select} ${Frequency}`}
                    options={frequencyRangeOptions?.map(frequency => ({
                      label: frequency?.label,
                      value: frequency?.value,
                    }))}
                    classNamePrefix="react-select"
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={120} />
                      ),
                    }}
                    value={
                      frequencyRangeOptions?.find(
                        user => user?.value === formState?.calibration_frequency
                      ) || null
                    }
                    onChange={selectedOption =>
                      handleInputChange(
                        "calibration_frequency",
                        selectedOption?.value
                      )
                    }
                  />
                  {errors.calibration_frequency && (
                    <div className="text-danger">
                      {errors.calibration_frequency}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="align-items-center mt-2">
            <Col md={12} className="d-flex align-items-center">
              <Col md={2}>
                <LabelTooltip
                  label={MaintenanceRequired}
                  tooltipText={MaintenanceRequiredToolTipMessage}
                  important={false}
                />
              </Col>

              <Col md={4}>
                <div className="mt-1 ms-3">
                  <CheckboxButton
                    checked={formState.maintenanceRequired}
                    onChange={() => {
                      handleInputChange("maintenance_frequency", null);
                      handleInputChange("maintenance_value", "");
                      handleCheckboxChange("maintenanceRequired");
                    }}
                  />
                </div>
              </Col>
            </Col>
          </Row>

          {formState.maintenanceRequired && (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={Every}
                    important
                    tooltipText={EveryMaintenanceToolTipMessage}
                  />
                  <Input
                    type="number"
                    name="maintenance_value"
                    placeholder={`${Enter} ${Every}`}
                    value={formState.maintenance_value}
                    onChange={e => {
                      if (
                        (e.target.value &&
                          Number(e.target.value) &&
                          Number(e.target.value) <= 99) ||
                        e.target.value === ""
                      )
                        handleInputChange("maintenance_value", e.target.value);
                    }}
                    min={0}
                    invalid={!!errors.maintenance_value}
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  />
                  {errors.maintenance_value && (
                    <div className="invalid-feedback">
                      {errors.maintenance_value}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={Frequency}
                    important
                    tooltipText={FrequencyMaintenanceToolTipMessage}
                  />
                  <ReactSelect
                    name="type"
                    className="text-gray"
                    styles={customStyles}
                    isSearchable={true}
                    isClearable={false}
                    placeholder={`${Select} ${Type}`}
                    options={frequencyRangeOptions?.map(frequency => ({
                      label: frequency?.label,
                      value: frequency?.value,
                    }))}
                    classNamePrefix="react-select"
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={120} />
                      ),
                    }}
                    value={
                      frequencyRangeOptions?.find(
                        user => user?.value === formState?.maintenance_frequency
                      ) || null
                    }
                    onChange={selectedOption =>
                      handleInputChange(
                        "maintenance_frequency",
                        selectedOption?.value
                      )
                    }
                  />
                  {errors.maintenance_frequency && (
                    <div className="text-danger">
                      {errors.maintenance_frequency}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={Location}
                  important
                  tooltipText={`${Enter} ${Location}`}
                />
                <ReactSelect
                  name="type"
                  className="text-gray"
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={false}
                  placeholder={`${Select} ${Location}`}
                  options={locationListData}
                  classNamePrefix="react-select"
                  components={{
                    MenuList: props => (
                      <SearchDropDownPaginator {...props} maxHeight={400} />
                    ),
                  }}
                  value={
                    locationListData?.find(
                      user => user?.value === formState?.location_id
                    ) || null
                  }
                  onChange={async selectedOption => {
                    if (selectedOption?.value && selectedOption?.value !== "")
                      await dispatch(
                        getManageMasterSubLocationListByLocation({
                          location_id: selectedOption?.value,
                        })
                      );
                    else if (selectedOption?.value === "") {
                      setFormState(prevState => ({
                        ...prevState,
                        ["sub_location_id"]: null,
                      }));
                    }
                    handleInputChange("location_id", selectedOption?.value);
                  }}
                />
                {errors.location_id && (
                  <div className="text-danger">{errors.location_id}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={SubLocation}
                  important
                  tooltipText={`${Enter} ${SubLocation}`}
                />
                <ReactSelect
                  name="type"
                  className="text-gray"
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={false}
                  placeholder={`${Select} ${SubLocation}`}
                  options={
                    formState?.location_id && formState?.location_id !== ""
                      ? subLocationListData
                      : []
                  }
                  classNamePrefix="react-select"
                  components={{
                    MenuList: props => (
                      <SearchDropDownPaginator {...props} maxHeight={250} />
                    ),
                  }}
                  value={
                    subLocationListData?.find(
                      user => user?.value === formState?.sub_location_id
                    ) || null
                  }
                  onChange={selectedOption =>
                    handleInputChange("sub_location_id", selectedOption?.value)
                  }
                />
                {errors.sub_location_id && (
                  <div className="text-danger">{errors.sub_location_id}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg="6">
              {assetDetails?.resource_id && (
                <FormGroup>
                  <LabelTooltip
                    label={AssetsStatus}
                    important
                    tooltipText={AssetsStatusToolTipMessage}
                  />
                  <ReactSelect
                    name="type"
                    className="text-gray"
                    styles={customStyles}
                    isSearchable={true}
                    isClearable={false}
                    placeholder={`${Select} ${AssetsStatus}`}
                    options={assetStatusDropdown?.map(frequency => ({
                      label: frequency?.label,
                      value: frequency?.value,
                    }))}
                    classNamePrefix="react-select"
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={160} />
                      ),
                    }}
                    value={
                      assetStatusDropdown?.find(
                        user => user?.value === formState?.status
                      ) || null
                    }
                    onChange={selectedOption =>
                      handleInputChange("status", selectedOption?.value)
                    }
                    isDisabled={
                      formState?.status?.toString() === "need_attention"
                    }
                  />
                  {errors.status && (
                    <div className="text-danger">{errors.status}</div>
                  )}
                </FormGroup>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={6}>
              <LabelTooltip
                label={UploadDocumentInvoice}
                important={false}
                tooltipText={UploadDocumentInvoice}
              />
              <MultiFileUpload
                accept=".pdf,.docx"
                multiple={true}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setDocument(prevDocuments => [...prevDocuments, file]);
                  }
                }}
              />
            </Col>
          </Row>

          <FixedFooter>
            <Btn color="primary" onClick={handleDoneClick}>
              {assetId ? Update : Add}
            </Btn>
          </FixedFooter>
          <ReasonAssetModal
            selectedStatus={formState}
            isOpen={modalOpen}
            toggle={toggleModal}
            onSubmit={handleReasonSubmit}
            setFormStateData={setFormState}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default UpsertAssetManagementForm;
