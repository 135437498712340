import PropTypes from "prop-types";
import React, { useState, ReactNode } from "react";
import { Tooltip } from "reactstrap";

interface TooltipLabelProps {
  tooltipText: string | string[];
  placement?: "top" | "bottom" | "left" | "right";
  toolTipUniqueId: any;
  children: ReactNode;
}

const TextTooltip: React.FC<TooltipLabelProps> = ({
  tooltipText,
  placement = "top",
  toolTipUniqueId,
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(prev => !prev);

  return (
    <div className="text-tooltip-wrapper">
      <div id={`TooltipIcon${toolTipUniqueId}`} className="tooltip-label">
        {children}
      </div>
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={`TooltipIcon${toolTipUniqueId}`}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};

TextTooltip.propTypes = {
  tooltipText: PropTypes.any,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  toolTipUniqueId: PropTypes.any,
  children: PropTypes.node,
};

export default TextTooltip;
