import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H5 } from "../../AbstractElements";
import DetailHeaderContainer from "../../CommonElements/DetailHeaderContainer";
import StatusDisplay from "../../CommonElements/StatusDisplay";
import { NoDataText, Source } from "../../utils/Constant";
import { getRandomInteger } from "../../utils/helper/helper";
import NoData from "../NoData";
import "./style.scss";

interface ActivityLogProps {
  date: string;
  name: string;
  data: NestedActivity[];
}

interface NestedActivity {
  sourceLabel: string;
  previousValue: string;
  currentValue: string;
}

interface ActivityLogContainerProps {
  logs: ActivityLogProps[];
}

const ActivityLogContainer: React.FC<ActivityLogContainerProps> = ({
  logs,
}) => {
  const hasLogs = logs?.some(item => item.data.length > 0);

  return (
    <div className="pt-1 activity-wrapper">
      <div className="activity-section">
        <Card>
          <CardBody className="m-2">
            {hasLogs ? (
              logs?.map((log, index) => (
                <div className="activity-card" key={index}>
                  {(log.date || log.name) && (
                    <div className="d-flex gap-3 align-items-center">
                      <H5>{log.date}</H5>
                      <span>|</span>
                      <H5>{log.name}</H5>
                    </div>
                  )}
                  {log.data.map((item, index) => (
                    <div
                      className={`${log.data.length > 1 && "activity-section__inner-section"}`}
                      key={index}
                    >
                      <div className="mt-2">
                        <StatusDisplay
                          label={Source}
                          number={item.sourceLabel}
                        />
                      </div>
                      <div className="mt-2">
                        <Row>
                          <Col>
                            <DetailHeaderContainer
                              assign="Previous Value"
                              name={item.previousValue}
                              key={getRandomInteger(1, 100)}
                            />
                          </Col>
                          <Col>
                            <DetailHeaderContainer
                              assign="Current Value"
                              name={item.currentValue}
                              key={getRandomInteger(1, 100)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <NoData
                svg={"empty-folder-icon"}
                title={NoDataText}
                showCard={false}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

ActivityLogContainer.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default ActivityLogContainer;
