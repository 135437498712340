import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import { ALL_USERS_STATUS } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Action,
  Available,
  Booked,
  CourseName,
  CoursePeriod,
  Employee,
  Instructor,
  Location,
  NominationClosesOn,
  Status,
  WaitList,
} from "../../utils/Constant";
import {
  calculateDayDifference,
  courseScheduleStatusMap,
  formatCustomDate,
  isDateGreaterThanTodayDate,
} from "../../utils/helper/helper";
import "./style.scss";

const NominationListTable = ({ data, handleCourseClick, actions }) => {
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [userMap, setUserMap] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.user);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, []);

  const toggleTooltip = index => {
    setTooltipOpen(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const renderActions = row => {
    const isGreaterThanToday = isDateGreaterThanTodayDate(
      row.course_start_date
    );
    return actions
      ?.filter(action => !action.isHide)
      .map((action, index) => {
        if (action?.type === "icon") {
          return (
            <>
              {isGreaterThanToday &&
                row?.nomination_status === "pending" &&
                row.rm_approval && (
                  <span
                    key={action.label + index}
                    id={`tooltip-${action.label}-${index}`}
                  >
                    <MUIIcons
                      iconName={action.icon}
                      onClick={() => action.onClick(row)}
                      className={action.className || "pointer"}
                      size={27}
                    />
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[index] || false}
                      target={`tooltip-${action.label}-${index}`}
                      toggle={() => toggleTooltip(index)}
                    >
                      {action.label}
                    </Tooltip>
                  </span>
                )}
            </>
          );
        } else if (action?.type === "dropdown") {
          const filteredDropdown = action?.dropdownOptions?.filter(
            item =>
              (item.label === "Rating" && !item.isHide) ||
              (item.label === "Feedback" && !item.isHide)
          );

          const filteredData =
            filteredDropdown.length > 0
              ? filteredDropdown
              : action.dropdownOptions;

          return (
            <Dropdown
              key={index + action.label}
              isOpen={row?.nomination_id === dropdownOpen}
              toggle={e => toggleDropdown(e, row.nomination_id)}
              direction="up"
            >
              {filteredDropdown.length > 0 || isGreaterThanToday ? (
                <DropdownToggle color="" className="three-dots">
                  <MUIIcons
                    iconName="MoreVertOutlined"
                    className="primary-icon-color"
                    size={22}
                  />
                </DropdownToggle>
              ) : (
                "-"
              )}
              <DropdownMenu className="dropdown-menu-end">
                {filteredData
                  .filter(dropdownOption => !dropdownOption.isHide)
                  .map((dropdownOption, idx) => (
                    <DropdownItem
                      key={dropdownOption.label + idx}
                      onClick={() => dropdownOption.onClick(row)}
                    >
                      {dropdownOption.label}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          );
        }
        return "-";
      });
  };

  const scheduledCourseColumns = [
    {
      name: Employee,
      selector: row =>
        userMap ? userMap[row?.participant_id]?.user_name : "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: CourseName,
      selector: row => (
        <button
          onClick={() => handleCourseClick(row)}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {row.course_name}
        </button>
      ),
      minWidth: "16rem",
      sortable: true,
    },
    {
      name: Instructor,
      selector: row =>
        userMap ? userMap[row?.primary_trainer_id]?.user_name : "-",
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Location,
      selector: row => row?.location_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: CoursePeriod,
      selector: row =>
        `${formatCustomDate(row?.course_start_date)} - ${formatCustomDate(row?.course_end_date)}`,
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Available,
      selector: row => row.available_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: Booked,
      selector: row => row.booked_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: WaitList,
      selector: row => row.waitlist_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: NominationClosesOn,
      selector: row => `${calculateDayDifference(row.course_end_date)} days`,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Status,
      selector: row => courseScheduleStatusMap[row?.status],
      sortable: true,
      width: "8rem",
    },
    {
      name: Action,
      center: true,
      cell: row => <div className="d-flex gap-2">{renderActions(row)}</div>,
      minWidth: "6rem",
    },
  ];

  return (
    <DataTable
      className="nomination-table"
      columns={scheduledCourseColumns}
      data={data}
      pagination
      fixedHeader
      striped
    />
  );
};

NominationListTable.propTypes = {
  data: PropTypes.array,
  handleCourseClick: PropTypes.func,
  actions: PropTypes.array,
};

export default NominationListTable;
