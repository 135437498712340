import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";

import { DefaultButton } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import FeedbackAnalysis from "../../../../container/FeedbackAnalysisCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getNomineesOfSchedule } from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getCourseRatings,
  getTrainerFeedback,
  updateTrainerFeedback,
} from "../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { RatingValues, Submit } from "../../../../utils/Constant";
import { ratingDropdown } from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
} from "../../../../utils/helper/permission";

const RatingListing = ({
  scheduledId,
  courseName,
  startDate,
  endDate,
  locationName,
  classRoomName,
  instructorId,
  primaryTrainerId,
  replacedTrainerId,
}) => {
  const dispatch = useAppDispatch();
  const { ratings, ratingsList } = useAppSelector(state => state.feedback);
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState({});
  const [participantResponses, setParticipantResponses] = useState([]);
  const { nominees } = useAppSelector(state => state.course);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    if (scheduledId) {
      dispatch(
        getNomineesOfSchedule({ schedule_id: scheduledId, type: "attendees" })
      );
      dispatch(getTrainerFeedback({ course_schedule_id: scheduledId }));
      dispatch(getCourseRatings({ course_schedule_id: scheduledId }));
    }
  }, [dispatch, scheduledId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const getDropdown = (row, question) => {
    const existingParticipant = participantResponses?.find(
      resp => resp?.participant_id === row?.participant_id
    );

    const response =
      existingParticipant?.question_responses?.find(
        score => score?.id === question?.id
      )?.response || "";

    return (
      <div className="w-100">
        <Input
          type="select"
          onChange={e => {
            return handleDropdownChange(
              row.participant_id,
              question.id,
              e.target.value
            );
          }}
          value={response}
        >
          {ratingDropdown.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  const handleDropdownChange = (participantId, questionId, response) => {
    setParticipantResponses(prevResponses => {
      const updatedResponses = prevResponses.map(participant => {
        if (participant.participant_id === participantId) {
          const questionResponses = participant.question_responses.map(
            question => {
              return question.id === questionId
                ? { ...question, response: Number(response) }
                : question;
            }
          );

          if (!questionResponses.some(question => question.id === questionId)) {
            questionResponses.push({
              id: questionId,
              response: Number(response),
            });
          }

          return { ...participant, question_responses: questionResponses };
        }
        return participant;
      });

      if (
        !updatedResponses.some(
          participant => participant.participant_id === participantId
        )
      ) {
        updatedResponses.push({
          participant_id: participantId,
          question_responses: [{ id: questionId, response: Number(response) }],
        });
      }

      return updatedResponses;
    });
  };

  const generateDynamicColumns = () => {
    const participantColumn = {
      name: "Participant",
      minWidth: "18rem",
      cell: row => {
        const userDetails = userMap[row.participant_id] || {};
        return (
          <div>
            <p className="mb-2 mt-2 fw-bold">{userDetails.user_name}</p>
            <p className="m-0">
              EMP ID: {userDetails.employee_code} | Phone: {userDetails.phone}
            </p>
          </div>
        );
      },
    };

    const questionColumns =
      ratings?.questions?.map(question => ({
        name: (
          <div>
            {question.tool_tip ? (
              <LabelTooltip
                label={question.question_text}
                tooltipText={question.tool_tip}
                important={false}
                placement={"bottom"}
              />
            ) : (
              question.question_text
            )}
          </div>
        ),
        cell: row => getDropdown(row, question),
        minWidth: "10rem",
      })) || [];

    return [participantColumn, ...questionColumns];
  };

  const onSubmit = async () => {
    const payload = {
      course_schedule_id: scheduledId,
      trainer_id: currentUserId(),
      ratings: [],
    };

    const allParticipants = nominees?.nominees || [];
    const allQuestions = ratings?.questions || [];

    allParticipants.forEach(participant => {
      const participantResponsesObj = {
        participant_id: participant.participant_id,
        question_responses: [],
      };

      allQuestions.forEach((question: any) => {
        const existingResponse = participantResponses.find(
          resp => resp.participant_id === participant.participant_id
        );

        const responseValue =
          existingResponse?.question_responses?.find(
            resp => resp.id === question.id
          )?.response || 1;

        participantResponsesObj.question_responses.push({
          question_id: question.id,
          response: responseValue,
        });
      });

      payload.ratings.push(participantResponsesObj);
    });

    await dispatch(updateTrainerFeedback({ data: payload }));
  };

  const renderFeedbackAnalysis = () => {
    return ratingsList?.feedback.map(rating => {
      const participant = userMap[rating.participant_id];
      const userDetails = {
        id: participant?.id,
        name: participant?.user_name,
        empId: participant?.employee_code,
        mobile: participant?.phone,
      };

      const statusData = rating?.overall_category?.map(category => ({
        label: category.category,
        value: `${category.percentage}%`,
      }));

      return (
        <div key={rating.participant_id}>
          <Link
            to={"/rating-summary"}
            state={{
              scheduledId: scheduledId,
              participant_id: rating?.participant_id,
              courseFeedbackId: rating?.course_feedback_id,
              courseName: courseName,
              startDate: startDate,
              endDate: endDate,
              locationName: locationName,
              classRoomName: classRoomName,
              instructorId: instructorId,
              statusData: statusData,
            }}
            className="mt-2"
          >
            <FeedbackAnalysis
              userDetails={userDetails}
              statusItems={statusData}
            />
          </Link>
          <Divider />
        </div>
      );
    });
  };

  return (
    <div className="mt-3">
      {ratings.ratings && (
        <div className="mt-2 p-1">{renderFeedbackAnalysis()}</div>
      )}
      {!ratings.ratings &&
        hasPermissionToComponent("RATE_TRAINEE") &&
        (currentUserId() === primaryTrainerId ||
          replacedTrainerId?.includes(currentUserId())) && (
          <div>
            <div className="p-3">{RatingValues}</div>
            <DataTable
              columns={generateDynamicColumns()}
              data={nominees?.nominees || []}
              pagination
              fixedHeader
              striped
            />
            <FixedFooter>
              <DefaultButton color="primary" onClick={onSubmit}>
                {Submit}
              </DefaultButton>
            </FixedFooter>
          </div>
        )}
    </div>
  );
};

RatingListing.propTypes = {
  scheduledId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  classRoomName: PropTypes.string.isRequired,
  instructorId: PropTypes.string.isRequired,
  primaryTrainerId: PropTypes.any,
  replacedTrainerId: PropTypes.any,
};

export default RatingListing;
