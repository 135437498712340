import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormGroup, Input } from "reactstrap";

import { Btn } from "../../../../../AbstractElements";
import {
  APPROVE_COURSE_REQUEST_STATUS,
  rmTabsStatusMap,
} from "../../../../../Api/constants";
import { convertCoursePayloadToForm } from "../../../../../Api/parser/courseRequest";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import CourseDetails from "../../../../../container/CourseDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  approveCourseRequest,
  getCourseRequestById,
} from "../../../../../ReduxToolkit/Reducers/CourseRequestSlice";
import { setCourseBasicInputFormValueToInitials } from "../../../../../ReduxToolkit/Reducers/CourseWizardSlice";
import { getRegionList } from "../../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Accomplishment,
  AddComment,
  Approve,
  BasicInfo,
  BusinessGroup,
  ContentDetails,
  ContentTopic,
  CountOfTargetAudience,
  CustomerName,
  Dates,
  Description,
  Designation,
  EmailId,
  Enter,
  EnterRejectRequestReasonToolTipMessage,
  Extension,
  FilledBy,
  Frequency,
  KeyContact,
  Name,
  NatureOfBusiness,
  Notes,
  Objective,
  PhoneNumber,
  PleaseAddComment,
  PointOfContact,
  PrimaryContact,
  Region,
  Reject,
  RejectReason,
  SecondaryContact,
  SecondaryEmailId,
  SecondaryExtension,
  SecondaryPhoneNumber,
  Submit,
  SuggestedDuration,
  Summary,
  TargetAudience,
} from "../../../../../utils/Constant";
import {
  formatCustomDate,
  frequencyType,
  sanitizedContent,
  showToast,
} from "../../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../../utils/helper/permission";

const RMDetails = ({ courseRequestId, userMap }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { courseRequestById } = useAppSelector(state => state.courseRequest);
  const { regionList } = useAppSelector(state => state.master);
  const [basicInfoDetails, setBasicInfoDetails] = useState(null);
  const [contentDetails, setContentDetails] = useState(null);
  const [keyContactDetails, setKeyContactDetails] = useState(null);
  const [notesDetails, setNotesDetails] = useState(null);
  const [comments, setComments] = useState(null);
  const [regionMap, setRegionMap] = useState(null);

  useEffect(() => {
    dispatch(getRegionList());
    if (courseRequestId) {
      dispatch(getCourseRequestById({ id: courseRequestId }));
    }
  }, [dispatch, courseRequestId]);

  useEffect(() => {
    const regions = {};
    regionList?.regions?.forEach(region => {
      regions[region.id] = region;
    });
    setRegionMap(regions);
  }, [regionList]);

  const getBasicData = parsedRequestData => {
    const basicData = [
      {
        assign:
          courseRequestById?.request_type === "internal" ? Name : CustomerName,
        name: parsedRequestData?.customer_name,
      },
    ];

    if (courseRequestById?.request_type === "internal") {
      basicData.push({
        assign: Designation,
        name: parsedRequestData?.designation_id,
      });
    } else {
      basicData.push(
        {
          assign: FilledBy,
          name: userMap[parsedRequestData?.created_by]?.user_name,
        },
        {
          assign: Designation,
          name: parsedRequestData?.designation_id,
        }
      );
    }

    return basicData;
  };

  const getDetailsData = (parsedRequestData, courseRequestById) => {
    const details = [
      {
        assign: Dates,
        name: formatCustomDate(parsedRequestData?.submitted_at),
      },
      {
        assign: Region,
        name: parsedRequestData?.region,
      },
    ];

    if (courseRequestById?.request_type !== "external") {
      details.push({
        assign: BusinessGroup,
        name: parsedRequestData?.business_group_id,
      });
    }

    if (courseRequestById?.request_type === "external") {
      details.push({
        assign: NatureOfBusiness,
        name: parsedRequestData?.nature_of_business,
      });
    }

    return details;
  };

  useEffect(() => {
    if (courseRequestById !== null && userMap !== null) {
      const parsedRequestData = convertCoursePayloadToForm(courseRequestById);
      const basicInfoFilledData = [
        {
          details: getBasicData(parsedRequestData),
        },
        {
          details: getDetailsData(parsedRequestData, courseRequestById),
        },
      ];

      if (courseRequestById?.request_type === "external") {
        basicInfoFilledData.push({
          details: [
            {
              assign: PointOfContact,
              name: parsedRequestData?.point_of_contact,
            },
          ],
        });
      }
      setBasicInfoDetails(basicInfoFilledData);

      const contentDetailsFilledData = [
        {
          details: parsedRequestData?.frequency_count
            ? [
                {
                  assign: Frequency,
                  name: `${parsedRequestData.frequency_count} ${frequencyType[parsedRequestData.frequency_type || "weekly"]}`,
                },
                { assign: ContentTopic, name: parsedRequestData.content_topic },
                { assign: Summary, name: parsedRequestData.summary },
              ]
            : [
                { assign: ContentTopic, name: parsedRequestData.content_topic },
                { assign: Summary, name: parsedRequestData.summary },
              ],
        },
        {
          details: [
            {
              assign: Accomplishment,
              name: (
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(parsedRequestData?.accomplishment),
                  }}
                />
              ),
            },
            {
              assign: TargetAudience,
              name: parsedRequestData?.target_audiance,
            },
            {
              assign: CountOfTargetAudience,
              name: parsedRequestData?.expected_target_audience,
            },
          ],
        },
        {
          details: [
            {
              assign: SuggestedDuration,
              name: parsedRequestData?.course_duration
                ? parsedRequestData?.course_duration +
                  " " +
                  frequencyType[parsedRequestData?.course_duration_type]
                : "",
            },
            {
              assign: Objective,
              name: (
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(parsedRequestData?.objective),
                  }}
                />
              ),
            },
          ],
        },
      ];
      setContentDetails(contentDetailsFilledData);

      const keyContentDetailsFilledData = [
        {
          details: [
            {
              assign: PrimaryContact,
              name: parsedRequestData?.primary_contact,
            },
            {
              assign: PhoneNumber,
              name: parsedRequestData?.phone_number || "",
            },
            { assign: EmailId, name: parsedRequestData?.email_id },
          ],
        },
        {
          details: [{ assign: Extension, name: parsedRequestData?.extension }],
        },
        {
          details: [
            {
              assign: SecondaryContact,
              name: parsedRequestData?.secondary_contact,
            },
            {
              assign: SecondaryPhoneNumber,
              name: parsedRequestData?.secondary_phone_number || "",
            },
            {
              assign: SecondaryEmailId,
              name: parsedRequestData?.secondary_email_id,
            },
          ],
        },
        {
          details: [
            {
              assign: SecondaryExtension,
              name: parsedRequestData?.secondary_extension,
            },
          ],
        },
      ];
      setKeyContactDetails(keyContentDetailsFilledData);

      const notesDetailsFilledData = [
        {
          details: [
            {
              assign: Notes,
              name: (
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(parsedRequestData?.notes),
                  }}
                />
              ),
            },
          ],
        },
      ];

      setNotesDetails(notesDetailsFilledData);
    }
  }, [courseRequestById, userMap, regionMap]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRejectReview = async () => {
    if (comments === "" || comments === null) {
      showToast(PleaseAddComment, "error");
      return;
    }
    const reasonObj: ApproveCourseRequest = {
      course_request_id: courseRequestId,
      status: rmTabsStatusMap["reject"] as "rejected" | "approved",
      reason: comments,
    };
    const wrapperObj = {
      approveCourseData: reasonObj,
    };
    await dispatch(approveCourseRequest(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-request`
    );
  };

  return (
    <div className="pb-5">
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={RejectReason}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={handleRejectReview}
      >
        <FormGroup>
          <LabelTooltip
            label={AddComment}
            tooltipText={EnterRejectRequestReasonToolTipMessage}
            important
          />
          <Input
            onChange={e => {
              if (
                (e.target.value && e.target.value?.length <= 255) ||
                e.target.value === ""
              )
                setComments(e.target.value);
            }}
            maxLength={255}
            rows={4}
            name="objective"
            type="textarea"
            placeholder={`${Enter} ${Description}`}
          />
        </FormGroup>
      </CommonModal>

      <div className="mt-4">
        {basicInfoDetails !== null && (
          <CourseDetails
            level={3}
            data={basicInfoDetails}
            headerText={BasicInfo}
          />
        )}
        {contentDetails !== null && (
          <CourseDetails
            level={3}
            data={contentDetails}
            headerText={ContentDetails}
          />
        )}
        {keyContactDetails !== null && (
          <CourseDetails
            level={4}
            data={keyContactDetails}
            headerText={KeyContact}
          />
        )}
        {notesDetails !== null && (
          <CourseDetails level={1} data={notesDetails} headerText={Notes} />
        )}
        {hasPermissionToComponent("REJECT_RM_DETAILS") &&
          courseRequestById !== null &&
          APPROVE_COURSE_REQUEST_STATUS.includes(courseRequestById.status) && (
            <FixedFooter>
              <Btn
                outline
                className="alert-light-primary"
                color="primary"
                onClick={toggleModal}
              >
                {Reject}
              </Btn>
              <Link
                to="/course-management/add-course"
                state={{ courseRequestId: courseRequestId }}
              >
                <Btn
                  onClick={() =>
                    dispatch(setCourseBasicInputFormValueToInitials())
                  }
                  color="primary"
                >
                  {Approve}
                </Btn>
              </Link>
            </FixedFooter>
          )}
      </div>
    </div>
  );
};

RMDetails.propTypes = {
  courseRequestId: PropTypes.number.isRequired,
  userMap: PropTypes.object,
};

export default RMDetails;
