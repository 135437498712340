import AssessmentCatlogList from "../Pages/Assessment/AssessmentCatlog";
import AddAssessmentCatlog from "../Pages/Assessment/AssessmentCatlog/AddAssessmentCatlog";
import AssessmentCatlogDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails";
import ScheduleAssessmentsCatlog from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduleAssessment";
import ScheduledUserDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduledUserDetails";
import LearnerAssessmets from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments";
import CompetencyAssessmentDetails from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessmentDetail";
import CompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessments";
import ReviewCompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewCompetencyAssessment";
import ReviewStandaloneAssessments from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewStandaloneAssessments";
import ScheduledAssessments from "../Pages/Assessment/ScheduledAssessments";
import AssessmentList from "../Pages/AssessmentList";
import AssessmentListDetails from "../Pages/AssessmentList/AssessmentListDetails";
import Attempts from "../Pages/AssessmentList/AssessmentListDetails/Attempts";
import CourseCatalog from "../Pages/CourseCatalog";
import CourseDetails from "../Pages/CourseCatalog/CourseDetails";
import CourseList from "../Pages/CourseMangement/CourseList";
import CourseDetailedView from "../Pages/CourseMangement/CourseList/CourseStageDetails";
import AdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation";
import CreateAdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation/CreateAdditionalInformation";
import Assessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment";
import CreateAssessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment/CreateAssessment";
import CompetancyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement";
import CreateCompetencyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement/CreateCompetencyStatement";
import CourseMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials";
import CreateMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials/CreateMaterials";
import LessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan";
import CreateLessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan/CreateLessonPlan";
import LODetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO";
import CreateLO from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO/CreateLo";
import TrainerBondDetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/TrainerBondDetails";
import WebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement";
import CreateWebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement/createWebinarCompetencyStatement";
import CreateBasicCourseDetails from "../Pages/CourseMangement/CourseList/CreateBasicDetails";
import RequestList from "../Pages/CourseMangement/RequestList";
import CourseCreation from "../Pages/CourseMangement/RequestList/RaiseNewRequest";
import RequestDetails from "../Pages/CourseMangement/RequestList/RequestDetails";
import AddCurriculum from "../Pages/Curriculum/AddCurriculum";
import CurriculumList from "../Pages/Curriculum/CurriculumList";
import Dashboard from "../Pages/Dashboard";
import GlobalSettings from "../Pages/GlobalSettings";
import LearnerScheduleDetails from "../Pages/LearnerSchedule/LearnerScheduleDetails";
import CourseFeedback from "../Pages/LearnerSchedule/LearnerScheduleDetails/CourseFeedback";
import ModuleFeedback from "../Pages/LearnerSchedule/LearnerScheduleDetails/ModuleFeedback";
import RateNow from "../Pages/LearnerSchedule/LearnerScheduleDetails/ModuleFeedback/RateNow";
import LearnerScheduleList from "../Pages/LearnerSchedule/LearnerScheduleList";
import LoadFactor from "../Pages/LoadFactor";
import ModifyPlan from "../Pages/LoadFactor/ModifyPlan";
import ScheduleCourse from "../Pages/LoadFactor/ScheduleCourse";
import ViewPeople from "../Pages/LoadFactor/ViewPeople";
import AssestManagement from "../Pages/MasterData/AssetManagement";
import AssetHistory from "../Pages/MasterData/AssetManagement/AssetHistory";
import AssetsList from "../Pages/MasterData/AssetManagement/AssetsList";
import UpsertAssetManagementForm from "../Pages/MasterData/AssetManagement/UpsertAssetManagementForm";
import ClassRoomPage from "../Pages/MasterData/ClassRoom";
import CompetencyPage from "../Pages/MasterData/Competency";
import DomainPage from "../Pages/MasterData/Domain";
import IndustryPage from "../Pages/MasterData/Industry";
import JobRolePage from "../Pages/MasterData/JobRole";
import LearningOutcomePage from "../Pages/MasterData/LearningOutcome";
import LOForm from "../Pages/MasterData/LearningOutcome/LOForm";
import LocationPage from "../Pages/MasterData/Location";
import QuestionBankList from "../Pages/MasterData/QuestionBank";
import AddQuestionBank from "../Pages/MasterData/QuestionBank/AddQuestionBank";
import QuestionBankPreview from "../Pages/MasterData/QuestionBank/QuestionBankPreview";
import RegionPage from "../Pages/MasterData/Region";
import SubLocationPage from "../Pages/MasterData/SubLocation";
import InstructionPage from "../Pages/Mentoring&Coaching/CommonPagesOfMentoring&Coaching/InstructionPage";
import SessionDetailPage from "../Pages/Mentoring&Coaching/CommonPagesOfMentoring&Coaching/SessionDetailsPage";
import ManageMentoring from "../Pages/Mentoring&Coaching/ManageMentoring";
import MapMentorAndMenteeForm from "../Pages/Mentoring&Coaching/ManageMentoring/MapMentorAndMenteeForm";
import MentorDetails from "../Pages/Mentoring&Coaching/ManageMentoring/MentorDetails";
import ReplaceMentor from "../Pages/Mentoring&Coaching/ManageMentoring/ReplaceMentor";
import MenteePage from "../Pages/Mentoring&Coaching/Mentee";
import MenteeDetailsPage from "../Pages/Mentoring&Coaching/Mentee/MenteeDetailsPage";
import MenteeSessionFeedbackForm from "../Pages/Mentoring&Coaching/Mentee/MenteeSessionFeedbackForm";
import MentorDetailsPage from "../Pages/Mentoring&Coaching/Mentee/MentorDetailsPage";
import MentorPage from "../Pages/Mentoring&Coaching/Mentor";
import AddSessionForm from "../Pages/Mentoring&Coaching/Mentor/AddSessionForm";
import MyLearningPlan from "../Pages/MyLearningPlan";
import NominationRequest from "../Pages/NominationRequest";
import NominationCourseDetails from "../Pages/NominationRequest/CourseDetails";
import PolicyList from "../Pages/Policy";
import AddPolicy from "../Pages/Policy/AddPolicy";
import ProfileDetails from "../Pages/ProfileDetails";
import Reports from "../Pages/Reports";
import ScheduledCourseList from "../Pages/ScheduledCourse";
import ScheduleDetails from "../Pages/ScheduledCourse/ScheduleDetails";
import AddNominees from "../Pages/ScheduledCourse/ScheduleDetails/AddNominees";
import ModuleFeedbackList from "../Pages/ScheduledCourse/ScheduleDetails/ModuleFeedback/ModuleFeedbackList";
import RatingSummary from "../Pages/ScheduledCourse/ScheduleDetails/Rating/RatingSummary";
import MapSga from "../Pages/SGA/MapSga";
import SgaList from "../Pages/SGA/SgaList";
import UserManagement from "../Pages/UserManagement";
import AddExternalUser from "../Pages/UserManagement/AddUser";
import UserDetails from "../Pages/UserManagement/UserDetails";

const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Dashboard />,
    key: "dashboard",
  },
  {
    path: `${process.env.PUBLIC_URL}/user-management`,
    Component: <UserManagement />,
    key: "userManagement",
  },

  {
    path: `${process.env.PUBLIC_URL}/user-management/user-details`,
    Component: <UserDetails />,
    key: "userDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/new-request`,
    Component: <CourseCreation />,
    key: "createRequest",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/add-course`,
    Component: <CreateBasicCourseDetails />,
    key: "createCourse",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-request`,
    Component: <RequestList />,
    key: "requestList",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-list`,
    Component: <CourseList />,
    key: "courseList",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/request-details`,
    Component: <RequestDetails />,
    key: "requestDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details`,
    Component: <CourseDetailedView />,
    key: "courseDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/learning-outcome`,
    Component: <LODetails />,
    key: "loDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/competancy-statement`,
    Component: <CompetancyStatement />,
    key: "competencyDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/lesson-plan`,
    Component: <LessonPlan />,
    key: "lessonPlanDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-structure`,
    Component: <AdditionalInformation />,
    key: "additionInfoDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/assessment`,
    Component: <Assessment />,
    key: "courseAssessmentDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/question-bank`,
    Component: <QuestionBankPreview />,
    key: "questionBankPreview",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-material`,
    Component: <CourseMaterials />,
    key: "courseMaterialsDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/bond-details`,
    Component: <TrainerBondDetails />,
    key: "trainerBondDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-learning-outcome`,
    Component: <CreateLO />,
    key: "addLoDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-competency-statement`,
    Component: <CreateCompetencyStatement />,
    key: "addCompetencyDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-webinar-competency-statement`,
    Component: <CreateWebinarCompetativeStatement />,
    key: "addWebinarDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/webinar-competency-statement`,
    Component: <WebinarCompetativeStatement />,
    key: "webinarCompetencyDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-lesson-plan`,
    Component: <CreateLessonPlan />,
    key: "addLessonPlanDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-course-structure`,
    Component: <CreateAdditionalInformation />,
    key: "addLessonPlanDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-assessment`,
    Component: <CreateAssessment />,
    key: "addLessonPlanDetails",
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-material`,
    Component: <CreateMaterials />,
    key: "addLessonPlanDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/curriculum`,
    Component: <CurriculumList />,
    key: "curriculumList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/policy`,
    Component: <PolicyList />,
    key: "policyList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-new-curriculum`,
    Component: <AddCurriculum />,
    key: "addCurriculumDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/question-bank`,
    Component: <QuestionBankList />,
    key: "questionBankList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-question-bank`,
    Component: <AddQuestionBank />,
    key: "createQuestionBankDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-policy`,
    Component: <AddPolicy />,
    key: "addPolicyDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/skill-gap-analysis`,
    Component: <SgaList />,
    key: "sgaList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/map-sga`,
    Component: <MapSga />,
    key: "mapSGADetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/my-learning-plan`,
    Component: <MyLearningPlan />,
    key: "myLearningPlan",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/add-assessment`,
    Component: <AddAssessmentCatlog />,
    key: "addAssessmentCatlog",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessment-details`,
    Component: <AssessmentCatlogDetails />,
    key: "assessmentCatlogDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-assessment`,
    Component: <ScheduleAssessmentsCatlog />,
    key: "scheduleAssessmentCatlog",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-details`,
    Component: <ScheduledUserDetails />,
    key: "scheduledUserDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/learner-assessments`,
    Component: <LearnerAssessmets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment`,
    Component: <CompetencyAssessment />,
    key: "competencyAssessment",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-self-assessments`,
    Component: <ReviewCompetencyAssessment />,
    key: "reviewCompetencyAssessment",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment-details`,
    Component: <CompetencyAssessmentDetails />,
    key: "competencyAssessmentDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`,
    Component: <ReviewStandaloneAssessments />,
    key: "reviewStandaloneAssessment",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list`,
    Component: <AssessmentList />,
    key: "learnerAssessmetsList",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details`,
    Component: <AssessmentListDetails />,
    key: "learnerAssessmetsDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details/attempts`,
    Component: <Attempts />,
    key: "attemptsDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessments-list`,
    Component: <AssessmentCatlogList />,
    key: "assessmentCatlogList",
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/scheduled-assessments`,
    Component: <ScheduledAssessments />,
    key: "scheduledAssessmentsList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/domain`,
    Component: <DomainPage />,
    key: "domainList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/competency`,
    Component: <CompetencyPage />,
    key: "competencyList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/learning-outcome-list`,
    Component: <LearningOutcomePage />,
    key: "loList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/learning-outcome-list/add-learning-outcome`,
    Component: <LOForm />,
    key: "addMasterLoDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/learning-outcome-list/edit-learning-outcome`,
    Component: <LOForm />,
    key: "addMasterLoDetails",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/industry`,
    Component: <IndustryPage />,
    key: "industryList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/job-role`,
    Component: <JobRolePage />,
    key: "jobRoleList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/classroom`,
    Component: <ClassRoomPage />,
    key: "classRoomList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/region`,
    Component: <RegionPage />,
    key: "regionList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/location`,
    Component: <LocationPage />,
    key: "locationList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/sub-location`,
    Component: <SubLocationPage />,
    key: "locationList",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring`,
    Component: <ManageMentoring />,
    key: "manageMentoring",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor`,
    Component: <MentorPage />,
    key: "iamMentor",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentee`,
    Component: <MenteePage />,
    key: "iamMentee",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/map-mentor-mentee`,
    Component: <MapMentorAndMenteeForm />,
    key: "manageMentoring",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/replace-mentor`,
    Component: <ReplaceMentor />,
    key: "manageMentoring",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/mentor-details`,
    Component: <MentorDetails />,
    key: "manageMentoring",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/mentoring-details/session-feedback`,
    Component: <MenteeSessionFeedbackForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/session-details`,
    Component: <SessionDetailPage />,
    key: "iamMentor",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/mentee-details`,
    Component: <MenteeDetailsPage />,
    key: "iamMentor",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/instruction`,
    Component: <InstructionPage />,
    key: "iamMentor",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`,
    Component: <AddSessionForm />,
    key: "iamMentor",
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentee/mentor-details-page`,
    Component: <MentorDetailsPage />,
    key: "iamMentee",
  },
  {
    path: `${process.env.PUBLIC_URL}/load-factor`,
    Component: <LoadFactor />,
    key: "loadFactorList",
  },
  {
    path: `${process.env.PUBLIC_URL}/modify-plan`,
    Component: <ModifyPlan />,
    key: "loadFactorList",
  },
  {
    path: `${process.env.PUBLIC_URL}/people`,
    Component: <ViewPeople />,
    key: "viewLoadFactorPeople",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-course`,
    Component: <ScheduleCourse />,
    key: "scheduleCourse",
  },
  {
    path: `${process.env.PUBLIC_URL}/course-schedule-list`,
    Component: <ScheduledCourseList />,
    key: "scheduledCourseList",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-details`,
    Component: <ScheduleDetails />,
    key: "scheduledCourseList",
  },
  {
    path: `${process.env.PUBLIC_URL}/add-participant`,
    Component: <AddNominees />,
    key: "addParticipantToSchedule",
  },
  {
    path: `${process.env.PUBLIC_URL}/rating-summary`,
    Component: <RatingSummary />,
  },
  {
    path: `${process.env.PUBLIC_URL}/global-setting`,
    Component: <GlobalSettings />,
    key: "globalSettings",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details`,
    Component: <LearnerScheduleDetails />,
    key: "learnerScheduleList",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list`,
    Component: <LearnerScheduleList />,
    key: "learnerScheduleList",
  },
  {
    path: `${process.env.PUBLIC_URL}/course-catalog`,
    Component: <CourseCatalog />,
    key: "courseCatlogList",
  },
  {
    path: `${process.env.PUBLIC_URL}/course-catalog/course-details`,
    Component: <CourseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-learning-plan/course-details`,
    Component: <CourseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/course-feedback`,
    Component: <CourseFeedback />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/module-feedback`,
    Component: <ModuleFeedback />,
    key: "learnerScheduleList",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/rate-now`,
    Component: <RateNow />,
    key: "learnerScheduleList",
  },
  {
    path: `${process.env.PUBLIC_URL}/nomination-request`,
    Component: <NominationRequest />,
    key: "nominationRequestList",
  },
  {
    path: `${process.env.PUBLIC_URL}/nomination-request/course-details`,
    Component: <NominationCourseDetails />,
    key: "nominationRequestList",
  },
  {
    path: `${process.env.PUBLIC_URL}/add-employee`,
    Component: <AddExternalUser />,
    key: "addExternalUser",
  },
  {
    path: `${process.env.PUBLIC_URL}/profile`,
    Component: <ProfileDetails />,
    key: "dashboard",
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-module-feedbacklist`,
    Component: <ModuleFeedbackList />,
    key: "scheduledCourseList",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/asset-management`,
    Component: <AssestManagement />,
    key: "assetManagement",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/asset-management/add-asset-management`,
    Component: <UpsertAssetManagementForm />,
    key: "assetManagement",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/asset-management/assets-list`,
    Component: <AssetsList />,
    key: "assetManagement",
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/asset-management/assets-history`,
    Component: <AssetHistory />,
    key: "assetManagement",
  },
  {
    path: `${process.env.PUBLIC_URL}/reports`,
    Component: <Reports />,
    key: "reports",
  },
];

export default routes;
