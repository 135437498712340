import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Col, FormGroup, Row } from "reactstrap";

import { Btn, P } from "../../../../../../AbstractElements";
import { LEARNER_STATUS, PUBLISHED } from "../../../../../../Api/constants";
import BorderTabs from "../../../../../../CommonElements/BorderTabs";
import Divider from "../../../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import MultiSelectDropdown from "../../../../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../../../../CommonElements/SearchDropDownPaginator";
import VerticalTabs from "../../../../../../CommonElements/VerticalTabs";
import EmployeeList from "../../../../../../container/EmployeeList";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  bulkUploadUsers,
  getAssessmentCatlogList,
  getParticipantByScheduleId,
  getScheduledByAssessmentId,
} from "../../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  getFilterRolesList,
  getSbuList,
  getUserMasterList,
  setUserMasterListToInitial,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import { dynamicImage } from "../../../../../../Service";
import {
  Download,
  NewUsers,
  NewUsersCount,
  ExistingUsers,
  ExistingUsersCount,
  SBU,
  ScheduleParticipantToolTipMessage,
  ScheduleUploadFileToolTipMessage,
  SearchForUsersOrGroups,
  Select,
  Upload,
  UploadFile,
  Users,
  UseThisTemplate,
  JobRole,
  Assessment,
  Schedules,
  SearchForSchedules,
  CopyAssessmentToolTipMessage,
  SchedulesToolTipMessage,
} from "../../../../../../utils/Constant";
import {
  customStyles,
  formatCustomDate,
  participantFormTabs,
  participantTypesTab,
} from "../../../../../../utils/helper/helper";

const ParticipantForm = ({
  userCardDetailIds,
  setUserCardDetailIds,
  enrolledUsers,
  setEnrolledUsers,
  enrolledUsersIds,
  setEnrolledUsersIds,
  setDeletedIds,
  deletedIds,
  type,
  urlList,
}) => {
  const dispatch = useAppDispatch();
  const [flushData, setFlushData] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("select_users");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const { roleOnFilter, sbuList, userMasterList } = useAppSelector(
    state => state.master
  );
  const [uploadFile, setUploadFile] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [roleFilterList, setRoleFilterList] = useState([]);
  const [sbuFilterList, setSbuFilterList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [preSelectedUsers, setPreSelectedUsers] = useState([]);
  const [preSelectedSchedules, setPreSelectedSchedules] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [basicTab, setBasicTab] = useState("enrolling_users");
  const [userCardDetail, setUserCardDetail] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [participantTypes, setParticipantTypes] = useState(participantTypesTab);
  const [assesmentCatlog, setAssesmentCatlog] = useState([]);
  const { assessmentCatlogList } = useAppSelector(
    state => state.assessmentCatlog
  );

  useEffect(() => {
    dispatch(
      getAssessmentCatlogList({
        status: PUBLISHED,
        assessment_type: ["standalone,sga"],
        tag: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const list = [{ label: Select + " " + Assessment, value: "" }];
    if (assessmentCatlogList?.assessments) {
      assessmentCatlogList?.assessments?.forEach(item => {
        list.push({ label: item?.name, value: item?.id?.toString() });
      });
    }
    setAssesmentCatlog(list);
  }, [assessmentCatlogList]);

  useEffect(() => {
    dispatch(
      getUserMasterList({
        roleId: null,
        sbuId: null,
        type: LEARNER_STATUS?.toString(),
      })
    );
    setFlushData(false);
    setFlushData(true);
    setFlushData(false);
    setUserCardDetail([]);
    setUserCardDetailIds([]);
    dispatch(getFilterRolesList());
    dispatch(getSbuList());
  }, [dispatch]);

  useEffect(() => {
    const tab: any = [...participantTypes];
    if (userCardDetail?.length > 0)
      tab[0].label = NewUsers + ` (${userCardDetail?.length})`;
    else tab[0].label = NewUsersCount;
    if (enrolledUsers?.length > 0)
      tab[1].label = ExistingUsers + ` (${enrolledUsers?.length})`;
    else tab[1].label = ExistingUsersCount;
    setParticipantTypes(tab);
  }, [userCardDetail, enrolledUsers]);

  useEffect(() => {
    const value = [];
    const nameMap = {};
    if (userMasterList) {
      userMasterList?.users?.forEach((item: any) => {
        nameMap[item?.id] = item;
        value.push({
          name: item?.id,
          label: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
          value: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
        });
      });
    }
    setUserMap(nameMap);
    setUsersList(value);
  }, [userMasterList]);

  useEffect(() => {
    const value = [];
    if (roleOnFilter) {
      roleOnFilter?.job_role?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  useEffect(() => {
    const value = [];
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  const handleModuleTabClick = (tabId: any) => {
    setFlushData(false);
    setActiveTab(tabId);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["role"] || values["sbu"]) {
      dispatch(setUserMasterListToInitial());
      await dispatch(
        getUserMasterList({
          roleId: values["role"]?.toString(),
          sbuId: values["sbu"]?.toString(),
          type: LEARNER_STATUS?.toString(),
        })
      );
    }
  };

  const handleClear = async () => {
    setUsersList([]);
    setPreSelectedUsers([]);
    setPreSelectedSchedules([]);
    setSelectedAssessment("");
    setUserCardDetail([]);
    setUserCardDetailIds([]);
    await dispatch(
      getUserMasterList({
        roleId: null,
        sbuId: null,
        type: LEARNER_STATUS?.toString(),
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const setMultiDropdownData = (value: any[]) => {
    const usersMap = [];

    usersList?.forEach((element: any) => {
      if (value.includes(element.name)) {
        usersMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedUsers(usersMap);
  };

  const setScheduleMultiDropdownData = (value: any[]) => {
    const scheduleMap = [];

    scheduleList?.forEach((element: any) => {
      if (value.includes(element.name)) {
        scheduleMap.push({
          name: element.name,
          value: element.label,
          label: element.label,
        });
      }
    });
    setPreSelectedSchedules(scheduleMap);
  };

  const onUploadFile = async () => {
    const postData = new FormData();
    uploadFile.forEach(file => {
      postData.append("file", file[0]);
    });
    const ids = [];
    const enrolled = await dispatch(bulkUploadUsers({ file: postData }));
    const userData = [];
    if (enrolled?.payload?.users) {
      setFlushData(true);
      const existingUserIds = userCardDetail.map(user => user.id);
      enrolled?.payload?.users?.map(item => {
        if (
          !enrolledUsersIds.includes(item.id) &&
          !existingUserIds.includes(item.id)
        ) {
          ids.push(item?.id);
          userData.push({
            imagePath: dynamicImage("dashboard-2/user/2.png"),
            name: item.user_name,
            detail: "",
            empId: item?.employee_code,
            phoneNumber: item?.phone,
            id: item.id,
            sbuName: item?.sbu,
            rmName: item?.mapped_rm?.[0]?.name,
            rmPhone: item?.mapped_rm?.[0]?.rm_phone,
          });
        }
      });
    }
    setUploadFile([]);
    setUserCardDetailIds([...userCardDetailIds, ...ids]);
    setUserCardDetail([...userCardDetail, ...userData]);
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <BorderTabs
            tabs={participantFormTabs}
            activeTab={activeTab}
            handleTabClick={handleModuleTabClick}
            showCard={false}
          />
          {activeTab === "schedules" && (
            <Row className="mt-2">
              <Col>
                <LabelTooltip
                  label={Assessment}
                  tooltipText={CopyAssessmentToolTipMessage}
                  important={false}
                />
                <ReactSelect
                  name="assessment_id"
                  className="text-gray"
                  onChange={async selectedOption => {
                    setScheduleList([]);
                    setSelectedAssessment("");
                    if (selectedOption?.value) {
                      setSelectedAssessment(selectedOption.value);
                      const data: any = await dispatch(
                        getScheduledByAssessmentId({
                          id: Number(selectedOption.value),
                        })
                      );

                      const schedules = data?.payload?.schedules;
                      const options = [];
                      schedules?.forEach(item => {
                        options.push({
                          name: item?.id,
                          label:
                            formatCustomDate(item?.start_date) +
                            " - " +
                            formatCustomDate(item?.end_date),
                          value:
                            formatCustomDate(item?.start_date) +
                            " - " +
                            formatCustomDate(item?.end_date),
                        });
                      });
                      setScheduleList(options);
                    }
                  }}
                  value={
                    assesmentCatlog?.find(
                      option => option.value === selectedAssessment
                    ) || null
                  }
                  options={assesmentCatlog?.map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                  isSearchable
                  isClearable={false}
                  styles={customStyles}
                  components={{
                    MenuList: props => (
                      <SearchDropDownPaginator {...props} maxHeight={250} />
                    ),
                  }}
                  filterOption={(option, inputValue) =>
                    option?.label
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  }
                />
              </Col>
              <Col>
                <LabelTooltip
                  label={Schedules}
                  tooltipText={SchedulesToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  disabled={type === "past"}
                  onChange={setScheduleMultiDropdownData}
                  options={scheduleList}
                  placeholder={SearchForSchedules}
                  defaultSelected={preSelectedSchedules}
                  onBlurDropdown={async values => {
                    if (values?.length > 0) {
                      const filterValues = values
                        ?.map(item => item?.name)
                        ?.join(",");
                      const data: any = await dispatch(
                        getParticipantByScheduleId({ id: filterValues })
                      );

                      const ids = [];
                      const userData = [];
                      if (data?.payload?.participants) {
                        const existingUserIds = userCardDetail?.map(
                          user => user.id
                        );
                        data?.payload?.participants?.map(item => {
                          if (
                            !enrolledUsersIds.includes(item) &&
                            !existingUserIds.includes(item)
                          ) {
                            ids.push(item);
                            userData.push({
                              imagePath: dynamicImage("dashboard-2/user/2.png"),
                              name: userMap?.[item]?.user_name,
                              detail: "",
                              empId: userMap?.[item]?.employee_code,
                              phoneNumber: userMap?.[item]?.phone,
                              id: item,
                              sbuName: userMap?.[item]?.sbu,
                              rmName: userMap?.[item]?.mapped_rm?.[0]?.name,
                              rmPhone:
                                userMap?.[item]?.mapped_rm?.[0]?.rm_phone,
                            });
                          }
                        });
                      }
                      setUploadFile([]);
                      setUserCardDetailIds([...userCardDetailIds, ...ids]);
                      setUserCardDetail([...userCardDetail, ...userData]);
                      setSelectedAssessment("");
                      setPreSelectedSchedules([]);
                      setScheduleList([]);
                    }
                  }}
                />
              </Col>
            </Row>
          )}
          {activeTab === "manage_enrollments" && (
            <div className="mt-3">
              <LabelTooltip
                label={UploadFile}
                tooltipText={ScheduleUploadFileToolTipMessage}
                important
              />
              <div className="d-flex gap-4">
                <P>{UseThisTemplate}</P>
                <a
                  href={urlList?.data?.templates?.email_template || ""}
                  download
                >
                  <P className="primary-text-color">{Download}</P>
                </a>
              </div>
              <MultiFileUpload
                flushData={flushData}
                accept=".csv"
                multiple={false}
                onFileChange={(file, status) => {
                  if (flushData) {
                    setFlushData(false);
                  }
                  if (status === "removed") {
                    if (uploadFile)
                      setUploadFile(prevDocuments =>
                        prevDocuments.filter(item => item[0].name !== file.name)
                      );
                  } else if (status === "done" && file) {
                    setUploadFile(prevDocuments => [
                      ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                      [file],
                    ]);
                  }
                }}
              />
              {uploadFile?.length > 0 && (
                <div className="mt-3">
                  {" "}
                  <Btn color="primary" onClick={onUploadFile}>
                    {Upload}
                  </Btn>
                </div>
              )}
            </div>
          )}
          {activeTab === "select_users" && (
            <Row className="mt-3">
              <Col lg="6">
                <LabelTooltip
                  label={Users}
                  tooltipText={ScheduleParticipantToolTipMessage}
                  important={false}
                />

                <MultiSelectDropdown
                  disabled={type === "past"}
                  onChange={setMultiDropdownData}
                  options={usersList?.filter(
                    item =>
                      !userCardDetailIds.includes(item?.name) &&
                      !enrolledUsersIds.includes(item?.name)
                  )}
                  placeholder={SearchForUsersOrGroups}
                  defaultSelected={preSelectedUsers}
                  onBlurDropdown={values => {
                    const selected = [];
                    values?.forEach(item => {
                      selected.push(item?.name);
                    });

                    const userData = [];
                    const userIds = [];
                    userMasterList?.users?.forEach((element: any) => {
                      if (selected.includes(element.id)) {
                        userIds.push(element.id);
                        userData.push({
                          imagePath: dynamicImage("dashboard-2/user/2.png"),
                          name: element.user_name,
                          detail: "",
                          empId: element?.employee_code,
                          phoneNumber: element?.phone,
                          id: element.id,
                          sbuName: element?.sbu,
                          rmName: element?.mapped_rm?.[0]?.name,
                          rmPhone: element?.mapped_rm?.[0]?.rm_phone,
                        });
                      }
                    });
                    setUserCardDetailIds([...userCardDetailIds, ...userIds]);
                    setUserCardDetail([...userCardDetail, ...userData]);
                    setPreSelectedUsers([]);
                  }}
                />
              </Col>
              <Col lg="1">
                <div className="filter-margin">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: JobRole,
                        key: "role",
                        tooltipText: `${Select} ${JobRole}`,
                        options: roleFilterList,
                        isMultiSelect: true,
                      },
                      {
                        label: SBU,
                        key: "sbu",
                        tooltipText: `${Select} ${SBU}`,
                        options: sbuFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Divider />
          <Row className="mt-4">
            <Col lg="2">
              <VerticalTabs
                tabItems={participantTypes}
                activeTab={basicTab}
                setActiveTab={setBasicTab}
              />
            </Col>
            <Col>
              {basicTab === "enrolling_users" && (
                <EmployeeList
                  onParticipantDelete={id => {
                    const updatedIds = userCardDetailIds?.filter(
                      item => item !== id
                    );
                    setUserCardDetailIds(updatedIds);

                    const updatedUsers = userCardDetail.filter(
                      user => user.id !== id
                    );
                    setUserCardDetail(updatedUsers);
                  }}
                  userCardWithDetail={userCardDetail}
                  showCard={false}
                  deleteParticipant={type !== "past"}
                />
              )}
              {basicTab !== "enrolling_users" && (
                <EmployeeList
                  onParticipantDelete={id => {
                    const deletedId = enrolledUsers.find(
                      user => user.id === id
                    )?.delete_id;

                    setDeletedIds([...deletedIds, deletedId]);
                    const updatedIds = enrolledUsersIds?.filter(
                      item => item !== id
                    );
                    setEnrolledUsersIds(updatedIds);

                    const updatedUsers = enrolledUsers.filter(
                      user => user.id !== id
                    );
                    setEnrolledUsers(updatedUsers);
                  }}
                  userCardWithDetail={enrolledUsers}
                  showCard={false}
                  deleteParticipant={type !== "past"}
                />
              )}
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};

ParticipantForm.propTypes = {
  userCardDetailIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUserCardDetailIds: PropTypes.func.isRequired,
  enrolledUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      detail: PropTypes.string,
      imagePath: PropTypes.string,
    })
  ).isRequired,
  setEnrolledUsers: PropTypes.func.isRequired,
  enrolledUsersIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setEnrolledUsersIds: PropTypes.func.isRequired,
  setDeletedIds: PropTypes.func.isRequired,
  deletedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
  urlList: PropTypes.any,
};

export default ParticipantForm;
