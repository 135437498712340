import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { H4, MUIIcons, P } from "../../../AbstractElements";
import { WBT } from "../../../Api/constants";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import LearningPlanCourseCard from "../../../container/LearningPlanCourseCard";
import NoData from "../../../container/NoData";
import NominationListTable from "../../../container/NominationListTable";
import ProjectDescription from "../../../container/ProjectDescription";
import RmReplacementCard from "../../../container/RmReplacementCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getNominationList,
  getScheduleDetails,
  reviewTrainerOrNominee,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { getClassRoomList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddComment,
  Approve,
  Cancel,
  Confirm,
  Confirmation,
  CourseID,
  CourseType,
  Days,
  Decline,
  Description,
  EmptyCommentsAlert,
  Enter,
  Feedback,
  Hours,
  KnowledgeAssessment,
  LearnerDocument,
  LearningOutcomes,
  Minutes,
  MoreActions,
  NominationListEmptyDescription,
  NotYetAdded,
  OJTAssessment,
  PleaseSelectReason,
  PreCourseWorkHeader,
  Rating,
  Reason,
  Replace,
  RequestSession,
  Select,
  ThisCourseIncludes,
  TrainerDocument,
  WhatYouWillLearnInThisCourse,
} from "../../../utils/Constant";
import {
  convertMinutesToDaysHoursMinutes,
  declineNomineeRMReasons,
  nominationDetailsTabs,
  participantDetailsTabs,
  showToast,
} from "../../../utils/helper/helper";

import "./style.scss";

type ValidMUIIcons =
  | "CalendarTodayOutlined"
  | "HelpOutline"
  | "SignalCellularAltOutlined"
  | "PlaceOutlined"
  | "PeopleAltOutlined"
  | "PersonOutlineOutlined"
  | "FileCopyOutlined"
  | "TextSnippetOutlined"
  | "EmojiEventsOutlined"
  | "AccessTimeOutlined";

const NominationCourseDetails = () => {
  const [courseTab, setCourseTab] = useState("details");
  const [participantTab, setParticipantTab] = useState("attendees");
  const [replacementListData, setReplacementListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedModalType, setSelectedModalType] = useState("");

  const [userNameMap, setUserNameMap] = useState({});

  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const schedule_id = params.get("schedule_id");
  const course_id = params.get("course_id");
  const tab = params.get("tab");
  const sub_tab = params.get("sub_tab");

  const [classRoomNameMap, setClassRoomNameMap] = useState({});

  const { scheduledDetails, nominationList } = useAppSelector(
    state => state.course
  );
  const { usersList } = useAppSelector(state => state.user);
  const { classRoomList } = useAppSelector(state => state.master);

  const { course_detail, learning_outcome } = scheduledDetails;
  const navigate = useNavigate();
  const [comments, setComments] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    dispatch(getClassRoomList({}));
    if (tab) {
      handleTabClick(tab);
    }

    if (sub_tab) {
      handleParticipantTabClick(sub_tab);
    }
  }, []);

  useEffect(() => {
    const classRoomMapObj: { [key: string]: { name: string } } = {};
    classRoomList?.class_room?.forEach(location => {
      classRoomMapObj[location.id] = location;
    });
    setClassRoomNameMap(classRoomMapObj);
  }, [classRoomList]);

  useEffect(() => {
    dispatch(
      getScheduleDetails({
        courseScheduleId: schedule_id,
      })
    );
  }, []);

  useEffect(() => {
    if (nominationList?.nominees?.length > 0) {
      const modifiedData = nominationList?.nominees.map(item => ({
        id: item.id,
        oldName: userNameMap
          ? userNameMap[item?.participant_id]?.user_name
          : "-",
        newName: userNameMap
          ? userNameMap[item?.replacement_user_id]?.user_name
          : "-",
      }));
      setReplacementListData(modifiedData);
    } else {
      setReplacementListData([]);
    }
  }, [nominationList, userNameMap]);

  useEffect(() => {
    const userMapObj: { [key: string]: { user_name: string } } = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  const getScheduleList = tabStatus => {
    dispatch(
      getNominationList({
        type: tabStatus,
        course_id: course_detail?.id || course_id,
      })
    );
  };

  const courseInlcudes = () => {
    const courseIncludes: { icon: ValidMUIIcons | ""; value: string }[] = [];
    const overview = course_detail?.overview;
    const duration = convertMinutesToDaysHoursMinutes(overview?.duration);

    overview?.duration &&
      courseIncludes.push({
        icon: "AccessTimeOutlined",
        value:
          course_detail?.course_type === WBT
            ? `${duration.days} ${Days} ${duration.hours} ${Hours} ${duration.minutes} ${Minutes}`
            : `${overview?.estimated_time} ${Days}`,
      });

    overview?.knowledge_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.knowledge_assessment_count} ${KnowledgeAssessment}`,
      });

    overview?.ojt_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.ojt_assessment_count} ${OJTAssessment}`,
      });

    overview?.learner_document_count &&
      courseIncludes.push({
        icon: "PlaceOutlined",
        value: `${overview.learner_document_count} ${LearnerDocument}`,
      });

    overview?.trainer_document_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.trainer_document_count} ${TrainerDocument}`,
      });

    overview?.learning_outcome_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.learning_outcome_count} ${LearningOutcomes}`,
      });

    return courseIncludes;
  };

  const getIconName = (icon: ValidMUIIcons | ""): ValidMUIIcons => {
    return icon || "HelpOutline";
  };

  const transformedData = course_detail?.pre_course_work?.map(item => ({
    courseId: item.id.toString(),
    status: item.status,
    courseName: item.name,
    domainName: [],
    completedOn: null,
    scheduledOn: null,
    assignedBy: null,
    courseType: null,
  }));

  const handleTabClick = (tabId: any) => {
    setCourseTab(tabId);
    if (tabId === "participant" || tabId === "upcoming") {
      getScheduleList(participantTab);
    }
  };

  const handleParticipantTabClick = (tabId: any) => {
    setParticipantTab(tabId);
    getScheduleList(tabId);
  };

  const handleReject = row => {
    setSelectedId(row);
    setSelectedModalType("reject");
    toggleModal();
  };

  const handleCancel = row => {
    setSelectedId(row);
    setSelectedModalType("remove");
    toggleModal();
  };

  const handleApprove = row => {
    setSelectedId(row);
    setSelectedModalType("approve");
    toggleModal();
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
      setComments("");
      setSelectedReason("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleReplace = row => {
    return navigate("/add-participant", {
      state: {
        replace_id: row.nomination_id,
        participant_id: row.participant_id,
        replace_name: userNameMap
          ? userNameMap[row?.primary_trainer_id]?.user_name
          : "-",
        scheduledId: row.schedule_id,
        courseId: row.course_id,
        courseName: row.course_name,
        isFromNomination: true,
      },
    });
  };

  const handleRating = row => {
    return navigate("/rating-summary", {
      state: {
        courseFeedbackId: null,
        courseName: row.course_name,
        startDate: row.course_start_date,
        endDate: row.course_end_date,
        locationName: row?.location_name || "-",
        classRoomName: classRoomNameMap[row.classroom_id]?.name || "-",
        instructorId: row.primary_trainer_id,
        statusData: [],
        scheduledId: row.schedule_id,
        participant_id: row.participant_id,
        rm: true,
      },
    });
  };

  const handleFeedback = row => {
    return navigate("/schedule-list/course-details/course-feedback", {
      state: {
        ...row,
        tranierName: userNameMap[row.primary_trainer_id]?.user_name,
        course_schedule_id: row.schedule_id,
        location_name: row?.location_name || "-",
        review: true,
      },
    });
  };

  const actions = [
    {
      type: "icon",
      icon: "CancelOutlined",
      label: Decline,
      onClick: handleReject,
      className: "text-primary",
      isHide: participantTab === "attendees" || participantTab === "upcoming",
    },
    {
      type: "icon",
      icon: "CheckCircleOutlined",
      label: Approve,
      onClick: handleApprove,
      className: "text-danger",
      isHide: participantTab === "attendees" || participantTab === "upcoming",
    },
    {
      type: "dropdown",
      label: MoreActions,
      isHide: courseTab === "upcoming",
      dropdownOptions: [
        {
          label: Replace,
          onClick: handleReplace,
          isHide: participantTab === "nomination",
        },
        {
          label: Cancel,
          onClick: handleCancel,
        },
        {
          label: Rating,
          onClick: handleRating,
          isHide: participantTab === "nomination",
        },
        {
          label: Feedback,
          onClick: handleFeedback,
          isHide: participantTab === "nomination",
        },
      ],
    },
  ];

  const handlePrimaryBtnClick = async () => {
    if (
      ["reject", "remove"].includes(selectedModalType) &&
      selectedReason === ""
    ) {
      showToast(PleaseSelectReason, "error");
      return;
    }
    switch (selectedModalType) {
      case "approve":
      case "reject":
      case "remove":
        if (
          selectedReason === "others_please_specify" ||
          selectedModalType === "approve"
        ) {
          if (!comments || comments.trim() === "") {
            showToast(EmptyCommentsAlert, "error");
            return;
          }
        }
        break;
    }

    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "nominee",
        id: selectedId?.nomination_id?.toString(),
        other_reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "nominee",
        id: selectedId?.nomination_id?.toString(),
        reason: selectedReason,
        other_reason:
          selectedReason === "others_please_specify" ? comments : "",
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "remove") {
      payload = {
        action: "removed",
        type: "nominee",
        id: selectedId?.nomination_id?.toString(),
        reason: selectedReason,
        other_reason:
          selectedReason === "others_please_specify" ? comments : "",
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    }

    await dispatch(
      getNominationList({ type: participantTab, course_id: course_detail.id })
    );

    toggleModal();
  };

  const renderModalBody = () => {
    return (
      <>
        {(selectedModalType === "reject" || selectedModalType === "remove") && (
          <>
            <LabelTooltip
              label={Reason}
              tooltipText={`${Select} ${Reason}`}
              important
            />
            <Input
              type="select"
              id="reason-dropdown"
              className="mb-2"
              value={selectedReason}
              onChange={e => {
                setSelectedReason(e.target.value);
              }}
            >
              <option value="" disabled>
                {`${Select} ${Reason}`}
              </option>
              {declineNomineeRMReasons?.map(reason => (
                <option key={reason.value} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </Input>
          </>
        )}
        {(selectedModalType === "approve" ||
          ((selectedModalType === "reject" || selectedModalType === "remove") &&
            selectedReason === "others_please_specify")) && (
          <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value.length <= 255) ||
                  e.target.value === ""
                ) {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
        )}
      </>
    );
  };

  const onHandleClick = () => {
    return;
  };

  return (
    <div className="page-body schedule-details">
      <Row>
        <Col md="8" sm="4">
          <ProjectDescription
            title={course_detail?.course_name}
            description={course_detail?.description}
            courseValues={[
              {
                label: CourseType,
                value: course_detail?.course_type,
                id: CourseType,
              },
              {
                label: CourseID,
                value: course_detail?.course_id,
                id: CourseID,
              },
            ]}
            scheduledOn={course_detail?.schedule_details?.start_date}
          />

          <Card className="mt-3">
            <CardBody>
              <JustifyTabs
                tabs={nominationDetailsTabs}
                activeTab={courseTab}
                onTabClick={handleTabClick}
              />
            </CardBody>
          </Card>

          {courseTab === "details" && (
            <Card>
              <CardHeader>
                <H4>{WhatYouWillLearnInThisCourse}</H4>
              </CardHeader>
              <CardBody className="">
                {learning_outcome.map(item => (
                  <div
                    className="d-flex gap-2 mb-3 align-items-center"
                    key={item.id}
                  >
                    <MUIIcons
                      className="primary-icon-color"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.outcome}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseTab === "participant" && (
            <Card className="mt-3">
              <CardBody>
                <JustifyTabs
                  tabs={participantDetailsTabs}
                  activeTab={participantTab}
                  onTabClick={handleParticipantTabClick}
                />
              </CardBody>
            </Card>
          )}

          {courseTab === "upcoming" && nominationList.nominees.length === 0 && (
            <NoData
              showCard={false}
              showIcon={false}
              svg="empty-folder-icon"
              title={NotYetAdded}
              description={NominationListEmptyDescription}
              buttonText={RequestSession}
              onclick={onHandleClick}
            />
          )}

          {((courseTab === "participant" && participantTab !== "replacement") ||
            courseTab === "upcoming") &&
            nominationList?.nominees?.length > 0 && (
              <NominationListTable
                actions={actions}
                data={nominationList.nominees}
                handleCourseClick={() => {
                  return null;
                }}
              />
            )}
          {courseTab === "participant" && participantTab === "replacement" && (
            <RmReplacementCard data={replacementListData} />
          )}
        </Col>

        <Col md="4">
          <Card>
            <CardHeader>
              <H4>{ThisCourseIncludes}:</H4>
            </CardHeader>
            <CardBody className="schedule-details__course-includes">
              {courseInlcudes().map(item => (
                <div className="d-flex gap-2 mb-3" key={item.icon}>
                  <MUIIcons
                    className="course-description__calender"
                    size={18}
                    iconName={getIconName(item.icon)}
                  />
                  <P className="course-description__schedule-on m-0">
                    {item.value}
                  </P>
                </div>
              ))}
            </CardBody>
          </Card>

          {course_detail?.pre_course_work?.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{PreCourseWorkHeader}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                <LearningPlanCourseCard data={transformedData} />
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody()}
      </CommonModal>
    </div>
  );
};

export default NominationCourseDetails;
