import PropTypes from "prop-types";
import { useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import { H2 } from "../../../AbstractElements";
import CertificationListContainer from "../../../container/CertificationListContainer";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCertificationList } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  CertificationAcquired,
  NoDataText,
  TotalCertification,
} from "../../../utils/Constant";

const ILPCertification = ({ userId }) => {
  const { certificationsList, loading } = useAppSelector(
    state => state.learningPlan
  );
  const dispatch = useAppDispatch();
  const { roleList, proficiencyList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getRolesList({}));
    dispatch(getProficiencyList());
    dispatch(getCertificationList());
  }, [userId]);

  const getRoleNameById = (id: number) => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "";
  };

  const getLevelNameById = (id: number) => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const renderCertificationListContainer = () => {
    if (loading) {
      return null;
    }

    if (certificationsList?.certifications?.length === 0) {
      return (
        <NoData svg="empty-folder-icon" title={NoDataText} showCard={false} />
      );
    }
    return certificationsList?.certifications?.map((item, index) => (
      <CertificationListContainer
        key={`${index}${item.assessment_id}`}
        uniqueKey={`${index}${item.assessment_id}`}
        title={
          item.assessment_name ||
          getRoleNameById(item?.role_id) +
            " - " +
            getLevelNameById(item?.level_id)
        }
        certifiedOn={item.issued_at}
        expiredOn={item.expires_at}
        id={item.id}
      />
    ));
  };

  return (
    <div>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex flex-row gap-4 align-items-center">
            <H2 className="text-black">{CertificationAcquired}</H2>
            <p className="text-gray d-flex gap-2">
              {TotalCertification} :
              <strong className="text-black">
                {certificationsList?.certifications?.length}
              </strong>
            </p>
          </div>
          <div className="mt-4">{renderCertificationListContainer()}</div>
        </CardBody>
      </Card>
    </div>
  );
};

ILPCertification.propTypes = {
  userId: PropTypes.number,
};
export default ILPCertification;
