import PropTypes from "prop-types";
import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { CardView, ListView } from "../../utils/Constant";
import MUIIcons from "../MUIIcon/MUIIcons";

import "./style.scss";

interface ViewModeDropdownProps {
  dropdownOpen: boolean;
  toggleDropdown: () => void;
  onChange: (view: "card" | "table") => void;
}

const ViewModeDropdown: React.FC<ViewModeDropdownProps> = ({
  dropdownOpen,
  toggleDropdown,
  onChange,
}) => {
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="view-dropdown mt-2"
    >
      <DropdownToggle color="" className="grid-icon">
        <MUIIcons
          iconName="GridViewOutlined"
          className="primary-icon-color"
          size={24}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          onClick={() => {
            onChange("card");
          }}
          className="d-flex align-items-center"
        >
          <MUIIcons
            iconName="ViewModuleOutlined"
            className="me-2 primary-icon-color"
            size={18}
          />
          {CardView}
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            onChange("table");
          }}
          className="d-flex align-items-center"
        >
          <MUIIcons
            iconName="ViewListOutlined"
            className="me-2 primary-icon-color"
            size={18}
          />
          {ListView}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

ViewModeDropdown.propTypes = {
  dropdownOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func,
  onChange: PropTypes.func,
};

export default ViewModeDropdown;
