import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";

import {
  Badges,
  Btn,
  DefaultButton,
  H4,
  MUIIcons,
  P,
  Progressbar,
  SecondaryButton,
} from "../../../AbstractElements";
import {
  ASSESSMENT_POST_PHASE,
  ASSESSMENT_PRE_PHASE,
  DRAFT_STATUS,
  EXTERNAL_LEARNER,
  INTERNAL_LEARNER,
  WBT,
  WEBINAR,
  YtsStatus,
  listStatusMapping,
} from "../../../Api/constants";
import SimpleAccordion from "../../../CommonElements/Accordion";
import BorderTabs from "../../../CommonElements/BorderTabs";
import CourseActivity from "../../../CommonElements/CourseActivity";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../CommonElements/MultiFileUpload/MultiFileUpload";
import LearningPlanCourseCard from "../../../container/LearningPlanCourseCard";
import PlayCourseMaterial from "../../../container/PlayCourseMaterial";
import ProjectDescription from "../../../container/ProjectDescription";
import ScheduleListContainer from "../../../container/ScheduleListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  downloadCertificate,
  getCourseActivity,
  getScheduleCourse,
  getScheduleDetails,
  getSkillAssessment,
  mapScheduleCourse,
  setCourseActivityDetailsToNull,
  setCourseSkillAssessmentDetailsToNull,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { getCourseModuleFeedabck } from "../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  getLOForAssessment,
  uploadSkillDocs,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Reducers/LoaderSlice";
import { uploadFileApi } from "../../../ReduxToolkit/Reducers/UserSlice";
import Store from "../../../ReduxToolkit/Store";
import {
  ActivityWorkbook,
  AddDocument,
  AssetStartDateToolTipMessage,
  BatchSize,
  Cancel,
  Completed,
  CourseDuration,
  CourseFeedbackForm,
  CourseID,
  CourseIsAlreadyMapped,
  CourseName,
  CoursePeriod,
  CourseType,
  Days,
  DesignedToEvaluateYourFoundational,
  Download,
  EnableLastDayOfCourse,
  EnrollMe,
  Enter,
  FailedToDownloadFileFrom,
  FormativeAssessment,
  Hours,
  Instructor,
  KnowledgeAssessment,
  LearnerDocument,
  LearningOutcomes,
  Location,
  LocationAlphanumeric,
  LocationRequired,
  MachineSerialNumber,
  MachineSerialNumberAlphanumeric,
  MachineSerialNumberRequired,
  MachineSerialNumberToolTipMessage,
  MailMe,
  MailMeAddToILP,
  Material,
  Minutes,
  ModuleImprovementForm,
  NominationPeriod,
  OJTAssessment,
  PleaseTakeLast,
  PleaseTakePreCourse,
  PleaseTakePreTest,
  PostTest,
  PreCourseWorkHeader,
  PreTest,
  ProficiencyLevel,
  RateNow,
  Resources,
  Resume,
  Retake,
  ReviewHasBeenSubmitted,
  Room,
  Select,
  SideBarTitleLocation,
  SkillAssessment,
  Start,
  StartDate,
  StartDateRequired,
  ThisCourseIncludes,
  TrainerDocument,
  TrainerName,
  UnderReview,
  Upload,
  UploadFile,
  WelcomeToPrerequisite,
  WhatYouWillLearnInThisCourse,
  WorkOrderNumber,
  WorkOrderNumberAlphanumeric,
  WorkOrderNumberRequired,
  WorkOrderNumberToolTipMessage,
  YetToStart,
  YourFileFileSizeNote,
  launchWebinar,
} from "../../../utils/Constant";
import "./style.scss";
import {
  assessmentStatus,
  convertMinutesToDaysHoursMinutes,
  extractFileName,
  formatCustomDate,
  getEllipsedFileName,
  sanitizeString,
  scheduledTabs,
  showToast,
  skillAssessmentTabs,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";

type ValidMUIIcons =
  | "CalendarTodayOutlined"
  | "HelpOutline"
  | "SignalCellularAltOutlined"
  | "PlaceOutlined"
  | "PeopleAltOutlined"
  | "PersonOutlineOutlined"
  | "FileCopyOutlined"
  | "TextSnippetOutlined"
  | "EmojiEventsOutlined"
  | "AccessTimeOutlined";

const LearnerScheduleDetails = () => {
  const [activeTab, setActiveTab] = useState("material");
  const [courseTab, setCourseTab] = useState("details");
  const [mediaFile, setMediaFile] = useState<any>("");
  const [flushData, setFlushData] = useState(false);
  const [skillAssessments, setSkillAssessments] = useState({
    percentage: 0,
  });
  const [isModal, setIsModal] = useState(false);
  const [activityData, setActivityData] = useState<any>([]);
  const [scheduledDetailTooltipOpen, setScheduledDetailTooltipOpen] = useState<
    number | null
  >(null);
  const [courseIncludesTooltipOpen, setCourseIncludesTooltipOpen] = useState<
    number | null
  >(null);
  const [uploadFileModal, setUploadFileModal] = useState({
    isOpen: false,
    key: null,
  });
  const [formValue, setFormValue] = useState(null);
  const [errors, setErrors] = useState(null);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const courseIdParam = params.get("course-id");
  const course_schedule_id = params.get("schedule_id");
  const course_id = params.get("course_id");

  const { scheduledDetails, courseActivity, skillDocuments } = useAppSelector(
    state => state.course
  );
  const currentDate = new Date().toISOString().slice(0, 10);
  const { loForAssessment } = useAppSelector(state => state.learningPlan);

  const { scheduleCourse } = useAppSelector(state => state.course);

  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  const { course_detail, course_materials, learning_outcome, user_dict } =
    scheduledDetails;

  useEffect(() => {
    dispatch(setCourseActivityDetailsToNull());
    dispatch(setCourseSkillAssessmentDetailsToNull());
    if (!courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseScheduleId: course_schedule_id,
          courseId: course_id,
        })
      );
      dispatch(getCourseActivity({ courseScheduleId: course_schedule_id }));
      dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
    }
  }, [courseIdParam]);

  useEffect(() => {
    dispatch(setCourseActivityDetailsToNull());
    dispatch(setCourseSkillAssessmentDetailsToNull());
    if (courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseId: Number(courseIdParam),
        })
      );
      dispatch(getCourseActivity({ courseScheduleId: course_schedule_id }));
      dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
    }
  }, [courseIdParam]);

  useEffect(() => {
    setActivityData([]);
    if (courseActivity.assessments.length > 0) {
      const updatedActivity = courseActivity.assessments?.map(item => {
        return {
          ...item,
        };
      });
      updatedActivity.push({
        name: ModuleImprovementForm,
        assessment_phase: "rateModule",
      });

      updatedActivity.push({
        name: CourseFeedbackForm,
        assessment_phase: "rateCourse",
      });

      if (course_materials?.length > 0) {
        updatedActivity.push({
          name: ActivityWorkbook,
          assessment_phase: "activity",
          status: "activity",
        });
      }

      setActivityData(updatedActivity);
    }
  }, [courseActivity, course_materials]);

  const getScheduleList = tabStatus => {
    dispatch(
      getScheduleCourse({
        status: tabStatus,
        course_id: course_id,
      })
    );
  };

  const extractDocumentsByCourseAssessment = skillDocument => {
    const result = {
      courseAssessmentIds: [],
      percentage: 0,
    };

    let approvedCount = 0;

    skillDocument.forEach(doc => {
      const courseAssessmentId = doc.course_assessment_id;

      if (!result[courseAssessmentId]) {
        result[courseAssessmentId] = {
          documents: [],
          uploaded_learner_document: [],
          id: doc.id,
        };
      }

      result.courseAssessmentIds.push(courseAssessmentId);

      if (doc.skill_document?.ojt_skill) {
        result[courseAssessmentId].documents.push(
          ...doc.skill_document.ojt_skill
        );
        result[courseAssessmentId].title = "OJT assessment";
      }

      if (doc.skill_document.field_skill?.learner_url) {
        result[courseAssessmentId].documents.push(
          ...doc.skill_document.field_skill.learner_url
        );
        result[courseAssessmentId].title = "Field assessment";
      }

      if (doc.uploaded_learner_document) {
        doc.uploaded_learner_document.forEach(url => {
          result[courseAssessmentId].uploaded_learner_document.push({
            file_name: extractFileName(url),
            file_type: "pdf",
            file_url: url,
            file_category: "uploaded_learner_document",
          });
        });
      }

      if (doc.status === "approved") {
        approvedCount++;
      }
    });

    const totalDocuments = skillDocument.length;
    result.percentage =
      totalDocuments > 0 ? (approvedCount / totalDocuments) * 100 : 0;

    return result;
  };

  useEffect(() => {
    setSkillAssessments({
      percentage: 0,
    });
    if (skillDocuments?.skill_document.length > 0) {
      const result = extractDocumentsByCourseAssessment(
        skillDocuments.skill_document
      );
      setSkillAssessments(result);
      dispatch(
        getLOForAssessment({ ids: result.courseAssessmentIds.toString() })
      );
    }
  }, [skillDocuments]);

  const getTrainer = trainers => {
    if (!trainers) {
      return {};
    }
    const firstTrainerId = user_dict?.[trainers[0].user_id];
    const remainingTrainers = trainers.slice(1);
    const remainingNames = remainingTrainers.map(trainer => {
      return user_dict?.[trainer.user_id]?.user_name + ", ";
    });
    return {
      userName: firstTrainerId?.user_name,
      userData: remainingNames,
    };
  };

  const courseData: {
    id: number;
    icon: ValidMUIIcons | "";
    value: string;
    iconName: string;
    extraData?: any;
    uniqueId?: any;
  }[] = [
    {
      id: 1,
      uniqueId: 7,
      icon: "AccessTimeOutlined",
      value:
        course_detail?.schedule_details?.start_date &&
        course_detail?.schedule_details?.end_date
          ? `${formatCustomDate(course_detail?.schedule_details?.start_date)} - ${formatCustomDate(course_detail?.schedule_details?.end_date)}`
          : "",
      iconName: CoursePeriod,
    },
    {
      id: 2,
      uniqueId: 8,
      icon: "CalendarTodayOutlined",
      value:
        course_detail?.schedule_details?.nomination_start_date &&
        course_detail?.schedule_details?.nomination_end_date
          ? `Nomination starts: ${formatCustomDate(course_detail?.schedule_details?.nomination_start_date)} - ${formatCustomDate(course_detail?.schedule_details?.nomination_end_date)}`
          : "",
      iconName: NominationPeriod,
    },
    {
      id: 3,
      uniqueId: 9,
      icon: "SignalCellularAltOutlined",
      value:
        course_detail?.curriculum?.length > 0
          ? course_detail?.curriculum
              .map(item => item.expertise_level)
              .filter((level, index, self) => self.indexOf(level) === index)
              .join(", ")
          : "",
      iconName: ProficiencyLevel,
    },
    {
      id: 4,
      uniqueId: 10,
      icon: "PlaceOutlined",
      value: course_detail?.schedule_details?.location_name || "",
      iconName: Location,
    },
    {
      id: 5,
      uniqueId: 11,
      icon: "PeopleAltOutlined",
      value:
        course_detail?.schedule_details?.batch_minimum_size &&
        course_detail?.schedule_details?.batch_maximum_size
          ? `${course_detail?.schedule_details?.batch_minimum_size} - ${course_detail?.schedule_details?.batch_maximum_size}`
          : "",
      iconName: BatchSize,
    },
  ];

  if (course_detail?.schedule_details?.trainer?.length > 0) {
    courseData.push({
      id: 6,
      uniqueId: 12,
      icon: "PersonOutlineOutlined",
      value: `${getTrainer(course_detail?.schedule_details?.trainer)?.userName}`,
      extraData: getTrainer(course_detail?.schedule_details?.trainer)?.userData,
      iconName: TrainerName,
    });
  }

  const courseInlcudes = () => {
    const courseIncludes: {
      id: number;
      icon: ValidMUIIcons | "";
      value: string;
      iconName: string;
    }[] = [];
    const overview = course_detail?.overview;
    const duration = convertMinutesToDaysHoursMinutes(overview?.duration);

    overview?.duration &&
      courseIncludes.push({
        id: 1,
        icon: "AccessTimeOutlined",
        iconName: CourseDuration,
        value:
          course_detail?.course_type === WBT
            ? `${duration.days} ${Days} ${duration.hours} ${Hours} ${duration.minutes} ${Minutes}`
            : `${overview?.estimated_time} ${Days}`,
      });

    overview?.knowledge_assessment_count &&
      courseIncludes.push({
        id: 2,
        icon: "FileCopyOutlined",
        value: `${overview.knowledge_assessment_count} ${KnowledgeAssessment}`,
        iconName: KnowledgeAssessment,
      });

    overview?.ojt_assessment_count &&
      courseIncludes.push({
        id: 3,
        icon: "TextSnippetOutlined",
        value: `${overview.ojt_assessment_count} ${OJTAssessment}`,
        iconName: SkillAssessment,
      });

    overview?.learner_document_count &&
      courseIncludes.push({
        id: 4,
        icon: "TextSnippetOutlined",
        value: `${overview.learner_document_count} ${LearnerDocument}`,
        iconName: LearnerDocument,
      });

    overview?.trainer_document_count &&
      courseIncludes.push({
        id: 5,
        icon: "TextSnippetOutlined",
        value: `${overview.trainer_document_count} ${TrainerDocument}`,
        iconName: TrainerDocument,
      });

    overview?.learning_outcome_count &&
      courseIncludes.push({
        id: 6,
        icon: "TextSnippetOutlined",
        value: `${overview.learning_outcome_count} ${LearningOutcomes}`,
        iconName: LearningOutcomes,
      });

    return courseIncludes;
  };

  const getIconName = (icon: ValidMUIIcons | ""): ValidMUIIcons => {
    return icon || "HelpOutline";
  };

  const downloadActivity = () => {
    const dowloadURLs = filterCourseMaterial()?.map(item => item.file_url);
    handleDownload(dowloadURLs);
  };

  const handleRateNow = async item => {
    if (item.assessment_phase === "rateCourse") {
      if (currentDate < course_detail?.schedule_details?.end_date) {
        return showToast(EnableLastDayOfCourse, "error");
      }

      const response = await dispatch(
        getCourseModuleFeedabck({
          type: "course",
          courseScheduleId: course_schedule_id,
        })
      );

      if (response?.payload?.questions?.length > 0) {
        return navigate("/schedule-list/course-details/course-feedback", {
          state: {
            ...location.state,
            tranierName:
              user_dict?.[location.state.primary_trainer_id]?.user_name || "-",
          },
        });
      } else {
        return showToast(ReviewHasBeenSubmitted, "error");
      }
    }

    return navigate("/schedule-list/course-details/module-feedback", {
      state: {
        ...location.state,
        tranierName:
          user_dict?.[location.state?.primary_trainer_id]?.user_name || "-",
      },
    });
  };

  const getActivityStatus = (item, handleClick) => {
    if (item.status === assessmentStatus.inProgress) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Resume}
        </button>
      );
    } else if (item.status === assessmentStatus.yetToStart) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Start}
        </button>
      );
    } else if (item.status === assessmentStatus.retake) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Retake}
        </button>
      );
    } else if (
      item.status === assessmentStatus.underReview ||
      item.status === DRAFT_STATUS
    ) {
      return <div className="primary-text-color">{UnderReview}</div>;
    } else if (
      item.status === assessmentStatus.passed ||
      item.status === assessmentStatus.failed ||
      ((item?.assessment_phase === "pre" ||
        item?.assessment_phase === "post") &&
        item.status === assessmentStatus.completed)
    ) {
      return (
        <div className="badge-approved-bg px-2 rounded-pill d-flex align-items-center gap-1">
          <MUIIcons className="icon" iconName="CheckOutlined" size={12} />
          {Completed}
        </div>
      );
    } else if (item.status === "activity") {
      return (
        <button
          onClick={downloadActivity}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Download}
        </button>
      );
    } else if (
      item?.assessment_phase !== "pre" &&
      item?.assessment_phase !== "post"
    ) {
      return (
        <button
          onClick={() => handleRateNow(item)}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {RateNow}
        </button>
      );
    }
  };

  const handlePrequiesitesClick = item => {
    setIsModal(false);
    return navigate(`/course-catalog/course-details?course_id=${item.id}`);
  };

  const getPrerequisiteStatusText = status => {
    if (status) {
      return listStatusMapping[status]?.message;
    }

    if (status === null) {
      return YetToStart;
    }
    return status;
  };

  const getPrerequisiteStatus = item => {
    if (item.status === "completed") {
      return (
        <div className="badge-approved-bg px-2 rounded-pill d-flex align-items-center gap-1">
          <MUIIcons className="icon" iconName="CheckOutlined" size={12} />
          {Completed}
        </div>
      );
    }

    return (
      <button
        onClick={() => handlePrequiesitesClick(item)}
        className="border-0 bg-transparent pointer primary-text-color"
      >
        {getPrerequisiteStatusText(item.status)}
      </button>
    );
  };
  const transformedData = course_detail?.pre_course_work?.map(item => ({
    courseId: item.id.toString(),
    status: item?.status === null ? YtsStatus : item.status,
    badgeTextColor: true,
    courseName: item.name,
    domainName: [],
    completedOn: null,
    scheduledOn: null,
    assignedBy: null,
    courseType: null,
    id: item.id,
  }));

  if (
    course_detail?.other_pre_course_works &&
    course_detail.other_pre_course_works.trim() !== ""
  ) {
    transformedData.push({
      id: null,
      courseId: null,
      badgeTextColor: true,
      status: "completed",
      courseName: course_detail.other_pre_course_works,
      domainName: [],
      completedOn: null,
      scheduledOn: null,
      assignedBy: null,
      courseType: null,
    });
  }

  const handleFileChange = (key, file, status) => {
    if (status === "removed") {
      setMediaFile(prevDocuments => ({
        ...prevDocuments,
        [key]: prevDocuments[key]?.filter(item => item[0].name !== file?.name),
      }));
    } else if (status === "done" && file) {
      setMediaFile(prevDocuments => ({
        ...prevDocuments,
        [key]: [
          ...(Array.isArray(prevDocuments[key]) ? prevDocuments[key] : []),
          [file],
        ],
      }));
    }
    setFlushData(false);
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file[0]);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handleUpload = async key => {
    await uploadFile(mediaFile[key]).then(async uploaded => {
      const payload = {
        action: "upload_document",
        skill_document: [
          {
            id: skillAssessments?.[key]?.id,
            uploaded_learner_document: uploaded,
            location: formValue?.location,
            machine_serial_number: formValue?.machineSerialNumber,
            start_date: formValue?.startDate,
            work_order_number: formValue?.workOrderNumber,
          },
        ],
      };
      const res = await dispatch(uploadSkillDocs({ docs: payload }));
      if (res) {
        setMediaFile(prevDocuments => ({
          ...prevDocuments,
          [key]: [],
        }));
        setFlushData(true);
        handleFileUploadModal();
        dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
      }
    });
  };

  const handleFileUploadModal = (key = null) => {
    setUploadFileModal(prevState => ({
      isOpen: !prevState.isOpen,
      key,
    }));

    if (!key) {
      setMediaFile("");
      setFormValue(null);
      setErrors(null);
    }
  };

  const getSkillAssessmentContent = () => {
    const data =
      loForAssessment &&
      Object.keys(loForAssessment?.data)?.map(key => {
        const course = loForAssessment.data[key];
        const firstOutcome = course.outcomes[0];
        return {
          id: key,
          icon: true,
          accordionHeading: (
            <div>
              <P>{firstOutcome.outcome}</P>
            </div>
          ),
          spanClass: "custom-skillUpdate-heading",
          bodyText: (
            <div>
              <P>{skillAssessments?.[key]?.title}</P>
              <BorderTabs
                tabs={skillAssessmentTabs}
                activeTab={activeTab}
                handleTabClick={tabId => setActiveTab(tabId)}
              />

              <CardBody>
                {activeTab === "material" &&
                  skillAssessments?.[key]?.documents?.map((item, index) => (
                    <Badges
                      color="light text-dark my-2"
                      key={item.file_name + index}
                    >
                      <div className="d-flex align-items-center">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span className="m-1">
                          {getEllipsedFileName(item.file_name)}
                        </span>
                        <MUIIcons
                          className="pointer"
                          onClick={() => handleDownload([item.file_url])}
                          size={24}
                          iconName="FileDownloadOutlined"
                        />
                      </div>
                    </Badges>
                  ))}

                {activeTab === "your_file" && (
                  <>
                    {skillAssessments?.[key]?.uploaded_learner_document?.map(
                      (item, index) => (
                        <Badges
                          color="light text-dark my-2"
                          key={item.file_name + index}
                        >
                          <div className="d-flex align-items-center">
                            <MUIIcons
                              size={24}
                              iconName="InsertDriveFileOutlined"
                            />
                            <span className="m-1">
                              {getEllipsedFileName(item.file_name)}
                            </span>
                            <MUIIcons
                              className="pointer"
                              onClick={() => handleDownload([item.file_url])}
                              size={24}
                              iconName="FileDownloadOutlined"
                            />
                          </div>
                        </Badges>
                      )
                    )}
                    <div className="mt-4">
                      <Btn
                        color="primary"
                        onClick={() => handleFileUploadModal(key)}
                      >
                        {UploadFile}
                      </Btn>
                    </div>
                  </>
                )}
              </CardBody>
            </div>
          ),
        };
      });

    return data;
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          Store.dispatch(showLoader());
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        } finally {
          Store.dispatch(hideLoader());
        }
      }
    }
  };

  const handleTabClick = (tabId: any) => {
    setCourseTab(tabId);
    if (tabId !== "details") {
      getScheduleList(tabId);
    }
  };

  const handleModal = () => {
    setIsModal(prevState => !prevState);
  };

  const isAllCompleted = () => {
    return scheduledDetails?.course_detail?.pre_course_work.every(
      item => item.status === "completed"
    );
  };

  const hasAnyNullElement = () => {
    return scheduledDetails?.course_detail?.pre_course_work.some(
      item => item.status === null
    );
  };

  const handleEnroll = () => {
    const payload = {
      type: "interested_course",
      user_id: loginData.id,
      id: [Number(course_id)],
    };
    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
    handleModal();
  };

  const handleMailAndEnroll = async (enroll = false) => {
    const notCompletedIds = scheduledDetails?.course_detail?.pre_course_work
      .filter(item => item.status === null)
      .map(item => item.id);

    if (enroll && notCompletedIds.length === 0) {
      showToast(CourseIsAlreadyMapped, "info");
      return;
    }

    const allIds = scheduledDetails?.course_detail?.pre_course_work.map(
      item => item.id
    );

    const payload = {
      type: enroll ? "mail_me_and_add_ilp" : "mail_me",
      user_id: loginData.id,
      id: enroll ? notCompletedIds : allIds,
      course_id: scheduledDetails?.course_detail?.id,
    };

    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
    handleModal();
  };

  const isOngoingOrNominationClosed = () => {
    return (
      course_detail?.schedule_details?.schedule_status === "ongoing" ||
      course_detail?.schedule_details?.schedule_status === "nomination_closed"
    );
  };

  const checkPreCourseWorkPassedOrFailed = (): boolean => {
    const allCompleted = course_detail?.pre_course_work?.every(
      work => work?.status === "completed"
    );

    return allCompleted;
  };

  const handlePreClick = item => {
    if (checkPreCourseWorkPassedOrFailed()) {
      if (item.status === assessmentStatus.inProgress) {
        return navigate("/learning-assessment", {
          state: {
            participantId: item?.participant_id,
            name: item?.name,
            scheduleCourse: true,
          },
        });
      }

      return navigate("/assessment-instruction", {
        state: {
          participantId: item?.participant_id,
          name: item?.name,
          termsAndCondition: item?.term_and_condition,
          scheduleCourse: true,
        },
      });
    } else {
      return showToast(PleaseTakePreCourse, "error");
    }
  };

  const checkPreAssessmentPassedOrFailed = (): boolean => {
    return courseActivity?.assessments?.some(
      assessment =>
        assessment.assessment_phase === "pre" &&
        (assessment.status === "passed" || assessment.status === "failed")
    );
  };

  const handlePostClick = item => {
    if (checkPreCourseWorkPassedOrFailed()) {
      if (checkPreAssessmentPassedOrFailed()) {
        if (currentDate !== course_detail.schedule_details?.end_date) {
          return showToast(PleaseTakeLast, "error");
        } else {
          if (item.status === assessmentStatus.inProgress) {
            return navigate("/learning-assessment", {
              state: {
                participantId: item?.participant_id,
                name: item?.name,
              },
            });
          }

          return navigate("/assessment-instruction", {
            state: {
              participantId: item?.participant_id,
              name: item?.name,
              termsAndCondition: item?.term_and_condition,
            },
          });
        }
      } else {
        return showToast(PleaseTakePreTest, "error");
      }
    } else {
      return showToast(PleaseTakePreCourse, "error");
    }
  };

  const handleReview = item => {
    return item;
  };

  const getAssessmentDetails = item => {
    switch (item.assessment_phase) {
      case ASSESSMENT_PRE_PHASE:
        return {
          name: `${PreTest}-${item.name}`,
          onClick: () => handlePreClick(item),
        };

      case ASSESSMENT_POST_PHASE:
        return {
          name: `${PostTest}-${item.name}`,
          onClick: () => handlePostClick(item),
        };

      case "":
      case null:
        return {
          name: `${FormativeAssessment}-${item.name}`,
          onClick: () => handlePreClick(item),
        };
      case "activity":
        return {
          name: item.name,
          onClick: downloadActivity,
        };
      case "rateModule":
      case "rateCourse":
        return {
          name: item.name,
          onClick: () => handleReview(item),
        };

      default:
        return {};
    }
  };

  const sortActivity = () => {
    const sorted = [...activityData].sort((a, b) => {
      const order = [
        ASSESSMENT_PRE_PHASE,
        "",
        null,
        "activity",
        "rateModule",
        "rateCourse",
        ASSESSMENT_POST_PHASE,
      ];
      const phaseA = a.assessment_phase || "";
      const phaseB = b.assessment_phase || "";

      return order.indexOf(phaseA) - order.indexOf(phaseB);
    });

    return sorted;
  };

  const handleDownloadCertificate = isParticipated => {
    const payload = {
      type: course_detail?.course_type,
      courseName: course_detail?.course_name,
    };
    if (course_detail.course_type === WBT) {
      payload["courseId"] = course_detail.id;
    } else {
      payload["courseScheduleId"] = course_schedule_id;
    }
    if (isParticipated) {
      payload["isParticipated"] = 1;
    }
    dispatch(downloadCertificate({ ...payload }));
  };

  const handleLaunchWebinar = () => {
    window.open(course_detail?.webinar_link, "_blank", "noopener,noreferrer");
  };

  const scheduledDetailsToggleTooltip = (id: number) => {
    setScheduledDetailTooltipOpen(prevState => (prevState === id ? null : id));
  };

  const courseIncludesToggleTooltip = (id: number) => {
    setCourseIncludesTooltipOpen(prevState => (prevState === id ? null : id));
  };

  const filterCourseMaterial = () => {
    if ([EXTERNAL_LEARNER, INTERNAL_LEARNER].includes(loginData.currentRole)) {
      return course_materials.filter(
        file => file.file_category === "learner_document"
      );
    }
    return course_materials;
  };

  const handleChange = e => {
    setFormValue(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validateForm = () => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    const validationRules = [
      {
        field: "workOrderNumber",
        requiredMessage: WorkOrderNumberRequired,
        regex: alphanumericRegex,
        regexMessage: WorkOrderNumberAlphanumeric,
      },
      {
        field: "startDate",
        requiredMessage: StartDateRequired,
      },
      {
        field: "location",
        requiredMessage: LocationRequired,
        regex: alphanumericRegex,
        regexMessage: LocationAlphanumeric,
      },
      {
        field: "machineSerialNumber",
        requiredMessage: MachineSerialNumberRequired,
        regex: alphanumericRegex,
        regexMessage: MachineSerialNumberAlphanumeric,
      },
    ];

    const newErrors = {};
    let isError = false;

    validationRules.forEach(
      ({ field, requiredMessage, regex, regexMessage }) => {
        const value = formValue?.[field];

        if (!value) {
          newErrors[field] = requiredMessage;
          isError = true;
        } else if (regex && !regex.test(value)) {
          newErrors[field] = regexMessage;
          isError = true;
        }
      }
    );

    const hasValidKeyAndValue = Object.keys(mediaFile).some(
      key => key.length > 0 && mediaFile[key] && mediaFile[key].length > 0
    );

    if (!hasValidKeyAndValue) {
      newErrors["uploadFile"] = AddDocument;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }

    handleUpload(uploadFileModal?.key);
  };

  return (
    <div className="page-body schedule-details">
      <Row>
        <Col md="8" sm="4">
          <div className="mb-3">
            <ProjectDescription
              title={course_detail?.course_name}
              description={course_detail?.description}
              courseValues={[
                {
                  label: CourseType,
                  value: course_detail?.course_type,
                  id: CourseType,
                },
                {
                  label: CourseID,
                  value: course_detail?.course_id,
                  id: CourseID,
                },
              ]}
              scheduledOn={course_detail?.schedule_details?.start_date}
              isDownloadCertificate={{
                participationCertificate: course_detail?.is_participated,
                courseCompletionCertificate:
                  course_detail?.completion_status === "completed" &&
                  course_detail.id,
                handleDownload: handleDownloadCertificate,
              }}
            />
          </div>

          {courseIdParam && course_detail?.course_type === WBT && (
            <Row className="mt-5">
              {course_materials.map((item, index) => (
                <Col md="6" sm="6" key={item.file_name + index}>
                  <PlayCourseMaterial
                    material={item}
                    courseId={course_detail?.id}
                    courseName={course_detail?.course_name}
                  />
                </Col>
              ))}
            </Row>
          )}

          {activityData.length > 0 && isOngoingOrNominationClosed() && (
            <Card className="course-activity mt-4">
              <CardHeader>
                <H4>Course Activity</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {sortActivity()?.map(item => {
                  const assessmentDetails = getAssessmentDetails(item);
                  return (
                    <div className="" key={item.participant_id}>
                      <CourseActivity
                        name={assessmentDetails.name}
                        getStatus={() =>
                          getActivityStatus(item, assessmentDetails.onClick)
                        }
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          )}

          {isOngoingOrNominationClosed() &&
            loForAssessment?.data &&
            Object.keys(loForAssessment?.data)?.length > 0 && (
              <Card className="course-activity mt-4">
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H4>{SkillAssessment}</H4>
                  <div className="d-flex flex-row align-items-center gap-3">
                    <Progressbar
                      barClassName="custom-sga-progressBar"
                      value={skillAssessments?.percentage}
                      className="custom-sgaProgressBar"
                    />
                    {`${skillAssessments?.percentage}%`}
                  </div>
                </CardHeader>
                <div className="mt-3 mx-3">
                  <SimpleAccordion
                    accordionList={getSkillAssessmentContent()}
                  />
                </div>
              </Card>
            )}

          {((!isOngoingOrNominationClosed() && !courseIdParam) ||
            (courseIdParam && course_detail?.course_type !== "wbt")) && (
            <Card>
              <CardBody>
                <JustifyTabs
                  tabs={[{ id: "details", label: "Details" }, ...scheduledTabs]}
                  activeTab={courseTab}
                  onTabClick={handleTabClick}
                />
              </CardBody>
            </Card>
          )}

          {((courseTab === "details" && !courseIdParam) ||
            (courseTab === "details" &&
              courseIdParam &&
              course_detail?.course_type !== WBT)) && (
            <Card>
              <CardHeader>
                <H4>{WhatYouWillLearnInThisCourse}</H4>
              </CardHeader>
              <CardBody className="">
                {learning_outcome.map(item => (
                  <div
                    className="d-flex gap-2 mb-3 align-items-center"
                    key={item.id}
                  >
                    <MUIIcons
                      className="primary-icon-color"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.outcome}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseTab !== "details" &&
            course_detail?.course_type !== WBT &&
            course_detail?.course_type !== WEBINAR && (
              <Card>
                <CardBody className="">
                  <ScheduleListContainer
                    userDetails={scheduleCourse?.user_dict}
                    data={scheduleCourse?.scheduled_courses}
                    filterColumns={[CourseName, Instructor, Room]}
                  />
                </CardBody>
              </Card>
            )}

          {course_detail?.course_type === WEBINAR && (
            <Card>
              <CardHeader>
                <H4>{Resources}</H4>
              </CardHeader>
              <CardBody>
                <>
                  <ul className="schedule-details__resources">
                    {course_detail?.tool_aid?.tool?.map(item => (
                      <li className="mb-1" key={item.id}>
                        <div>{item.name}</div>
                      </li>
                    ))}
                  </ul>
                  <Button
                    color="primary"
                    className="d-flex align-items-center gap-1 my-3"
                    onClick={handleLaunchWebinar}
                  >
                    {launchWebinar}
                  </Button>
                </>
              </CardBody>
            </Card>
          )}
        </Col>

        <Col md="4">
          <Card>
            <CardBody className="schedule-details__course-data">
              {course_detail?.schedule_details &&
                courseData.map(item => (
                  <div className="d-flex gap-2 mb-3" key={item.icon}>
                    <span
                      id={`TooltipIcon${sanitizeString(item.value)}`}
                      className="ml-2 pointer"
                    >
                      <MUIIcons
                        className="course-description__calender"
                        size={18}
                        iconName={getIconName(item.icon)}
                      />
                    </span>

                    <Tooltip
                      placement="top"
                      isOpen={scheduledDetailTooltipOpen === item.id}
                      target={`TooltipIcon${sanitizeString(item.value)}`}
                      toggle={() => scheduledDetailsToggleTooltip(item.id)}
                    >
                      {item.iconName}
                    </Tooltip>
                    {item?.icon === "PersonOutlineOutlined" &&
                    item?.extraData?.length > 0 ? (
                      <>
                        <span
                          id={`TooltipPersonIcon${sanitizeString(item.value)}`}
                          className="ml-2 pointer"
                        >
                          {item?.value} {" +"} {item?.extraData?.length}
                        </span>

                        <Tooltip
                          placement="top"
                          isOpen={scheduledDetailTooltipOpen === item.uniqueId}
                          target={`TooltipPersonIcon${sanitizeString(item.value)}`}
                          toggle={() =>
                            scheduledDetailsToggleTooltip(item.uniqueId)
                          }
                        >
                          {item?.extraData}
                        </Tooltip>
                      </>
                    ) : (
                      <P className="course-description__schedule-on m-0">
                        {item.value}
                      </P>
                    )}
                  </div>
                ))}
              {course_detail?.schedule_details &&
                course_detail?.schedule_details?.self_enrollment &&
                !location?.state?.mySession && (
                  <div className="border-top">
                    <DefaultButton
                      className="w-100 mt-3"
                      color="primary"
                      onClick={handleModal}
                    >
                      I’m Interested !
                    </DefaultButton>
                  </div>
                )}
            </CardBody>
          </Card>
          {(scheduledDetails?.course_detail?.course_prerequisite?.length > 0 ||
            (scheduledDetails?.course_detail?.other_prerequisites &&
              scheduledDetails?.course_detail?.other_prerequisites !== "")) && (
            <Card>
              <CardHeader>
                <H4>Prerequisite</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {scheduledDetails?.course_detail?.course_prerequisite?.map(
                  item => (
                    <div className="" key={item.id}>
                      <CourseActivity
                        name={item.name}
                        getStatus={() => getPrerequisiteStatus(item)}
                      />
                    </div>
                  )
                )}
              </CardBody>
              {scheduledDetails?.course_detail?.other_prerequisites &&
                scheduledDetails?.course_detail?.other_prerequisites !== "" && (
                  <CardBody>
                    <div className="">
                      <CourseActivity
                        name={
                          scheduledDetails?.course_detail?.other_prerequisites
                        }
                        getStatus={() =>
                          getPrerequisiteStatus({ status: "completed" })
                        }
                      />
                    </div>
                  </CardBody>
                )}
            </Card>
          )}

          <Modal
            isOpen={isModal}
            toggle={handleModal}
            className="modal-body"
            centered={true}
          >
            <ModalHeader toggle={handleModal}>
              <H4>Prerequisite Course</H4>
            </ModalHeader>
            <ModalBody>
              <P className="mb-3">
                {WelcomeToPrerequisite} {course_detail?.course_name}{" "}
                {DesignedToEvaluateYourFoundational}
              </P>

              {scheduledDetails?.course_detail?.course_prerequisite?.map(
                item => (
                  <div className="" key={item.id}>
                    <CourseActivity
                      name={item.name}
                      getStatus={() => getPrerequisiteStatus(item)}
                    />
                  </div>
                )
              )}
            </ModalBody>
            <ModalFooter>
              {isAllCompleted() ? (
                <DefaultButton
                  className="w-100"
                  color="primary"
                  onClick={handleEnroll}
                >
                  {EnrollMe}
                </DefaultButton>
              ) : (
                <div className="d-flex w-100">
                  {(() => {
                    const hasNullElements = hasAnyNullElement();
                    return (
                      <>
                        <SecondaryButton
                          onClick={() => handleMailAndEnroll()}
                          className={`secondary-button ${hasNullElements ? "w-50" : "w-100"}`}
                        >
                          {MailMe}
                        </SecondaryButton>
                        {hasNullElements && (
                          <DefaultButton
                            className="w-50"
                            color="primary"
                            onClick={() => handleMailAndEnroll(true)}
                          >
                            {MailMeAddToILP}
                          </DefaultButton>
                        )}
                      </>
                    );
                  })()}
                </div>
              )}
            </ModalFooter>
          </Modal>

          {(course_detail?.pre_course_work.length > 0 ||
            (course_detail?.other_pre_course_works &&
              course_detail.other_pre_course_works.trim() !== "")) && (
            <Card>
              <CardHeader>
                <H4>{PreCourseWorkHeader}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                <LearningPlanCourseCard
                  data={transformedData}
                  handleClick={handlePrequiesitesClick}
                  showUserDetails={false}
                />
              </CardBody>
            </Card>
          )}

          {isOngoingOrNominationClosed() &&
            course_detail?.course_type !== WBT &&
            filterCourseMaterial()?.length > 0 && (
              <Card>
                <CardHeader>
                  <H4>{Material}</H4>
                </CardHeader>
                <CardBody className="mt-2">
                  {filterCourseMaterial()?.map((item, index) => (
                    <div className="mb-3" key={item.file_name + index}>
                      <Badges className="w-100" color="light text-dark">
                        <div className="d-flex align-items-center">
                          <MUIIcons
                            size={24}
                            iconName="InsertDriveFileOutlined"
                          />
                          <span className="text-start m-1 w-100 gap-1 p-1 mb-2">
                            {getEllipsedFileName(item.file_name)}
                          </span>
                          <MUIIcons
                            className="pointer"
                            onClick={() => handleDownload([item.file_url])}
                            size={24}
                            iconName="FileDownloadOutlined"
                          />
                        </div>
                      </Badges>
                    </div>
                  ))}
                </CardBody>
              </Card>
            )}

          <Card>
            <CardHeader>
              <H4>{ThisCourseIncludes}:</H4>
            </CardHeader>
            <CardBody className="schedule-details__course-includes">
              {courseInlcudes().map(item => (
                <div className="d-flex gap-2 mb-3" key={item.icon}>
                  <span
                    id={`TooltipIcon${sanitizeString(item.value)}`}
                    className="ml-2 pointer"
                  >
                    <MUIIcons
                      className="course-description__calender"
                      size={18}
                      iconName={getIconName(item.icon)}
                    />
                  </span>

                  <Tooltip
                    placement="top"
                    isOpen={courseIncludesTooltipOpen === item.id}
                    target={`TooltipIcon${sanitizeString(item.value)}`}
                    toggle={() => courseIncludesToggleTooltip(item.id)}
                  >
                    {item.iconName}
                  </Tooltip>
                  <P className="course-description__schedule-on m-0">
                    {item.value}
                  </P>
                </div>
              ))}
            </CardBody>
          </Card>

          <Modal
            isOpen={uploadFileModal?.isOpen}
            toggle={() => handleFileUploadModal()}
            className="modal-body"
            centered={true}
            size="lg"
          >
            <ModalHeader toggle={() => handleFileUploadModal()}>
              <H4>{UploadFile}</H4>
            </ModalHeader>
            <ModalBody>
              <Form className="g-3 custom-input" noValidate>
                <Row>
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        tooltipText={WorkOrderNumberToolTipMessage}
                        label={WorkOrderNumber}
                        important
                      />
                      <Input
                        onChange={handleChange}
                        value={formValue?.workOrderNumber}
                        name="workOrderNumber"
                        type="text"
                        placeholder={`${Enter} ${WorkOrderNumber}`}
                        maxLength={100}
                        invalid={!!errors?.workOrderNumber}
                      />
                      {errors?.workOrderNumber && (
                        <div className="invalid-feedback">
                          {errors?.workOrderNumber}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        label={StartDate}
                        tooltipText={AssetStartDateToolTipMessage}
                        important={true}
                      />
                      <div className={"w-100"}>
                        <DatePicker
                          onChange={(date: Date | null) =>
                            handleChange({
                              target: {
                                name: "startDate",
                                value: date,
                              },
                            })
                          }
                          selected={formValue?.startDate}
                          placeholderText={`${Select} ${StartDate}`}
                          className={`form-control ${errors?.startDate ? "is-invalid" : ""}`}
                          dateFormat="dd-MM-yyyy"
                          disabledKeyboardNavigation={true}
                          onKeyDown={e => e.preventDefault()}
                        />

                        {errors?.startDate && (
                          <div className="invalid-date-picker-feedback">
                            {errors?.startDate}
                          </div>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        tooltipText={`${Enter} ${SideBarTitleLocation}`}
                        label={SideBarTitleLocation}
                        important
                      />
                      <Input
                        onChange={handleChange}
                        value={formValue?.location}
                        name="location"
                        type="text"
                        placeholder={`${Enter} ${SideBarTitleLocation}`}
                        maxLength={100}
                        invalid={!!errors?.location}
                      />
                      {errors?.location && (
                        <div className="invalid-feedback">
                          {errors?.location}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        tooltipText={MachineSerialNumberToolTipMessage}
                        label={MachineSerialNumber}
                        important
                      />
                      <Input
                        onChange={handleChange}
                        value={formValue?.machineSerialNumber}
                        name="machineSerialNumber"
                        type="text"
                        placeholder={`${Enter} ${MachineSerialNumber}`}
                        maxLength={100}
                        invalid={!!errors?.machineSerialNumber}
                      />
                      {errors?.machineSerialNumber && (
                        <div className="invalid-feedback">
                          {errors?.machineSerialNumber}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="12 ">
                    <LabelTooltip
                      label={UploadFile}
                      tooltipText={UploadFile}
                      important={true}
                    />
                    <div className="text-danger">{YourFileFileSizeNote}</div>
                    <MultiFileUpload
                      flushData={flushData}
                      accept=".pdf,.docx,.doc"
                      multiple={true}
                      onFileChange={(file, status) =>
                        handleFileChange(uploadFileModal?.key, file, status)
                      }
                    />
                    {errors?.uploadFile && (
                      <div className="invalid-feedback d-block">
                        {errors?.uploadFile}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex">
                <SecondaryButton
                  className="secondary-button"
                  onClick={() => handleFileUploadModal()}
                >
                  {Cancel}
                </SecondaryButton>
                <DefaultButton color="primary" onClick={handleSubmit}>
                  {Upload}
                </DefaultButton>
              </div>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default LearnerScheduleDetails;
