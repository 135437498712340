import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { Btn, H3 } from "../../../../AbstractElements";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import {
  createExtensionRequest,
  getAssessmentDetailsByParticipantId,
} from "../../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  AddReason,
  AddReasonRequired,
  AddValidNumberInRange30,
  AssessmentExtensionRequestAddReasonForApproveToolTipMessage,
  AssessmentExtensionRequestNoOfDaysToolTipMessage,
  Enter,
  NumberOfDays,
  NumberOfDaysRequired,
  RequestExtensionRequest,
  Submit,
} from "../../../../utils/Constant";
import { handleKeyDownOnNumericWithoutDecimal } from "../../../../utils/helper/helper";

const RequestExtensionModal = ({ handleModal, requestExtensionModal }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const [formValues, setFormValues] = useState({
    noOfDays: null,
    addReason: "",
  });

  const [errors, setErrors] = useState({
    noOfDays: "",
    addReason: "",
  });

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === "noOfDays" && (Number(value) === 0 || Number(value) > 30)) {
      setFormValues(prevState => ({
        ...prevState,
        [name]: "",
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: AddValidNumberInRange30,
      }));
      return;
    }

    setFormValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {
      noOfDays: "",
      addReason: "",
    };

    let isError = false;
    if (!formValues.noOfDays) {
      newErrors.noOfDays = NumberOfDaysRequired;
      isError = true;
    }

    if (!formValues.addReason) {
      newErrors.addReason = AddReasonRequired;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }

    const response = await dispatch(
      createExtensionRequest({
        extenstionRequest: {
          schedule_id: state?.assessment?.schedule_id,
          type: "extension",
          requested_days: parseInt(formValues.noOfDays, 10),
          requester_comments: formValues.addReason,
        },
      })
    );

    if (response.payload) {
      dispatch(
        getAssessmentDetailsByParticipantId({
          id: state?.assessment?.participant_id,
        })
      );
      setFormValues({
        noOfDays: null,
        addReason: "",
      });
      handleModal();
    }
  };

  return (
    <Modal isOpen={requestExtensionModal} centered={true} toggle={handleModal}>
      <ModalHeader toggle={handleModal}>
        <H3>{RequestExtensionRequest}</H3>
      </ModalHeader>
      <ModalBody>
        <H3 className="mb-2">{state?.assessment?.name}</H3>

        <Form className="g-3 custom-input" noValidate>
          <Row className="d-flex align-items-end">
            <Col sm="12" lg="12">
              <FormGroup>
                <LabelTooltip
                  label={NumberOfDays}
                  tooltipText={AssessmentExtensionRequestNoOfDaysToolTipMessage}
                  important
                />
                <Input
                  onChange={handleChange}
                  value={formValues.noOfDays}
                  name="noOfDays"
                  type="number"
                  inputMode="numeric"
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  placeholder={`${Enter} ${NumberOfDays}`}
                  invalid={!!errors.noOfDays}
                />
                {errors.noOfDays && (
                  <div className="invalid-feedback">{errors.noOfDays}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" lg="12">
              <FormGroup>
                <LabelTooltip
                  label={AddReason}
                  tooltipText={
                    AssessmentExtensionRequestAddReasonForApproveToolTipMessage
                  }
                  important
                />
                <Input
                  onChange={handleChange}
                  value={formValues.addReason}
                  name="addReason"
                  type="textarea"
                  placeholder={`${Enter} ${AddReason}`}
                  maxLength={200}
                  invalid={!!errors.addReason}
                />
                {errors.addReason && (
                  <div className="invalid-feedback">{errors.addReason}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Btn color="primary" onClick={handleSubmit}>
          {Submit}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

RequestExtensionModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  requestExtensionModal: PropTypes.bool.isRequired,
};

export default RequestExtensionModal;
